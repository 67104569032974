import React, { Component } from 'react';
import { connect } from 'react-redux';
import UserLoader from '../assets/image/user-loader.gif';
import { createUser, IntercomData } from '../redux/actions/authActions';
import { clearSignupError } from '../redux/actions/errorActions';
import { CLOSE_LOGINM, SIGNUP_SEND, SIGNUP_FAIL } from '../redux/actions/types';

class Register extends Component {

    constructor(props) {
        super(props);
        this.state = {
            username: '',
            email: '',
            password: '',
            errors: { username: '', email: '', password: '' },
            usernameValid: false,
            emailValid: false,
            passwordValid: false,
            errorMessage: '',
        }

        this.changeHandle = this.changeHandle.bind(this);
        this.validateField = this.validateField.bind(this);
        this.signupDataSubmit = this.signupDataSubmit.bind(this);
    }

    changeHandle = (e) => {
        const { name, value } = e.target;
        this.setState({
            [name]: value
        }, () => this.validateField(name, value))
    }

    validateField = (fieldName, value) => {
        // console.log(this.state);
        let errors = this.state.errors;
        let usernameValid = this.state.usernameValid;
        let emailValid = this.state.emailValid;
        let passwordValid = this.state.passwordValid;

        if (fieldName === 'email') {
            if (value.length > 0) {
                // console.log('if');
                if (!value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
                    emailValid = false;
                    errors.email = 'Enter valid email';
                    // console.log('if if');
                } else {
                    // console.log('if else');
                    emailValid = true;
                    errors.email = ''
                }
            } else {
                // console.log('else');
                emailValid = false;
                errors.email = 'Email Must Required.';
            }
        }


        switch (fieldName) {
            case 'username':
                usernameValid = value.length >= 5;
                errors.username = usernameValid ? '' : 'Name Must be 5 characters long!';
                break;
            // case 'email':
            //     emailValid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
            //     // console.log(emailValid.length);
            //     // emailValid = emailValid.length >= 0;
            //     errors.email = emailValid ? '' : 'Email Must Required.';
            //     break;
            case 'password':
                passwordValid = value.length >= 6;
                errors.password = passwordValid ? '' : 'Password must be 6 characters long!';
                break;
            default:
                break;
        }
        this.setState({
            errors: errors,
            usernameValid: usernameValid,
            emailValid: emailValid,
            passwordValid: passwordValid,
        });
    }

    signupDataSubmit = (e) => {
        e.preventDefault();
        const { dataSend, signupFail, dataSubmit, IntercomData2 } = this.props
        dataSend();
        const { username, email, password } = this.state;


        if (username !== '' || email !== '' || password !== '') {
            dataSubmit(username, email, password).then((res) => {
                if(res.status) { 
                    IntercomData2();
                }
                else {
                    this.setState({
                        errorMessage: res.message
                    })
                }
            });
        } else {
            signupFail();
            this.setState({
                errorMessage2: 'All Field is Required'
            });
        }
    }

    componentDidUpdate(prevProps) {
        const { isAuth } = this.props;
        // console.log(error);
        // if (error !== prevProps.error) {
        //     // check for login error
        //     if (error.id === 'SIGNUP_FAIL') {
        //         console.log(error.signupMSG)
        //         this.setState({ errorMessage: error.signupMSG });

        //     } else {
        //         this.setState({ errorMessage: '' })
        //     }
        // }

        // if auth, close modal
        if (isAuth) {
            this.props.closeModal();
        }
    }

    render() {

        const { username, email, password, errors, errorMessage, usernameValid, emailValid, passwordValid } = this.state;
        const disabled = !usernameValid || !emailValid || !passwordValid;

        const { isLoading3, error } = this.props;

        return (
            <div className="loginForm">
                <form method="POST" onSubmit={this.signupDataSubmit}>

                    {(errors.username.length > 0) ? <p className="errorContact">{(errors.username.length > 0) ? errors.username : ''}</p> : null}
                    {(errors.email.length > 0) ? <p className="errorContact">{(errors.email.length > 0) ? errors.email : ''}</p> : null}
                    {(errors.password.length > 0) ? <p className="errorContact">{(errors.password.length > 0) ? errors.password : ''}</p> : null}
                    {(this.state.isSubmitted) ?
                        <p className="green-block">Your request has been sent.</p>
                        :
                        null
                    }
                    <div className="form-group mb-0">
                        <input type="text" className="form-controllog border-radt" placeholder="Username" name="username" value={username} onChange={this.changeHandle} autoComplete={"off"} />
                    </div>
                    <div className="form-group mb-0">
                        <input type="email" name="email" className="form-controllog" placeholder="Email" value={email} onChange={this.changeHandle} autoComplete={"off"} />
                    </div>
                    <div className="form-group mb-0">
                        <input type="password" className="form-controllog border-radb" placeholder="Password" value={password} name="password" onChange={this.changeHandle} autoComplete={"off"} />
                    </div>
                    {(errorMessage !== '') ? <p className="errorContact">{errorMessage}</p> : null}
                    {(error.msg !== null) ? <p className="errorContact">{error.msg}</p> : null}

                    <div className="form-group mb-0 mt-100">
                        <button type="submit" disabled={disabled || isLoading3} name="signup" className="btn-sub">{isLoading3 ? <img className="sendLoader" src={UserLoader} alt="loader" /> : "Create account"}</button>
                    </div>
                </form>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    // console.log(state);
    return {
        isAuth: state.auth.isAuth,
        error: state.error,
        isLoading3: state.auth.signupLoading
    }
}

const mapDispatchToProps = dispatch => ({
    dataSubmit: (username, email, password) => dispatch(createUser(username, email, password)),
    clearError: () => dispatch(clearSignupError()),
    closeModal: () => dispatch({ type: CLOSE_LOGINM }),
    dataSend: () => dispatch({ type: SIGNUP_SEND }),
    signupFail: () => dispatch({ type: SIGNUP_FAIL }),
    IntercomData2: () => dispatch(IntercomData()),
})


export default connect(mapStateToProps, mapDispatchToProps)(Register);