import moment from 'moment';

export const countWords = (str) => {

    str = str.replace(/(^\s*)|(\s*$)/gi,"");
    str = str.replace(/[ ]{2,}/gi," ");
    str = str.replace(/\n /,"\n");

    return str.split(' ').length;
}

// card number validation function
export const cardNumberValidation = (cardNumber) =>{
    const regexPattern = {
        MASTERCARD:  new RegExp(/^5[1-5][0-9]{1,}|^2[2-7][0-9]{1,}$/),
        VISA:  new RegExp(/^4[0-9]{2,}$/),
        AMERICAN_EXPRESS:  new RegExp(/^3[47][0-9]{5,}$/),
        DISCOVER:  new RegExp(/^6(?:011|5[0-9]{2})[0-9]{3,}$/),
        // DINERS_CLUB:  new RegExp(/^3(?:0[0-5]|[68][0-9])[0-9]{4,}$/),
        JCB: new RegExp(/^(?:2131|1800|35[0-9]{3})[0-9]{3,}$/),
        UNIONPAY: new RegExp(/^(62[0-9]{14,17})$/),
        MAESTRO: new RegExp(/^(5018|5020|5038|6304|6759|6761|6763)[0-9]{8,15}$/),
        DINERS_CLUB: new RegExp(/3(?:0[0-5]|[68][0-9])[0-9]{11}/g)
      };
      for (const card in regexPattern) {
        if (cardNumber.replace(/[^\d]/g, "").match(regexPattern[card])) {
          if (cardNumber) {
            return cardNumber &&
              /^[1-6]{1}[0-9]{11,15}$/i.test(
                cardNumber.replace(/[^\d]/g, "").trim()
              )
              ? {message:"", valid:true, type:card}
              : {message:"Enter a valid Card", valid:false, type:''};
          }
        }
      }
      return {message:"Enter a valid Card", valid:false, type:''};
}

// card expiry date validation function
export const cardExpiryValidation = (value)=>{
    var expDat = new RegExp(/^(0[1-9]|1[0-2])\/[0-9]{2}$/i);
    if (value) {
        if (expDat.test(value.trim())) {
          let today = new Date();
          let CurrentDate = moment(
            new Date(
              today.getFullYear() +
                "-" +
                (today.getMonth() + 1) +
                "-" +
                new Date(today.getFullYear(), today.getMonth() + 1, 0).getDate()
            )
          );
          let visaValue = value.split("/");
          let visaDate = new Date(`20${visaValue[1]}`, visaValue[0], 0);
          return CurrentDate < moment(visaDate)
            ? { message:'', valid:true }
            : { message:"Please enter valid date", valid:false };
        } else {
          return { message:"Invalid date format", valid:false };
        }
    }else{
        return { message:'Expiry Date is required', valid:false }
    }
}

// card cvv(security code) validation function
export const cardCVVValidation = (value) =>{
    if (value.length > 0) {
        if (value.length > 2) {

            return { message:'', valid:true };

        } else {
            return { message:'Must be 3 characters or more', valid:false };
        }
    } else {
        return { message:'CVV is required', valid:false };
    }
}

// card holder name validation function
export const cardHolderNameValidation = (value) =>{
    if (value.length > 0) {
        if (/^[a-zA-Z ]*$/i.test(value)) {
            return { message:'', valid:true }
        } else {
            return { message:"Only alphabets", valid:false }
        }
    }else{
        return { message:"Card holder name must required", valid:false }
    }
}