import { apiHelper } from '../../helper/api_helper'
import { jwtDecode } from '../../helper/jwt_helper';
import * as ACTION_TYPES from './types';
import { returnNewsError } from './newsError';

export const loadNews = (page) => dispatch => {

    if (page <= 1) {
        dispatch({ type: ACTION_TYPES.LOADING_NEWS });
    }
    //axios.get(allCoinURL, config)
    return apiHelper(`news?page=${page}`, 'GET', null, null)

        .then(res => {            
            if (res.data.status) {
                const decode = jwtDecode(res.data.data);
                dispatch({
                    type: ACTION_TYPES.LOADED_NEWS_SUCCESS,
                    payload: decode
                });

            } else {
                const err = res.data;
                dispatch(returnNewsError(err.msg, 401, 'LOADED_NEWS_FAIL'));
                dispatch({ type: ACTION_TYPES.LOADED_NEWS_FAIL });
            }
        })
        .catch(err => {
            dispatch(returnNewsError(err.msg, 401, 'LOADED_NEWS_FAIL'));
            dispatch({ type: ACTION_TYPES.LOADED_NEWS_FAIL });
        })

}   