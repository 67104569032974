// import axios from 'axios';
import * as ACTION_TYPES from './types';
import { returnReplayErrors } from './errorActions';
import { apiHelper } from '../../helper/api_helper'

export const sendContactMe = (email) => dispatch =>{

    // // header
    // const config ={
    //     headers:{
    //         'Content-Type':'application/json'
    //     }
    // }

    
    // let formData = new FormData();
    // formData.append('numberemail',email)

    let formData = new FormData();
    formData.append('email', email)

    return apiHelper('contactUs', 'POST', formData, null)

    //axios.post('mails/sendMail/footer', formData, config)
        .then(res=> {
            // console.log(res);
            if(res.data.status){
                dispatch({
                    type:ACTION_TYPES.REPLAY_SUCCESS,
                    payload:res.data.msg
                })
            }else{
                const err = res.data;
                dispatch(returnReplayErrors(err.msg,  401, 'REPLAY_FAIL'));
                dispatch({
                    type: ACTION_TYPES.REPLAY_FAIL
                })
            }
            
        })
        .catch(err=>{
            // console.log(err);
            dispatch(returnReplayErrors(err, 401, 'REPLAY_FAIL'));
            dispatch({
                type: ACTION_TYPES.REPLAY_FAIL
            })
        })
}