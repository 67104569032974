import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import './TermsUse.css';
import { loadMeta } from '../../redux/actions/metaActions';
import { Helmet } from "react-helmet";

class TermsUse extends Component {

    componentDidMount(){
        const { loadMeta } = this.props;
        loadMeta('terms-of-use');
    }

    render() {

        const termsList = [
            {
                had:"Last Revised: January 3rd, 2020",
                text:"This page (together with the documents referred to on it) tells you the terms of service on which you may make use of our website www.redlinecoin.com (our site), whether as a guest or a registered user. Please read these terms of service carefully before you start to use the site. By using our site, you indicate that you accept these terms of service and that you agree to abide by them.",
                text2:"Kindly, go through the terms and conditions keenly before you opt to utilize our RedLine Coin Services. Your utilization of our services will be subject to the stipulated terms and conditions, and by using our products; you agree to comply by our terms."
            },
            {
                had:"1. Introduction",
                text:"RedLine Coin serves to develop mobile crypto signal counsel applications. Our applications can be sourced from App Store and Google Play Store. Our Products give cryptocurrency counsel, content aspects, how it works and alternative information and services, in the absence of restriction, signal, advise, strategies or alternative information; additionally, when accessed through the internet, mobile or any given electronic device ( generally referred to as “Our Services” in a collective manner) Our products are developed for giving information. You are strongly advised to seek advice from an attorney or financial advisors before engaging in the information we avail. When you agree to our given Terms and Conditions, you affirm that you are singly accountable for your actions, investments and financial status.",
            },
            {
                had:"2. Acceptance of Terms and Conditions",
                text:"Your use of Our Products and services is an affirmation of your acceptance of the given terms and Conditions. In the event you do not accept the current terms and conditions; you are not supposed to utilize our Products and Services. Redline Coin has the rights to amend the current terms and conditions at any time appropriate without being under the obligation of giving notice. The updated version of the current Terms and Conditions can be found on the official website of RedLine Coin; any modifications will be effected at the time of posting. Your continuous use of our products affirms your agreement to the amended terms and Conditions.",
            },
            {
                had:"3. Intellectual Property",
                text:"All the designs, Logos, graphics, texts, images and information located in Our Products and Services are Intellectual Property of RedLine Coins (or belongs to it) and are safeguarded by extant copyright laws. The entire logos and trademarks shown in Our Products and Services are Trademarks of RedLine Coins. No Given trademarks can be used without initial documented consent of RedLine Coin except in the identification of products or services related therewith. Our Products and all linked software, and the entire patent rights, trade secrets, copyrights and alternative intellectual property rights including in or out of it are always to remain the exclusive Property of Taplistic. Or it's licensors.",
            },
            {
                had:"4. Legality of Use",
                text:"Through the use of Our Products, you hence warrant that (1) The entire data found in any given submission by you is either owned by you or the rights have been given to you allowing you to avail the information as stipulated in the current Terms and Conditions; and (2) The information found in any given submissions by you does not compromise any particular Intellectual property Rights of other people; including but not restricted to, trade secrets, patent, trademark and copyright. You also accept full accountability, assume entire risk, waive and/or free the US from any loss, injury, claim or damage; regardless of whether or not the particular use was appropriately monitored and regardless of users negligence. Through your use of our product, you testify and confirm that are a both physically and mentally fit to utilize Our Products; and with conscience are aware of entire risks related to their utilization.",
            },
            {
                had:"5. Subscriptions",
                text:"RedLineCoin is a subscription service app. It's an advisory service in which you are paying for privileged information. Should you choose to subscribe to receive signals you can subscribe to an auto-renewing subscription. A subscription is REQUIRED To access the signal information within the app.",
                text_set:"1-month Subscription - ",
                textBold:"$19.99",
                text3:"Payment will be charged to your credit card through your iTunes account at confirmation of purchase. Subscription renews automatically unless canceled at least 24 hours prior to the end of the subscription period. There is no increase in price when renewing. Any unused portion of a free trial period, if offered, will be forfeited.",
                text4:"Subscriptions can be managed and auto-renewal turned off in Account Settings in iTunes after purchase. Once purchased, refunds will not be provided for any unused portion of the term.",
            },
            {
                had:"6. Representations and Warranties; Limitation of Liability",
                text:"RedLine Coin does not make representations pertaining (1) the credentials of any particular user of our Services and/or products as a section of any given financial counsel or into investment plans. Our Services are availed “as is” in the absence of warranties either implied or expressed; and this includes not restricted to the implied warranties of merchantability, employed for a specific objective, or non-infringement. Redline Coin does not guarantee that the information found in our services and/or products will be increasingly up to date, concise, complete and concise. To the degree that the law does not allow the disclaimer of warranties. The entire content found in our services and products; or any website linked to us; is warranted just to the minimum amount legally stipulated. At no time shall RedLine Coin, be liable to any given party or any direct, indirect, unique, or alternative consequential compromise for any consumption of such in our services and products, or any associated website; including, without any restriction, any lost profits, interruptions in business or otherwise. RedLine Coin will be liable as presently stipulated by law, disregarding the statutory basis of such liability only if Redline Coin, has resulted to a particular damage knowingly or with astounding negligence. In the event of negligible negligence, RedLine Coin will not be liable to other enterprises and will be liable to users only individual damages. Redline will not be liable to any enterprises for monitoring and sorting damages, just pecuniary loses, damages or some give lost profits leading to claims of third parties.",
            },
            {
                had:"7. Maintenance",
                text:"RedLine Coin is not accountable for any particular outages, hitches or service interruptions that take place periodically in the process of using our services; this includes complications as a result of hardware, software or power outages or problems at the wireless carrier level. Also, RedLine Coin is not accountable for the conflicting products and services availed by others; including any Userâ€™ wireless data networks or mobile handsets. Redline, in its exclusive discretion, sustains the right and power to either add or get rid of operating systems in line with commercial factors it perceives relevant as it relates to the use, adoption, and appeal of the OS.",
            },
            {
                had:"8. Users Created Content and Rights related to Such Content",
                text:"The user agrees that due to the automatic assessment of the manner they use our Products, they might be exposed to particular offers and/or promotional messages designed to such user. The user further agrees that marketing attempts might be enacted in line with created content that is marketed by RedLine Coin service. The user hence gives Redline Coin, the irrevocable, free, unlimited and non-exclusive power to utilize all the generated, relayed, saved and published content by the user. Consequently, RedLine Coin will have the power to utilize all content, regardless of the kind of usage. This shall also include the power and right to transform and modify such content unless such transformations or modifications compromise material interests of our user. In regard to this, the user waives, to the degree that is legally allowed the entire intellectual property rights. Redline does not assert exclusive ownership of any content which is made by users and will thus not monitor such content.",
            },
            {
                had:"9. Territory",
                text:"The notion “territory” in this case implies the USA’s territories possessions, North America, the UK and Australia. Any added Territories shall be by mutual consent",
            },
            {
                had:"10.Governing Law",
                text:"The current Terms and Conditions, alternative non-contractual duties that arise in lieu of them and litigation between RedLine Coin and the users, shall be subject to the US law exclusively. Consequently, The State and Federal Courts in New York, NY: will have unlimited jurisdiction to judge any conflict coming up in association with the current Terms and Condition, in addition to disputes associated to any given non-contractual obligations.",
            }
        ];
        const termsArray = termsList.map((data, i)=>
            <li key={i}>
                <h6>{data.had}</h6>
                <p>{data.text}</p>
                {(data.text2)?<br />:null}
                {(data.text2)?<p>{data.text2}</p>: null}
                {(data.text_set)?<br />:null}
                {(data.text_set)?<p>{data.text_set}<b>{data.textBold}</b></p>:null}
                {(data.text3)?<br />:null}
                {(data.text3)?<p>{data.text3}</p>:null}
                {(data.text4)?<br />:null}
                {(data.text4)? <p>{data.text4}</p>: null}
            </li>
        )

        const { title, description, keywords, g_title, g_description, g_content, f_title, f_description, f_content, t_title, t_description, t_content } = this.props.meta;

        return (
            <Fragment>
                <Helmet>
                    <title>{title}</title>
                    <meta name="description" id='web_dis' content={description} />
                    <meta name="keywords" id='web_key' content={keywords} />

                    <meta name="apple-itunes-app" content="https://itunes.apple.com/us/app/redline-coin-crypto-signals/id1326274166" />
                    <meta name="google-play-app" content="https://play.google.com/store/apps/details?id=com.redline.coin" />

                    {/* Google / Search Engine Tags */}
                    <meta itemprop="name" id='google_title' content={g_title} />
                    <meta itemprop="description" id='google_dis' content={g_description} />
                    <meta itemprop="image" id='google_img' content={g_content} />

                    {/* Facebook Meta Tags */}
                    <meta property="og:url" content="https://redlinecoin.com" />
                    <meta property="og:type" content="website" />
                    <meta property="og:title" id='facebook_title' content={f_title} />
                    <meta property="og:description" id='facebook_dis' content={f_description} />
                    <meta property="og:image" id='facebook_img' content={f_content} />

                    {/* Twitter Meta Tags */}
                    <meta name="twitter:card" content="summary_large_image" />
                    <meta name="twitter:title" id='twitter_title' content={t_title} />
                    <meta name="twitter:description" id='twitter_dis' content={t_description} />
                    <meta name="twitter:image" id='twitter_img' content={t_content} />
                </Helmet>
                <div className="container">
                    <div className="tu_body">
                        <h1>Terms of Service</h1>
                        <ul className="term_body">
                            { termsArray }
                        </ul>
                    </div>
                </div>
            </Fragment>
        )
    }
}

const mapStateToProps = state =>({
    meta:state.meta.metaData
});

const mapDispatchToProps = dispatch =>({
    loadMeta:(data)=>dispatch(loadMeta(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(TermsUse);
