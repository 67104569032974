import * as ACTION_TYPES from './types';

export const returnOSignalError = (msg, status, id)=>{
    return{
        type:ACTION_TYPES.OSIGNAL_GET_ERROR,
        payload:{ msg, status, id }
    }
}

export const clearOSignalError = () =>{
    return{
        type:ACTION_TYPES.OSIGNAL_CLEAR_ERROR
    }
}

export const returnCSignalError = (msg, status, id)=>{
    return{
        type:ACTION_TYPES.CSIGNAL_GET_ERROR,
        payload:{ msg, status, id }
    }
}

export const clearCSignalError = () =>{
    return{
        type:ACTION_TYPES.CSIGNAL_CLEAR_ERROR
    }
}
export const returnCalcuError = (msg, status, id)=>{
    return{
        type:ACTION_TYPES.CALCULATION_GET_ERROR,
        payload:{ msg, status, id }
    }
}

export const clearCalcuError = () =>{
    return{
        type:ACTION_TYPES.CALCULATION_CLEAR_ERROR
    }
}