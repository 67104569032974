import axios from 'axios';
import * as ACTION_TYPES from './types';
import { jwtDecode } from '../../helper/jwt_helper';
import { apiHelper } from '../../helper/api_helper'

import { returnSignupErrors, returnLoginErrors, returnUserErrors, returnForgotErrors, returnResendErrors, returnSecurityErrors, returnHmacErrors, returnInterErrors } from './errorActions';
// import CryptoJs from 'crypto-js';
// check token & user load
export const loadUser = () => (dispatch, getState) => {

    // user loading
    dispatch({ type: ACTION_TYPES.USER_LOADING });

    axios.get('https://redlinecoin.com/api/index.php/user', tokenConfig(getState))
        .then(res => dispatch({
            type: ACTION_TYPES.USER_LOADED,
            payload: res.data
        }))
        .catch(err => {
            dispatch(returnUserErrors(err.response.data, err.response.status));
            dispatch({
                type: ACTION_TYPES.AUTH_ERROR
            })
        })
}

// create user
export const createUser = (fname, email, password) => dispatch => {

    let formData2 = new FormData();
    formData2.append("username", fname)
    formData2.append("email", email)
    formData2.append("password", password)

    return apiHelper('auth/register', 'POST', formData2, null)
        .then(response => {
            const res = response.data
            if (res.status) {
                dispatch({
                    type: ACTION_TYPES.SIGNUP_SUCCESS,
                    payload: res.data.data
                })
                return {status: true, message: '' }
            } else {
                const err = res;
                dispatch(returnSignupErrors(err.message, 401, 'SIGNUP_FAIL'));
                dispatch({
                    type: ACTION_TYPES.SIGNUP_FAIL
                })
                return { status: false, message: res.message }
            }
        })
        .catch(err => {
            // console.log(err);
            dispatch(returnSignupErrors(err.message, err.status, 'SIGNUP_FAIL'));
            dispatch({
                type: ACTION_TYPES.SIGNUP_FAIL
            })
            return { status: false, message: err.message }
        })    
}

// login user
export const loginUser = (email, password) => dispatch => {
    // request body
    let formData = new FormData();
    formData.append("email", email)
    formData.append("password", password)

    return apiHelper('auth/login', 'POST', formData, null)
        .then(response => {
            const res = response.data
            // console.log(res);
            if (res.status) {
                dispatch({
                    type: ACTION_TYPES.LOGIN_SUCCESS,
                    payload: res.data
                })
                return {status: true, message: '', data: res.data }
            } else {
                const err = res.data;
                dispatch(returnLoginErrors(err.message, 401, 'LOGIN_FAIL'));
                dispatch({
                    type: ACTION_TYPES.LOGIN_FAIL
                })
                return { status: false, message: res.message }
            }

        })
        .catch(err => {
            //console.log(err);
            dispatch(returnLoginErrors(err.message, err.status, 'LOGIN_FAIL'));
            dispatch({
                type: ACTION_TYPES.LOGIN_FAIL
            })
            return { status: false, message: 'Invalid email and/or password' }
        });

    // return axios.post('https://redlinecoin.com/api/index.php/Users/userlogin', formData, config)
    //     .then(res=> {
    //         // console.log(res);
    //         if(res.data.flag === 1){
    //             dispatch({
    //                 type:ACTION_TYPES.LOGIN_SUCCESS,
    //                 payload:res.data.data
    //             })
    //         } else{
    //             const err = res.data;
    //             dispatch(returnLoginErrors(err.msg, 401, 'LOGIN_FAIL'));
    //             dispatch({
    //                 type: ACTION_TYPES.LOGIN_FAIL
    //             })
    //         }  

    //     })
    //     .catch(err=>{
    //         // console.log(err);
    //         dispatch(returnLoginErrors(err.msg, err.status, 'LOGIN_FAIL'));
    //         dispatch({
    //             type: ACTION_TYPES.LOGIN_FAIL
    //         })
    //     });
}

// logout user
export const logout = () => dispatch => {
    const token = sessionStorage.getItem('token');
    const headers = { 'Authorization': token }

    return apiHelper('auth/logout', 'POST', null, headers)
        .then(response => {
            const res = response.data
            if (res.status) {
                dispatch({
                    type: ACTION_TYPES.LOGOUT_SUCCESS
                })
            }
        }).catch(err =>{
            console.error(err)
        })
}

// forgot password
export const forgotSubmit = (email) => dispatch => {

    let formData = new FormData();
    formData.append('email', email)

    return apiHelper('auth/send-otp', 'POST', formData, null)
        .then(response => {
            // console.log(response);
            const res = response.data
            if (res.status) {
                dispatch({
                    type: ACTION_TYPES.FORGOT_SUCCESS,
                    payload: { data: res.data, email }
                })
            } else {
                // const err = res.data;
                // console.log(err);
                dispatch(returnForgotErrors(res.message, 401, 'FORGOT_FAIL'));
                dispatch({
                    type: ACTION_TYPES.FORGOT_FAIL
                })
            }
        })
        .catch(err => {
            dispatch(returnForgotErrors(err.msg, 401, 'FORGOT_FAIL'));
            dispatch({
                type: ACTION_TYPES.FORGOT_FAIL
            })
        });

}

// resend code
export const ResendCode = (email) => dispatch => {

    // header
    // const headers = {
    //     'Content-Type': 'application/json'
    // }

    let formData = new FormData();
    formData.append('email', email)

    return apiHelper('auth/send-otp', 'POST', formData, null)
    .then(response => {
            const res = response.data
            // console.log(res);
            if (res.status) {
                dispatch({
                    type: ACTION_TYPES.RESEND_SUCCESS,
                    payload: res.data
                })
            } else {
                const err = res.message;
                // console.log(err);
                dispatch(returnResendErrors(err, 401, 'RESEND_FAIL'));
                dispatch({
                    type: ACTION_TYPES.RESEND_FAIL
                })
            }
        })
        .catch(err => {
            dispatch(returnResendErrors(err.msg, 401, 'RESEND_FAIL'));
            dispatch({
                type: ACTION_TYPES.RESEND_FAIL
            })
        });
}

export const submitSecurityCode = (email, otp, password) => dispatch => {


    let formData = new FormData();
    formData.append('email', email)
    formData.append('otp', otp)
    formData.append('password', password)

    // axios.post('https://redlinecoin.com/api/index.php/Auth/step2', formData, config)
    return apiHelper('auth/reset-password', 'PUT', formData, null)
        .then(response => {
            const res = response.data;
            // console.log(res);
            if (res.status) {
                dispatch({
                    type: ACTION_TYPES.SECURITY_SUCCESS,
                    payload: res.data
                })
            } else {
                const err = res.message;
                // console.log(err);
                dispatch(returnSecurityErrors(err, 401, 'SECURITY_FAIL'));
                dispatch({
                    type: ACTION_TYPES.SECURITY_FAIL
                })
            }
        })
        .catch(err => {
            dispatch(returnSecurityErrors(err.msg, 401, 'SECURITY_FAIL'));
            dispatch({
                type: ACTION_TYPES.SECURITY_FAIL
            })
        });
}


// setup config/headers and token
export const tokenConfig = getState => {
    // get token form localstorage
    const token = getState().auth.access_token;

    // header
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    // if token 
    if (token) {
        config.headers['x-auth-token'] = token;
    }

    return config;
}

// intercom
export const IntercomData = () => (dispatch, getState) => {

    const token = getState().auth.token;
    const headers = { 'Authorization': token }

    // const api_url = `https://redlinecoin.com/api/index.php/Users/checkUserDetailV2/${userId}`;

    let formData2 = new FormData();
    formData2.append("notification_token", token)

    return apiHelper('auth/check-login', 'POST', formData2, headers)
        //axios.get(api_url, config)
        .then(res => {
            // console.log(res);
            if (res.data.status) {
                dispatch({
                    type: ACTION_TYPES.INTERCOM_SUCCESS,
                    payload: { name: res.data.data.fname, email: res.data.data.email }
                });
            } else {
                const err = res.data;
                dispatch(returnInterErrors(err.msg, 401, 'INTERCOM_FAIL'))
                dispatch({ type: ACTION_TYPES.INTERCOM_FAIL })
            }
        })
        .catch(err => {
            dispatch(returnInterErrors(err.msg, 401, 'INTERCOM_FAIL'))
            dispatch({ type: ACTION_TYPES.INTERCOM_FAIL })
        })
}

// hmac key
export const hash_hmac = () => (dispatch, getState) => {

    const token = sessionStorage.getItem('token');
    const tokenDecode = token && jwtDecode(token);
    
    if (tokenDecode && tokenDecode.id) {

        var cryptoJS = require('crypto-js')
        var stringID = tokenDecode.id.toString()
        var hash = cryptoJS.HmacSHA256(stringID, 'i1OfCKk2V2X76zE2EGUcAnF-OUQq1lXLGnHhT07P')
        var hmacKey = cryptoJS.enc.Hex.stringify(hash)

        // const hash = CryptoJs.HmacSHA256(userId, 'i1OfCKk2V2X76zE2EGUcAnF-OUQq1lXLGnHhT07P');
        // const hmacKey = CryptoJs.enc.Hex.stringify(hash);
        dispatch({
            type: ACTION_TYPES.HMAC_SUCCESS,
            payload: hmacKey
        });
    } else {
        dispatch(returnHmacErrors('no key', 401, 'HMAC_FAIL'));
        dispatch({ type: ACTION_TYPES.HMAC_FAIL });
    }
}

