import * as ACTION_TYPES from '../actions/types';

const initialState={
    loginModal:false,
    logOutModal:false,
    forgotModal:false,
    securityCodeModal:false,
    isLogin:true,
}

const loginModalClick = (state = initialState, action) =>{
    switch(action.type){
        case ACTION_TYPES.OPEN_LOGIN:
            return{
                ...state,
                isLogin:true,
                loginModal:true
            }
        case ACTION_TYPES.CLOSE_LOGINM:
            return{
                ...state,
                loginModal:false,
                isLogin:false
            }
        case ACTION_TYPES.LOGIN_TABS:
            return{
                ...state,
                isLogin:!state.isLogin
            }
        case ACTION_TYPES.TOGGLE_LOGOUTMODAL:
        case ACTION_TYPES.LOGOUT_SUCCESS:
            return{
                ...state,
                logOutModal:!state.logOutModal
            }
        case ACTION_TYPES.TOGGLE_FORGOTMODAL:
        // case ACTION_TYPES.FORGOT_SUCCESS:
            return{
                ...state,
                loginModal:false,
                forgotModal:!state.forgotModal
            }
        case ACTION_TYPES.TOGGEL_SECURITYMODAL:
        case ACTION_TYPES.FORGOT_SUCCESS:
        case ACTION_TYPES.SECURITY_SUCCESS:
            return{
                ...state,
                forgotModal:false,
                securityCodeModal:!state.securityCodeModal
            }
        default:
            return state;
    }
}

export default loginModalClick;