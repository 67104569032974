import * as ACTION_TYPES from './types';

export const returnYoutubeError = (msg, status, id)=>{
    return{
        type:ACTION_TYPES.YOUTUBE_GET_ERROR,
        payload:{ msg, status, id }
    }
}

export const clearYoutubeError = () =>{
    return{
        type:ACTION_TYPES.YOUTUBE_CLEAR_ERROR
    }
}

export const returnLangError = (msg, status, id)=>{
    return{
        type:ACTION_TYPES.LANG_GET_ERROR,
        payload:{ msg, status, id }
    }
}

export const clearLangError = () =>{
    return{
        type:ACTION_TYPES.LANG_CLEAR_ERROR
    }
}