//import axios from 'axios';
import { jwtDecode } from '../../helper/jwt_helper';
import { apiHelper } from '../../helper/api_helper'
import * as ACTION_TYPES from './types';
import { returnYoutubeError, returnLangError } from './youtubeError';

//const url = 'https://redlinecoin.com/api/index.php/Youtube_channels';

export const loadYoutube = (data) => dispatch => {

    const { lang, sortPopVal, searchVal } = data;

    dispatch({ type: ACTION_TYPES.LOADING_YOUTUBE });

    const headers = { 'Authorization': sessionStorage.getItem('token') }

    //const api_url = `${url}/getChannelFromUrl?lang=${lang}&title=${searchVal}&param=${sortPopVal}`;

    let formData = new FormData()
    if (lang !== 'all') {
        formData.append("language", lang)
    }
    formData.append("sort_by", sortPopVal)
    formData.append("search_by", searchVal)

    //axios.get(api_url, headers)
    return apiHelper(`youTube/channels`, 'POST', formData, headers)
        .then(response => {
            const res = response.data
            // console.log(res);
            if (res.status) {
                const decode = jwtDecode(res.data);
                dispatch({
                    type: ACTION_TYPES.LOADED_YOUTUBE_SUCCESS,
                    payload: decode
                })
                // console.log(decode)
            } else {
                const err = res;
                dispatch(returnYoutubeError(err.msg, 401, 'LOADED_YOUTUBE_FAIL'));
                dispatch({ type: ACTION_TYPES.LOADED_YOUTUBE_FAIL });
            }
        })
        .catch(err => {
            dispatch(returnYoutubeError(err.msg, 401, 'LOADED_YOUTUBE_FAIL'));
            dispatch({ type: ACTION_TYPES.LOADED_YOUTUBE_FAIL });
        })
}

export const loadLang = () => dispatch => {

    const headers = { 'Authorization': sessionStorage.getItem('token') }

    // const api_url = `${url}/getlanguages`;
    // axios.get(api_url, config)
    return apiHelper('youTube/language', 'GET', null, headers)
        .then(response => {
            const res = response.data

            if (res.status) {
                dispatch({
                    type: ACTION_TYPES.LOADED_LANG_SUCCESS,
                    payload: jwtDecode(res.data)
                });
            } else {
                const err = res;
                dispatch(returnLangError(err.msg, 401, 'LOADED_LANG_FAIL'));
                dispatch({ type: ACTION_TYPES.LOADED_LANG_FAIL });
            }
        })
        .catch(err => {
            dispatch(returnLangError(err.msg, 401, 'LOADED_LANG_FAIL'));
            dispatch({ type: ACTION_TYPES.LOADED_LANG_FAIL });
        })
}