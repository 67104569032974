import * as ACTION_TYPES from '../actions/types';

const initialState = {
    isLoader: false,
    isLoader2: false,
    openSignalArray: [],
    subscriptions: null,
    closeSignalArray: [],
    calcData: null,
    cTotal:0,
    isCSLoader:true,
    cpage:1
}

const topSignal = (state = initialState, action) => {
    switch (action.type) {
        case ACTION_TYPES.LOADING_OPEN_SIGNAL:
            return {
                ...state,
                isLoader: true
            }
        case ACTION_TYPES.OPEN_SIGNAL_SUCCESS:
            return {
                ...state,
                isLoader: false,
                openSignalArray: action.payload.open_signal,
                subscriptions: action.payload.subs
            }
        case ACTION_TYPES.OPEN_SIGNAL_FAIL:
            return {
                ...state,
                isLoader: false,
                openSignalArray: []
            }
        case ACTION_TYPES.LOADING_CLOSE_SIGNAL:
            return {
                ...state,
                isLoader2: true
            }
        case ACTION_TYPES.CLOSE_SIGNAL_SUCCESS:
            return {
                ...state,
                isLoader2: false,
                closeSignalArray: action.payload.close_signal_data,
                cTotal:state.cpage > 1?state.cTotal:action.payload.total_count
            }
        case ACTION_TYPES.CLOSE_SIGNAL_FAIL:
            return {
                ...state,
                isLoader2: false,
                closeSignalArray: []
            }
        case ACTION_TYPES.CALCULATION_SUCCESS:
            return {
                ...state,
                calcData: action.payload,
            }
        case ACTION_TYPES.CALCULATION_FAIL:
            return {
                ...state,
                calcData: null
            }
        default:
            return state;
    }
}

export default topSignal;