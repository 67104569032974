import { combineReducers } from 'redux';
import usersR from './auth';
import errorR from './errors';
import loginModalClick from './loginModalReducer';
import buyornot from './buyornot';
import buyornotError from './buyornotErrors';
import FooterReducer from './footer';
import AppDMReducers from './appDMReducer';
import buycrypto from './buycrypto';
import buyCryptoError from './buycryptoError';
import newsReducer from "./news";
import newsError from './newsErrors';
import topYoutube from './topYoutube';
import topYoutubeError from './topYoutubeError';
import topSignal from './topSignal';
import topSignalError from './topSignalError';
import contactReducer from './contact';
import contactError from './contactError';
import MetaDataR from './MetaDataR';
import MetaError from './MetaError';

const rootReducer = combineReducers({
    auth:usersR,
    error:errorR,
    authModal:loginModalClick,
    footer:FooterReducer,
    buyornot,
    buyornotErrors:buyornotError,
    appDM:AppDMReducers,
    buycrypto,
    buyCryptoError,
    news:newsReducer,
    newsError,
    youtube:topYoutube,
    youtubeError:topYoutubeError,
    signal:topSignal,
    signalError:topSignalError,
    contact:contactReducer,
    contactError:contactError,
    meta:MetaDataR,
    metaError:MetaError

})
export default rootReducer;