import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux';
import './Policy.css';
import { loadMeta } from '../../redux/actions/metaActions';
import { Helmet } from "react-helmet";

class Policy extends Component {

    componentDidMount(){
        const { loadMeta } = this.props;
        loadMeta('policy');
    }

    render() {

        const policyList = [
            {
                text:"RedLine Coin (“us,” “we,” or “our”), in the current page, you will be informed of our policies pertaining the gathering, utilization, and disclosure of all personal information we get from our users in our application.",
                text2:"RedLine Coin Service uses personal information exclusively for availing and enhancing the App. By utilizing the App, you agree to the gathering and utilization of information in compliance with this particular policy.",
            },
            {
                had:"The Collection of Information and Use",
                text:"In the process of using our App, we might request you to give us some personally identifiable data that can be utilized to either identify or contact you. It is important to note that while personally identifiable information might be used, it is not restricted to your name (“Personal Information”).",
            },
            {
                had:"Log Data",
                text:"Similar to various App operators, we gather data sent by your browser as you visit our App (“Log Data”).",
                text2:"The collected Log Data, in this case, can include information like the user’s  Internet Protocol (“IP”) address, the type of browsers, the time and date of visit and alternative statistics.",
                text3:"Also, we might employ third-party services like Google Analytics which gather, manage and assess this…",
                text4:"The log Data part is for enterprises which employ analytics of tracking services in WebApps or apps, such as Google Analytics for the entire disclosure part, to come up with your Privacy policy."
            },
            {
                had:"Communications",
                text:"We might use the available personal information to communicate with you in the form of newsletters, promotional materials and other such information which…",
                text2:"The communications part is for enterprises that might contact users through email or alternative techniques. For the full disclosure part, come up with your Privacy Policy.",
            },
            {
                had:"Security",
                text:"While the security of your personal information remains paramount to us, it is important to note that no technique of transmission of information over the internet, or any electronic technique in this case; is 100% secure. Even though we attempt to employ commercially legit techniques to safeguard your personal information, we do not guarantee absolute security.",
            },
            {
                had:"Changes to the Current Privacy Policy",
                text:"The current Privacy Policy is effective as of May 28, 2017; it will be as effective as long as there are no notified changes in its provisions in the future. This implies that it is effective as posted on the current page.",
                text2:"The right to either change or update the privacy policy is exclusively ours to use at any time; users are advised to monitor our Privacy Policy from time to Time.",
                text3:"Consequently, your progressive use of the available service, after we post any changes to the Privacy Policy, on the current page will include your acknowledgment of the changes and your agreeing to comply and be bound by the changed Privacy Policy.",
                text4:"At any time that we come up with material modification to the current Privacy policy, we will provide you with a notification either through the email addresses you availed to us or by putting an identifiable notice on our WebApp."
            },
            {
                had:"Contact Us",
                text:"In case you have any concerns regarding the current Privacy Policy, Kindly keep in touch with us using the available means.",
            }
        ]

        const policyArray = policyList.map((data, i)=>
            <li key={i}>
                {(data.had)?<h6>{data.had}</h6>:null}
                <p>{data.text}</p>
                {(data.text2)?<p>{data.text2}</p>:null}
                {(data.text3)?<p>{data.text3}</p>:null}
                {(data.text4)?<p>{data.text4}</p>:null}
            </li>
        );

        const { title, description, keywords, g_title, g_description, g_content, f_title, f_description, f_content, t_title, t_description, t_content } = this.props.meta;

        return (
            <Fragment>
                <Helmet>
                    <title>{title}</title>
                    <meta name="description" id='web_dis' content={description} />
                    <meta name="keywords" id='web_key' content={keywords} />

                    <meta name="apple-itunes-app" content="https://itunes.apple.com/us/app/redline-coin-crypto-signals/id1326274166" />
                    <meta name="google-play-app" content="https://play.google.com/store/apps/details?id=com.redline.coin" />

                    {/* Google / Search Engine Tags */}
                    <meta itemprop="name" id='google_title' content={g_title} />
                    <meta itemprop="description" id='google_dis' content={g_description} />
                    <meta itemprop="image" id='google_img' content={g_content} />

                    {/* Facebook Meta Tags */}
                    <meta property="og:url" content="https://redlinecoin.com" />
                    <meta property="og:type" content="website" />
                    <meta property="og:title" id='facebook_title' content={f_title} />
                    <meta property="og:description" id='facebook_dis' content={f_description} />
                    <meta property="og:image" id='facebook_img' content={f_content} />

                    {/* Twitter Meta Tags */}
                    <meta name="twitter:card" content="summary_large_image" />
                    <meta name="twitter:title" id='twitter_title' content={t_title} />
                    <meta name="twitter:description" id='twitter_dis' content={t_description} />
                    <meta name="twitter:image" id='twitter_img' content={t_content} />
                </Helmet>
                <div className="container">
                    <div className="policy_body">
                        <h1>Privacy Policy</h1>

                        <ul className="policy_ul">
                            { policyArray }
                        </ul>
                    </div>
                </div>
            </Fragment>
        )
    }
}

const mapStateToProps = state =>({
    meta:state.meta.metaData
});

const mapDispatchToProps = dispatch =>({
    loadMeta:(data)=>dispatch(loadMeta(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(Policy);
