import React, { Component, lazy, Suspense } from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { connect } from 'react-redux';
import { loadMeta } from '../../redux/actions/metaActions';
import ArrowRight from '../../assets/image/arrow-right.svg';
// import { Helmet } from "react-helmet";
const Helmet = lazy(()=>import('react-helmet'));

class Home extends Component {
    
    componentDidMount(){
        const { loadMeta } = this.props;
        loadMeta('home');
    }
    openChekout = () => {
        this.props.history.push('/checkout');
    }
    render() {

        const { title, description, keywords, g_title, g_description, g_content, f_title, f_description, f_content, t_title, t_description, t_content } = this.props.meta;
        // console.log(this.props.meta);

        return (
            <div>
                <Suspense fallback={""}>
                    <Helmet>
                        <title>{title}</title>
                        <meta name="description" id='web_dis' content={description} />
                        <meta name="keywords" id='web_key' content={keywords} />

                        <meta name="apple-itunes-app" content="https://itunes.apple.com/us/app/redline-coin-crypto-signals/id1326274166" />
                        <meta name="google-play-app" content="https://play.google.com/store/apps/details?id=com.redline.coin" />

                        {/* Google / Search Engine Tags */}
                        <meta itemprop="name" id='google_title' content={g_title} />
                        <meta itemprop="description" id='google_dis' content={g_description} />
                        <meta itemprop="image" id='google_img' content={g_content} />

                        {/* Facebook Meta Tags */}
                        <meta property="og:url" content="https://redlinecoin.com" />
                        <meta property="og:type" content="website" />
                        <meta property="og:title" id='facebook_title' content={f_title} />
                        <meta property="og:description" id='facebook_dis' content={f_description} />
                        <meta property="og:image" id='facebook_img' content={f_content} />

                        {/* Twitter Meta Tags */}
                        <meta name="twitter:card" content="summary_large_image" />
                        <meta name="twitter:title" id='twitter_title' content={t_title} />
                        <meta name="twitter:description" id='twitter_dis' content={t_description} />
                        <meta name="twitter:image" id='twitter_img' content={t_content} />
                    </Helmet>
                
                    <BuyBitcoin openChekout={this.openChekout} />
                    <SignalProfilt />
                    <TopInvestment />
                    <BitcoinBuy />
                    <TopYoutube />
                    <AlertComponent />
                    <HowItWorks />
                </Suspense>
            </div>
        )
    }
}

const mapStateToProps = state =>({
    meta:state.meta.metaData
});

const mapDispatchToProps = dispatch =>({
    loadMeta:(data)=>dispatch(loadMeta(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(Home);

// buy bitcoin component
const openAppleStore = () =>{
    window.open('https://itunes.apple.com/us/app/redline-coin-crypto-signals/id1326274166', '_blank');
}
const openPlayStore = () =>{
    window.open('https://play.google.com/store/apps/details?id=com.redline.coin', '_blank');
}

var currYear = new Date().getFullYear();
const BuyBitcoin = (props) =>(
    <section className="BuyBitcoin_body">
        <div className="container">
            <div className="bitcoin_block">
                <h1>Buy Bitcoin in {currYear}?</h1>
                <p>We Inform you for the right time to<br className="sm-hidden md-hidden lg-hidden" /> <span className="green-color">Buy</span>, <span className="blue-color">Hold</span> or <br className="xs-hidden display-none" /> <span className="red-color">Don’t Buy</span> Bitcoin and<br className="sm-hidden md-hidden lg-hidden" /> other altcoin.</p>
                                
                <button className="app-storeBtn app-storeBtn3" onClick={openAppleStore}><span className="apple-iconBig"></span> <p className="font-12">Download on the<br /><span>App Store</span></p></button>
                <button className="play-storeBtn play-storeBtn3" onClick={openPlayStore}><span className="play-iconBig"></span> <p className="font-12">Download on the<br /><span>Google Play</span></p></button>
                <button className="get-premium-btn get-premium-btn3" onClick={props.openChekout} name="get_premium"><span className="get-premium-iconBig"></span><p className="font-12">Get Premium</p></button> 
            </div>
            <div className="mobileUi-block">
                <div className="mobile-ui"></div>
            </div>
        </div>
    </section>
)

// signal profilt
const optionsSilder ={
    0:{
        items:1,
        nav:false
    },
    600:{
        items:2,
        nav:false
    },
    1000:{
        items:5,
        nav:false,
        loop:false
    }
}
const SignalProfilt = () =>(
    <section className="bg-investing">
        <div className="container">
            <div className="main-block">
                <div className="top-investing-block top-block3 sm-hidden md-hidden lg-hidden">

                    <h4>Signals for profit</h4>
                    <p>Signal will send you push notifications<br className="sm_hidden"/> before you should buy a cryptocoin for<br /> maximum profit.</p>
                </div>
                <div className="sm-hidden">
                    <OwlCarousel className="owl-theme" loop={false} margin={10} nav={false} responsive={ optionsSilder } >
                        <div className="item">
                            <div className="mobile-ui2"></div>
                        </div>
                        <div className="item">
                            <div className="mobile-Etherium "></div>
                        </div>
                        <div className="item">
                            <div className="mobile-signalResult"></div>
                        </div>
                    </OwlCarousel>
                </div>

                <div className="top-investing-block xs-hidden display-none">
                    <h4>Signals for maximum profit</h4>
                </div>
                            
                <div className="mobile-ui2 xs-hidden display-none"></div>
                <div className="mobile-Etherium xs-hidden display-none"></div>
                <div className="mobile-signalResult xs-hidden display-none"></div>
            </div>
        </div>
    </section>
);

// top investment
const TopInvestment = () =>(
    <section className="bg-buyBitcoin2">
        <div className="container box-cona">
            <div className="mobileUi-block" id="order2">
                <div className="mobile-uiSignal"></div>
            </div>
            <div className="bitcoin_block3" id="order1">
                <h3 className="xs-hidden ">Top 5 crypto to <span className="blue-color">invest</span> in {currYear}</h3>
                <h3 className="destop-view">Top 5 Crypto To Invest</h3>
                <p>We will help you estimate and predict<br className="sm-hidden md-hidden lg-hidden" /> the best 5 cryptocurrency to invest<br className="sm-hidden md-hidden lg-hidden" /> for long or short term.</p>
            </div>
                        
        </div>
    </section>
);

// bitcoin buy component
const BitcoinBuy = () =>(
    <section className="bg-buyBitcoin">
        <div className="container">
            <div className="bitcoin_block4 sm-hidden md-hidden lg-hidden">
                <h3>Websites to Buy crypto</h3>
                <p>Top websites to Buy, Sell or<br/> Exchanges Crypto. Ranked by safety,<br/> regulation, fee, payments &amp; trust.</p>
            </div>
                        
            <div className="bitcoin_block4 xs-hidden ">
                <h3>Top websites to <span className="green-color">Buy</span> Crypto</h3>
                <p>Top websites to Buy, Sell or Exchanges Crypto.<br/> Ranked by safety, regulation, fee, payments &amp; trust.</p>
            </div>
            <div className="mobileUi-block ">
                <div className="mobile-uiWebsite"></div>
            </div>
                        
        </div>
    </section>
);

// top youtube component
const TopYoutube = () =>(
    <section className="bg-buyBitcoin">
        <div className="container">
            <div className="bitcoin_block3">
                <h3 className="xs-hidden">Top <span className="red-color">YouTube</span> Channels</h3>
                <h3 className="sm-hidden md-hidden lg-hidden center-text">Top YouTube Channels</h3>
                <p>Want to learn more about<br className="sm-hidden md-hidden lg-hidden" /> cryptocurrency? Here is<br className="xs-hidden" /> a list of top<br className="sm-hidden md-hidden lg-hidden" /> YouTube channels in many languages.</p>
            </div>
            <div className="mobileUi-block">
                <div className="mobile-uiYoutube"></div>
            </div>
        </div>
    </section>
);

// alert component
const AlertComponent = () =>(
    <section className="bg-buyBitcoin">
        <div className="container">
            <div className="bitcoin_block4 sm-hidden md-hidden lg-hidden">
                <h3>Alerts for any changes</h3>
                <p>Alert will help you to receive a<br /> notification of any update or changes.</p>
            </div>
            <div className="mobileUi-block ">
                <div className="mobile-uiAlert"></div>
            </div>
            <div className="bitcoin_block4 xs-hidden ">
                <h3>Create <span className="blue-color">alerts</span> for any changes</h3>
                <p>Alert will help you to receive a notification of any<br /> update or changes.</p>
            </div>
        </div>
    </section>
);

// how it works
const HowItWorks = () =>(
    <div>
        <section className="how-bg xs-hidden ">
            <div className="container">
                <div className="hadding">
                    <h3>How status works?</h3>
                </div>
                <div className="image-blocks">
                    <div className="row">
                        <div className="span-4">
                            <div className="imag_block ">
                                <div className="storngbuy"></div>
                            </div>
                        </div>
                        <div className="span-4">
                            <div className="imag_block ">
                                <div className="dontbuy"></div>
                            </div>
                        </div>
                        <div className="span-4">
                            <div className="imag_block ">
                                <div className="hold"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className="btn-download-block sm-hidden  md-hidden lg-hidden">
            <div className="container">
                <div className="download-block">
                    <button className="app-storeBtn" onClick={openAppleStore}> <span className="apple-iconBig"></span> <p>Download on the <br /><span>App Store</span></p></button>
                    <button className="play-storeBtn play-storeBtn2" onClick={openPlayStore}> <span className="play-iconBig"></span> <p>Download on the <br /><span>Google Play</span></p></button>
                </div>
            </div>
        </section>
    </div>
)

