import * as ACTION_TYPES from '../actions/types';

const initialState={
    token:sessionStorage.getItem('token'),
    //userId:sessionStorage.getItem('userId'),
    isAuth:null,
    isLoading:false,
    user:null,
    loginLoading:false, 
    signupLoading:false,
    forgotLoading:false,
    forgetEmail:null,
    resendLoading:false,
    securityLoading:false,
    hmacKey:sessionStorage.getItem('keyHmac'),
    userName:'',
    userEmail:'',
    forError:''
}

const usersR = (state = initialState, action)=>{
    switch(action.type){
        case ACTION_TYPES.USER_LOADING:
            return{
                ...state,
                isLoading:true
            }

        case ACTION_TYPES.USER_LOADED:
            return{
                ...state,
                isAuth:true,
                isLoading:false,
                user:action.payload
            }

        case ACTION_TYPES.LOGIN_SEND:
            return{
                ...state,
                loginLoading:true,
            }
        case ACTION_TYPES.SIGNUP_SEND:
            return{
                ...state,
                signupLoading:true,
            }

        case ACTION_TYPES.LOGIN_SUCCESS:
        case ACTION_TYPES.SIGNUP_SUCCESS:
            sessionStorage.setItem('token', action.payload);
            // sessionStorage.setItem('userId',action.payload.user_id);
            return {
                ...state,
                ...action.payload,
                isAuth:true,
                isLoading:false,
                token:sessionStorage.getItem('token'),
               // userId:sessionStorage.getItem('userId'),
                loginLoading:false,
                signupLoading:false
            }
        case ACTION_TYPES.FORGOT_SEND:
            return {
                ...state,
                forgotLoading:true,
            }
        case ACTION_TYPES.FORGOT_SUCCESS:
            return {
                ...state,
                forgotLoading:false,
                forgetEmail:action.payload.email
            }

        case ACTION_TYPES.RESEND_SEND:
            return{
                ...state,
                resendLoading:true
            }
        
        case ACTION_TYPES.RESEND_SUCCESS:
            return{
                ...state,
                resendLoading:false
            }

        case ACTION_TYPES.SECURITY_SEND:
            return{
                ...state,
                securityLoading:true
            }
        
        case ACTION_TYPES.SECURITY_SUCCESS:
            return {
                ...state,
                securityLoading:false
            }
        
        case ACTION_TYPES.AUTH_ERROR:
        case ACTION_TYPES.LOGOUT_SUCCESS:
        case ACTION_TYPES.LOGIN_FAIL:
        case ACTION_TYPES.SIGNUP_FAIL:
        case ACTION_TYPES.FORGOT_FAIL:
        case ACTION_TYPES.SECURITY_FAIL:
        case ACTION_TYPES.RESEND_FAIL:
        sessionStorage.removeItem('token');
        sessionStorage.removeItem('userId')
            return{
                ...state,
                token:null,
                userId:null,
                user:null,
                isAuth:false,
                isLoading:false,
                loginLoading:false,
                signupLoading:false,
                forgotLoading:false,
                securityLoading:false,
                resendLoading:false
            }
        case ACTION_TYPES.INTERCOM_SUCCESS:
            sessionStorage.setItem('userName', action.payload.name);
            sessionStorage.setItem('userEmail',action.payload.email);
            return{
                ...state,
                userName:sessionStorage.getItem('userName'),
                userEmail:sessionStorage.getItem('userEmail')
            }
        case ACTION_TYPES.INTERCOM_FAIL:
            sessionStorage.removeItem('userName');
            sessionStorage.removeItem('userEmail');
            return{
                ...state,
                userName:'',
                userEmail:''
            }
        case ACTION_TYPES.HMAC_SUCCESS:
            sessionStorage.setItem('keyHmac', action.payload);
            return{
                ...state,
                hmacKey:sessionStorage.getItem('keyHmac')
            }
        case ACTION_TYPES.HMAC_FAIL:
            sessionStorage.removeItem('keyHmac');
            return{
                ...state,
                hmacKey:sessionStorage.getItem('keyHmac')
            }

        default:
            return state;
    }
}

export default usersR;