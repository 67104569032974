import * as ACTION_TYPES from '../actions/types';

const initialState ={
    isLoader:false,
    isSubmitted:false,
    msg:null
}
const contactReducer = (state = initialState, action) =>{
    switch(action.type){
        case ACTION_TYPES.CONTACT_SUBMIT:
            return{
                ...state,
                isLoader:true
            }
        case ACTION_TYPES.CONTACT_SUCCESS:
            return{
                ...state,
                isLoader:false,
                isSubmitted:true,
                msg:action.payload
            }
        case ACTION_TYPES.CONTACT_FAIL:
            return{
                ...state,
                isLoader:false
            }
        case ACTION_TYPES.CONTACT_SUBMIT_FALSE:
            return{
                ...state,
                isSubmitted:false
            }
        default:
            return state;
    }
}

export default contactReducer;