import React, { Component, Fragment } from 'react';
import { apiHelper } from '../../helper/api_helper';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import SearchIcons2 from '../../assets/image/search_icon2.svg';
import SearchIcons from '../../assets/image/search_icon.svg';
import AddCurIcons from '../../assets/image/add_icon.svg';
import RecordNotFound from '../RecordNotFound';
import LoaderComponent from '../LoaderComponent';
import ArrowRight from '../../assets/image/arrow-right.svg';
import AppDM from '../AppDM/AppDM';
import CryptoModal from './CryptoModal';
import { jwtDecode } from '../../helper/jwt_helper';
import { loadMeta } from '../../redux/actions/metaActions';
import { Helmet } from "react-helmet";
import { OPEN_LOGIN, TOGGLE_APPDM, TOGGLE_CRYPMODAL } from '../../redux/actions/types';
import './buyornot.css';
import Modal from '../Modal'

class BuyOrSell extends Component {

    _unmounted = false;

    constructor(props) {
        super(props);
        this.state = {
            mSearchO: false,
            isLoader: false,
            allCoins: [],
            searchArray: [],
            selectedCoins: [],
            search: '',
            selected: [],
            payModal: false,
            payResponse: false
        }
    }

    componentDidMount() {
        this._unmounted = true;

        const { loadMeta } = this.props;

        loadMeta('buy-or-not');

        if (sessionStorage.getItem('token')) {

            this.getCoinWithLogin();
            var queryParam = this.props.location.search;
            var params = new URLSearchParams(queryParam);
            if (params.get('token') && params.get('PayerID')) {
                this.paypalSuccess();
            }

        } else {

            this.getCoinsWithoutLogin();

        }

    }

    paypalSuccess = async () => {
        try {

            this.setState({
                isLoader: true
            })
            const headers = {
                'Authorization': sessionStorage.getItem('token')
            }

            var queryParam = this.props.location.search;
            var params = new URLSearchParams(queryParam);

            let formData = new FormData();
            formData.append('token', params.get('token'))
            formData.append('PayerID', params.get('PayerID'))
            formData.append('url', '/buy-or-sell')

            const response = await apiHelper('subscription/payPal/success', 'POST', formData, headers);
            const res = response.data;
            if (res.status) {

                this.setState({
                    payResponse: true,
                    payModal: true,
                    isLoader: false
                })
                // window.location.href = res.data
                sessionStorage.setItem('token', res.data)
                localStorage.removeItem('returnPurchaseURL')
            } else {
                if (this._unmounted) {
                    this.setState({
                        payResponse: false,
                        payModal: true,
                        isLoader: false
                    })
                }
            }

        } catch (err) {
            console.error(err.message);
            if (this._unmounted) {
                this.setState({
                    payResponse: false,
                    payModal: true,
                    isLoader: false
                })
            }
        }
    }

    componentWillUnmount() {
        this._unmounted = false;
    }

    componentDidUpdate(prevProps) {

        const { crypModal } = this.props;

        if (crypModal !== prevProps.crypModal) {
            this.getCoinWithLogin();
        }
    }

    getCoinsWithoutLogin = async () => {
        try {

            this.setState({
                isLoader: true
            })

            const response = await apiHelper('coin', 'GET', null, null);
            const res = response.data;
            if (res.status) {

                const decode = jwtDecode(res.data);
                const firstArray = decode.filter(info => info.symbol === 'BTC');
                const secondArray = decode.filter(info => info.symbol !== 'BTC');

                const newArray = [...firstArray, ...secondArray];
                if (this._unmounted) {
                    this.setState({
                        isLoader: false,
                        allCoins: newArray,
                        searchArray: newArray
                    })
                }

            } else {
                if (this._unmounted) {
                    this.setState({
                        isLoader: false,
                        allCoins: [],
                        searchArray: []
                    })
                }
            }

        } catch (err) {
            console.error(err.message);
            if (this._unmounted) {
                this.setState({
                    isLoader: false,
                    allCoins: [],
                    searchArray: []
                })
            }
        }
    }

    getCoinWithLogin = async () => {
        try {

            const headers = {
                'Authorization': sessionStorage.getItem('token')
            }

            this.setState({
                isLoader: true
            })

            const response = await apiHelper('user/coin', 'GET', null, headers);
            const res = response.data;
            if (res.status) {

                const decode = jwtDecode(res.data);
                const firstArray = decode.filter(info => info.symbol === 'BTC');
                const secondArray = decode.filter(info => info.symbol !== 'BTC');

                const newArray = [...firstArray, ...secondArray];

                if (this._unmounted) {
                    this.setState({
                        allCoins: newArray,
                        searchArray: newArray
                    })
                    var queryParam = this.props.location.search;
                    var params = new URLSearchParams(queryParam);
                    if (!params.get('token') && !params.get('PayerID')) {
                        this.setState({
                            isLoader: false,
                        })
                    }
                }

            } else {
                if (this._unmounted) {
                    this.setState({
                        isLoader: false,
                        allCoins: [],
                        searchArray: []
                    })
                }
            }

        } catch (err) {
            console.error(err.message);
            if (this._unmounted) {
                this.setState({
                    isLoader: false,
                    allCoins: [],
                    searchArray: []
                })
            }
        }
    }

    toggleMSarch = () => {
        this.setState({
            mSearchO: !this.state.mSearchO,
        })
    }

    getSearch = (e) => {
        this.setState({
            search: e.target.value
        })
    }

    searchSubmit = (e) => {
        e.preventDefault();
        const { search, allCoins } = this.state;

        if (search !== '') {

            const searchArr = allCoins.filter(type => type.name.toLowerCase().indexOf(search.toLowerCase()) !== -1);
            this.setState({
                searchArray: searchArr
            })

        } else {

            this.setState({
                searchArray: allCoins
            })

        }
    }

    openChekout = () => {
        if (sessionStorage.getItem('token') && sessionStorage.getItem('token') !== '') {
            this.props.history.push('/checkout');
        } else {
            localStorage.setItem("isPurchase", "true");
            localStorage.setItem("returnPurchaseURL", "/buy-or-sell");
            this.props.openLogin()
        }
    }

    renderList = (data, i) => {
        const token = sessionStorage.getItem('token')
        const decode = jwtDecode(token);
        // const { selectedCoins } = this.state;
        const { toggleAPPModal } = this.props;
        // const isSelect = selectedCoins.includes(data.id);
        return (<li key={i}>
            <div className="main-listB"><div className="num-block"><p> {i + 1} </p> </div> <div className="img-blocs"><img src={"http://s3.us-east-2.amazonaws.com/cdn.redlinecoin.com/img_coins/" + data.img_url} alt={data.name} /> </div>
                <div className="text-blocks"><h6> <span className="text-had"> {data.name} </span> <span className="cur-tex"><span className="dot-rtex"></span> {data.symbol + '/USD'} </span></h6>
                    <p> {'$' + data.usd_price} {(data.change_24_hour_usd > 0) ? <span className="stok-resu green"> {'(+' + data.change_24_hour_usd + '%)'} </span> : <span className="stok-resu red">{'(' + data.change_24_hour_usd + '%)'}</span>} </p>
                </div>
                <div className="btns-blxck"> {token && decode.subscription === '0' ?
                    // (!isSelect && selectedCoins.length> 0) ?
                    // <button type="button" className="btn-status" name="paid" onClick={() => toggleAPPModal()}>Premium Only</button>
                    data.symbol !== "BTC" ? <button type="button" className="btn-status" name="paid" onClick={this.openChekout}> Premium Only </button> :
                        (() => {
                            switch (data.recommendation) {
                                case 1:
                                    return <button type="button"
                                        className="btn-status"
                                        name="str-sell"
                                        onClick={
                                            () => toggleAPPModal()
                                        }> Strong Don 't Buy</button>;
                                case 2:
                                    return <button type="button"
                                        className="btn-status"
                                        name="sell"
                                        onClick={
                                            () => toggleAPPModal()
                                        }> Don 't Buy</button>;
                                case 3:
                                    return <button type="button"
                                        className="btn-status"
                                        name="hold"
                                        onClick={
                                            () => toggleAPPModal()
                                        }> Hold </button>;
                                case 4:
                                    return <button type="button"
                                        className="btn-status"
                                        name="buy"
                                        onClick={
                                            () => toggleAPPModal()
                                        }> Buy </button>;
                                case 5:
                                    return <button type="button"
                                        className="btn-status"
                                        name="str-buy"
                                        onClick={
                                            () => toggleAPPModal()
                                        }> Strong Buy </button>;
                                default:
                                    return null;
                            }
                        })() :
                    // data.symbol === "BTC" ?
                    (() => {
                        switch (data.recommendation) {
                            case 1:
                                return <button type="button"
                                    className="btn-status"
                                    name="str-sell"
                                    onClick={
                                        () => toggleAPPModal()
                                    }> Strong Don 't Buy</button>;
                            case 2:
                                return <button type="button"
                                    className="btn-status"
                                    name="sell"
                                    onClick={
                                        () => toggleAPPModal()
                                    }> Don 't Buy</button>;
                            case 3:
                                return <button type="button"
                                    className="btn-status"
                                    name="hold"
                                    onClick={
                                        () => toggleAPPModal()
                                    }> Hold </button>;
                            case 4:
                                return <button type="button"
                                    className="btn-status"
                                    name="buy"
                                    onClick={
                                        () => toggleAPPModal()
                                    }> Buy </button>;
                            case 5:
                                return <button type="button"
                                    className="btn-status"
                                    name="str-buy"
                                    onClick={
                                        () => toggleAPPModal()
                                    }> Strong Buy </button>;
                            default:
                                return null;
                        }
                    })()
                    //  : <button type="button" className="btn-status" name="paid" onClick={this.openChekout}> Premium Only </button>

                }

                    <button type="button" className="btn-r_arrow" onClick={this.openChekout}> <img src={ArrowRight} alt="Arrow" /></button> </div> </div>
        </li>
        )
    }

    render() {

        const token = sessionStorage.getItem('token');

        const {
            openLogin,

            toggleCryptoModal,
            meta,

            meta: { title, description, keywords, g_title, g_description, g_content, f_title, f_description, f_content, t_title, t_description, t_content }

        } = this.props;

        const { isLoader, searchArray, search } = this.state;

        var modalData = {
            payModal: this.state.payModal,
            payResponse: this.state.payResponse,
            url: '/buy-or-sell'
        }

        return (
            <Fragment>
                {Object.keys(meta).length !== 0 ?
                    <Helmet>
                        <title> {title} </title>
                        <meta name="title" content={title} />
                        <meta name="description" id='web_dis' content={description} />
                        <meta name="keywords" id='web_key' content={keywords} />

                        <meta name="apple-itunes-app" content="https://itunes.apple.com/us/app/redline-coin-crypto-signals/id1326274166" />
                        <meta name="google-play-app" content="https://play.google.com/store/apps/details?id=com.redline.coin" />
                        { /* Google / Search Engine Tags */}
                        <meta itemprop="name" id='google_title' content={g_title} />
                        <meta itemprop="description" id='google_dis' content={g_description} />
                        <meta itemprop="image" id='google_img' content={g_content} />

                        { /* Facebook Meta Tags */}
                        <meta property="og:url" content="https://redlinecoin.com" />
                        <meta property="og:type" content="website" />
                        <meta property="og:title" id='facebook_title' content={f_title} />
                        <meta property="og:description" id='facebook_dis' content={f_description} />
                        <meta property="og:image" id='facebook_img' content={f_content} />

                        { /* Twitter Meta Tags */}
                        <meta name="twitter:card" content="summary_large_image" />
                        <meta name="twitter:title" id='twitter_title' content={t_title} />
                        <meta name="twitter:description" id='twitter_dis' content={t_description} />
                        <meta name="twitter:image" id='twitter_img' content={t_content} />
                    </Helmet>
                    :
                    null
                }

                <section className="buyotnot_bg">
                    <div className="container">
                        <div className="bon_body">
                            <h1> Buy or Sell
                                {(!this.state.mSearchO) ? <span className="btn-blc">
                                    <button type="button" className="btn-searchs" onClick={this.toggleMSarch}>
                                        <img src={SearchIcons2} alt="search" />
                                    </button>
                                    {(token !== null) ?
                                        <button type="button" className="btn-addCur" onClick={() => toggleCryptoModal()}> <img src={AddCurIcons} alt="Add Coins" /> </button> :
                                        <button type="button" className="btn-addCur" onClick={() => openLogin()}> <img src={AddCurIcons} alt="Add Coins" /> </button>}</span>
                                    :
                                    <form action="/" method="POST" onSubmit={(e) => this.searchSubmit(e)} className="search-form">
                                        <span className="input_block"> <input type="text" className="input_search" name="search" placeholder="Search..." autoComplete="off" defaultValue={search} onChange={this.getSearch} />
                                            <button type="button" className="btn-cancel" onClick={this.toggleMSarch}> Cancel </button> </span>
                                    </form>
                                }

                            </h1>
                            <div className="main-bloc">
                                <div className="filters-block">
                                    <form action="/" method="POST" onSubmit={(e) => this.searchSubmit(e)} className="search-form">
                                        <input type="text" className="serch_input" name="search" autoComplete="off" defaultValue={search} onChange={this.getSearch} placeholder="Search..." />
                                        <button type="submit" aria-label="search" className="search-btn"> <img src={SearchIcons} alt="search" /></button>
                                    </form> {
                                        token ?
                                            <button type="button" className="btn_addCryp" onClick={() => toggleCryptoModal()}> Add cryptocurrency </button> :
                                            <button type="button" className="btn_addCryp" onClick={() => openLogin()}> Add cryptocurrency </button>
                                    } </div>
                            </div>
                            <div className="main-blcks">
                                <ul className="bon_block">
                                    {
                                        isLoader ? <LoaderComponent /> :
                                            searchArray.length > 0 ? searchArray.map((data, i) => this.renderList(data, i)) : <RecordNotFound />
                                    }
                                </ul>
                            </div>
                            {
                                token ?
                                    <button type="button" className="btn-crypto" onClick={() => toggleCryptoModal()}> {'100 available cryptocurrency'} </button> :
                                    <button type="button" className="btn-crypto" onClick={() => openLogin()}> {'100 available cryptocurrency'} </button>
                            }

                        </div> </div> </section>

                { /* app modal */}
                <AppDM />

                { /* crypto Modal */} {
                    sessionStorage.getItem('token') ?
                        <CryptoModal />
                        :
                        null
                }

                {this.state.payModal ?
                    <Modal modalData={modalData} /> :
                    null}

            </Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    meta: state.meta.metaData,
    crypModal: state.buyornot.crypModal
});

const mapDispatchToProps = (dispatch) => ({
    // loadCoins: () => dispatch({ type: LOADING_ALLCOINS }),
    // loadAllCoinData: () => dispatch(loadAllCoins()),
    openLogin: () => dispatch({ type: OPEN_LOGIN }),
    // ActiveCoinsData: () => dispatch(loadActiveCoins()),
    toggleAPPModal: () => dispatch({ type: TOGGLE_APPDM }),
    toggleCryptoModal: () => dispatch({ type: TOGGLE_CRYPMODAL }),
    loadMeta: (data) => dispatch(loadMeta(data))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BuyOrSell));