import { TOGGLE_APPDM } from '../actions/types';

const initialState = {
    showModal:false
}

const AppDMReducers = (state = initialState, action) => {
    switch(action.type){
        case TOGGLE_APPDM:
            return{
                ...state,
                showModal:!state.showModal
            }
        default:
            return state;
    }
}

export default AppDMReducers;