import * as ACTION_TYPES from '../actions/types';

const initialState = {
    metaMSG:null,
    status:null,
    id:null
}
const MetaError = (state = initialState, action) =>{
    switch(action.type){
        case ACTION_TYPES.NEWS_GET_ERROR:
            return{
                ...state,
                metaMSG:action.payload.msg,
                status:action.payload.status,
                id:action.payload.id
            }
        case ACTION_TYPES.NEWS_CLEAR_ERROR:
            return{
                ...state,
                metaMSG:null,
                status:null,
                id:null
            }
        default:
            return state;
    }
}

export default MetaError;