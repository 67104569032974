import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import './TopYoutube.css';
import { loadLang, loadYoutube } from '../../redux/actions/youtubeActions';
import LoaderComponent from '../LoaderComponent';
import RecordNotFound from '../RecordNotFound';
import { loadMeta } from '../../redux/actions/metaActions';
import { Helmet } from "react-helmet";

class TopYoutube extends Component {

    constructor(props) {
        super(props);
        this.state = {
            channelArray: [],
            searchVal: '',
            lang: '',
            sortPop: '',
            sortPopVal: '',
            isLangDrop: false,
            isSortDrop: false,
            isOverlay: false
        }

        this.filterSearch = this.filterSearch.bind(this);
        this.openDropD = this.openDropD.bind(this);
        this.closeDrop = this.closeDrop.bind(this);
        this.getLangu = this.getLangu.bind(this);
    }

    // UNSAFE_componentWillMount() {
    //     this.setState({
    //         lang: 'All',
    //         sortPop: 'Popularity',
    //         sortPopVal: 'viewCount'
    //     });
    // }

    componentDidMount() {
        this.setState({
            lang: 'All',
            sortPop: 'Popularity',
            sortPopVal: 'viewCount'
        });
        // const { searchVal, lang, sortPopVal } = this.state;
        // const lang2 = lang.toLowerCase();
        // const searchVal2 = searchVal.toLowerCase();
        const data = { lang: 'all', searchVal: '', sortPopVal:'viewCount' };

        const { loadChannel, loadLang, loadMeta } = this.props;

        // api function call
        loadMeta('top-youtube');
        loadChannel(data);
        loadLang();
    }

    // componentDidUpdate(prevProps) {
    //     const { youtubeArray } = this.props;
    //     if (youtubeArray.length !== prevProps.youtubeArray.length) {
    //         // console.log('if');
    //         this.setState({
    //             channelArray: youtubeArray
    //         })
    //     }
    // }

    filterSearch = (e) => {

        const search = e.target.value;
        const { lang, sortPopVal } = this.state;
        const lang2 = lang.toLowerCase();        
        const data = { lang: lang2, searchVal: search, sortPopVal };

        const { loadChannel } = this.props;

        this.setState({
            searchVal: search
        });
        loadChannel(data);
    }

    getLangu = (val) => {

        const { searchVal, sortPopVal } = this.state;
        const lang2 = val.toLowerCase();
        const searchVal2 = searchVal.toLowerCase();
        const data = { lang: lang2, searchVal: searchVal2, sortPopVal };

        const { loadChannel } = this.props;
        this.setState({
            lang: val,
            isOverlay: !this.state.isOverlay,
            isLangDrop: !this.state.isLangDrop
        });
        loadChannel(data);
    }

    getSortPop = (val) => {

        const sort = val.value;
        const { searchVal, lang } = this.state;
        const lang2 = lang.toLowerCase();
        const searchVal2 = searchVal.toLowerCase();
        const data = { lang: lang2, searchVal: searchVal2, sortPopVal: sort };

        const { loadChannel } = this.props;

        this.setState({
            sortPop: val.label,
            sortPopVal: sort,
            isSortDrop: !this.state.isSortDrop,
            isOverlay: !this.state.isOverlay
        });
        loadChannel(data);
    }

    openDropD = (id) => {
        if (id === 'language') {
            this.setState({
                isLangDrop: !this.state.isLangDrop,
                isSortDrop: false,
                isOverlay: !this.state.isOverlay
            });
        } else {
            // if(id === 'sortPopular')
            this.setState({
                isLangDrop: false,
                isSortDrop: !this.state.isSortDrop,
                isOverlay: !this.state.isOverlay
            });
        }
    }

    closeDrop = () => {
        this.setState({
            isLangDrop: false,
            isSortDrop: false,
            isOverlay: false
        });
    }

    renderYoutubeChannel = (data, i) => {
        const subscribers = data.subscriberCount.toString();
        const url_youtube = "https://www.youtube.com/channel/";
        return <li key={i}>
            <div className="channel_body2">
                <div className="num-bl">
                    <p>{i + 1}</p>
                </div>
                <div className="text-content2">
                    <h6>{data.title}</h6>
                </div>
                <div className="butto_block mobile-v">
                    <a href={url_youtube + data.channel_id} target="_blank" rel="noopener noreferrer">Open</a>
                </div>
                <div className="subscrib_block">
                    <ul className="ul-subscri">
                        <li>
                            <h6>Subscribers</h6>
                            <p className="red">{kFormatter(subscribers)}</p>
                        </li>
                        <li>
                            <h6>Uploads</h6>
                            <p>{kFormatter(data.videoCount)}</p>
                        </li>
                        <li>
                            <h6>Likes</h6>
                            <p className="green">{kFormatter(data.likes)}</p>
                        </li>
                    </ul>
                </div>
                <div className="butto_block desktop-v">
                    <a href={url_youtube + data.channel_id} target="_blank" rel="noopener noreferrer">Open</a>
                </div>

            </div>
        </li>
    }


    render() {
        const { isLoader, langArray } = this.props;

        const languageArray = langArray.map((data, i) =>
            <li key={i} onClick={() => this.getLangu(data.name)} className={(this.state.lang === data.name) ? 'active' : ''}>{data.name}</li>
        )

        const sort_popular = [
            { label: "Popularity", value: "popularity" }, { label: "Subscribers", value: "subscriberCount" }, { label: "Likes", value: "likes" }, { label: "Uploads", value: "videoCount" }
        ]
        const sort_popularArray = sort_popular.map((data, i) =>
            <li key={i} onClick={() => this.getSortPop(data)} className={(this.state.sortPop === data.label) ? 'active' : ''}>{data.label}</li>
        )

        const { searchVal, isLangDrop, isOverlay, isSortDrop } = this.state;

        const { title, description, keywords, g_title, g_description, g_content, f_title, f_description, f_content, t_title, t_description, t_content } = this.props.meta;

        // console.log(this.props.youtubeArray);

        return (
            <Fragment>
                <Helmet>
                    <title>{title}</title>
                    <meta name="description" id='web_dis' content={description} />
                    <meta name="keywords" id='web_key' content={keywords} />

                    <meta name="apple-itunes-app" content="https://itunes.apple.com/us/app/redline-coin-crypto-signals/id1326274166" />
                    <meta name="google-play-app" content="https://play.google.com/store/apps/details?id=com.redline.coin" />

                    {/* Google / Search Engine Tags */}
                    <meta itemprop="name" id='google_title' content={g_title} />
                    <meta itemprop="description" id='google_dis' content={g_description} />
                    <meta itemprop="image" id='google_img' content={g_content} />

                    {/* Facebook Meta Tags */}
                    <meta property="og:url" content="https://redlinecoin.com" />
                    <meta property="og:type" content="website" />
                    <meta property="og:title" id='facebook_title' content={f_title} />
                    <meta property="og:description" id='facebook_dis' content={f_description} />
                    <meta property="og:image" id='facebook_img' content={f_content} />

                    {/* Twitter Meta Tags */}
                    <meta name="twitter:card" content="summary_large_image" />
                    <meta name="twitter:title" id='twitter_title' content={t_title} />
                    <meta name="twitter:description" id='twitter_dis' content={t_description} />
                    <meta name="twitter:image" id='twitter_img' content={t_content} />
                </Helmet>
                <div className={(!isOverlay) ? "background_overlay" : "background_overlay active"} id="OverlayId2" onClick={this.closeDrop}></div>
                <section className="youtube_bg">
                    <div className="container">
                        <div className="youtube_body2">
                            <h1>Top Youtube</h1>

                            <div className="youtube_content">
                                <div className="yt-content-wrap">
                                    <ul className="yt-tab-wrap">
                                        <li id="active-search">
                                            <div className="tab-search">
                                                <input type="text" className="form-youtube" id="ytSearch" name="ytSearch" placeholder="Search" onChange={this.filterSearch} value={searchVal} />
                                                <button type="button" className="mobile-v btn-search" onClick={this.openInputSearch}></button>
                                            </div>
                                        </li>
                                        <li id="serch">
                                            <div className="lang_block">
                                                <div className="click-block" >
                                                    <label>Language</label>
                                                    <p name="language" className="input_youtube" onClick={() => this.openDropD('language')} defaultValue={this.state.lang} readOnly={true}>{this.state.lang}</p>
                                                    {/* <input type="text" name="language"  className="input_youtube" onClick={(e)=>this.openDropD(e, 'language')} defaultValue={this.state.lang} readOnly={true} /> */}
                                                </div>
                                                <div className={(!isLangDrop) ? "drop_block2" : "drop_block2 active"} id="language">
                                                    <label className="had">Language <span className="close_icon"></span></label>
                                                    <ul className="menu_drop">
                                                        <li onClick={() => this.getLangu('All')} className={(this.state.lang === 'All') ? 'active' : ''}>{'All'}</li>
                                                        {languageArray}
                                                    </ul>
                                                </div>
                                            </div>
                                        </li>
                                        <li id="serch2">
                                            <div className="lang_block">
                                                <div className="click-block" onClick={this.openDropD}>
                                                    <label>Sort by</label>
                                                    <p name="sort_popular" className="input_youtube" onClick={() => this.openDropD('sortPopular')} defaultValue={this.state.sortPop} readOnly={true}>{this.state.sortPop}</p>
                                                    {/* <input type="text" name="sort_popular" className="input_youtube" onClick={(e)=>this.openDropD(e, 'sortPopular')} defaultValue={this.state.sortPop} readOnly={true} /> */}
                                                </div>
                                                <div className={(!isSortDrop) ? "drop_block2" : "drop_block2 active"} id="sortPopular">
                                                    <label className="had">Sort by <span className="close_icon"></span></label>
                                                    <ul className="menu_drop">
                                                        {sort_popularArray}
                                                    </ul>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                {(isLoader) ?
                                    <LoaderComponent />
                                    :
                                    <ul className="channel_block2">
                                        {(this.props.youtubeArray.length > 0) ?
                                            this.props.youtubeArray.map((data, i) => this.renderYoutubeChannel(data, i))
                                            :
                                            <RecordNotFound />
                                        }
                                    </ul>
                                }
                            </div>
                        </div>
                    </div>
                </section>
            </Fragment>
        )
    }
}

const mapStateToProps = state => ({
    isLoader: state.youtube.isLoader,
    youtubeArray: state.youtube.youtubeArray,
    langArray: state.youtube.langArray,
    meta: state.meta.metaData
});

const mapDispatchToProps = dispatch => ({
    loadChannel: (data) => dispatch(loadYoutube(data)),
    loadLang: () => dispatch(loadLang()),
    loadMeta: (data) => dispatch(loadMeta(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(TopYoutube);

function kFormatter(num) {
    let neg = false;
    if (num < 0) {
        num = num * -1;
        neg = true;
    }
    if (num >= 1000000000) {
        if (neg) {
            return -1 * (num / 1000000000).toFixed(0).replace(/\.0$/, '') + 'G';
        }
        return (num / 1000000000).toFixed(0).replace(/\.0$/, '') + 'G';
    }
    if (num >= 1000000) {
        if (neg) {
            return -1 * (num / 1000000).toFixed(0).replace(/\.0$/, '') + 'M';
        }
        return (num / 1000000).toFixed(0).replace(/\.0$/, '') + 'M';
    }
    if (num >= 1000) {
        if (neg) {
            return -1 * (num / 1000).toFixed(0).replace(/\.0$/, '') + 'K';
        }
        return (num / 1000).toFixed(0).replace(/\.0$/, '') + 'K';
    }
    return num;
}
