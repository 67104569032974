import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import './TopSignal.css';
import DefaultCoinIcon from '../../assets/image/logo.svg'
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import RecordNotFound from '../RecordNotFound';
import { jwtDecode } from '../../helper/jwt_helper';
import LoaderComponent from '../LoaderComponent';
import moment from 'moment'
import AppDM from '../AppDM/AppDM';
import { TOGGLE_APPDM, OPEN_LOGIN } from '../../redux/actions/types';
import { loadCalcultor, loadOPenSignal, loadCloseSignal } from '../../redux/actions/signalActions';
import ArrowRight from '../../assets/image/arrow-right.svg';
import { loadMeta } from '../../redux/actions/metaActions';
import { Helmet } from "react-helmet";
import InfiniteScroll from "react-infinite-scroll-component";
import ScrollLoader from '../ScrollLoader';
import { apiHelper } from '../../helper/api_helper';
import Modal from '../Modal'

class TopSignal extends Component {

    _unmounted = false;

    constructor(props) {
        super(props);
        this.state = {
            valPR: '1Y',
            isPayLoader: false,
            isLoader2: false,
            cpage: 1,
            cTotal: 0,
            cSData: [],
            isCSLoader: true,
            isOSignal: true,
            isCSignal: false,
            isPResult: false,
            TotalIncome: null,
            ProfitVal: null,
            totalRetrun: null,
            inputVal: 500,
            TRYear: '',
            valMon: '',
            payModal: false,
            payResponse: false,
        }

        this.openTabs = this.openTabs.bind(this);
        this.getPRVal = this.getPRVal.bind(this);
        this.minsValue = this.minsValue.bind(this);
        this.pulsValue = this.pulsValue.bind(this);
    }

    componentDidMount() {
        this._unmounted = true;
        this.setState({
            valMon: '12 Months',
        });
        const { loadCalcultor, loadOPenSignal, loadMeta } = this.props;
        const { valPR } = this.state;
        loadMeta('top-signal');
        loadCalcultor(valPR.toLowerCase());
        loadOPenSignal();
        this.getCloseCoinsAPI(this.state.cpage);
        // loadCloseSignal(this.state.cpage);

        var queryParam = this.props.location.search;
        var params = new URLSearchParams(queryParam);
        if (params.get('token') && params.get('PayerID')) {
            this.paypalSuccess();
        }
    }

    paypalSuccess = async () => {
        try {

            this.setState({
                isPayLoader: true
            })
            const headers = {
                'Authorization': sessionStorage.getItem('token')
            }

            var queryParam = this.props.location.search;
            var params = new URLSearchParams(queryParam);

            let formData = new FormData();
            formData.append('token', params.get('token'))
            formData.append('PayerID', params.get('PayerID'))
            formData.append('url', '/top-signal')

            const response = await apiHelper('subscription/payPal/success', 'POST', formData, headers);
            const res = response.data;
            if (res.status) {

                this.setState({
                    payResponse: true,
                    payModal: true,
                    isPayLoader: false
                })
                // window.location.href = res.data
                sessionStorage.setItem('token', res.data)
                localStorage.removeItem('returnPurchaseURL')
            } else {
                if (this._unmounted) {
                    this.setState({
                        payResponse: false,
                        payModal: true,
                        isPayLoader: false
                    })
                }
            }

        } catch (err) {
            console.error(err.message);
            if (this._unmounted) {
                this.setState({
                    payResponse: false,
                    payModal: true,
                    isPayLoader: false
                })
            }
        }
    }

    componentWillUnmount() {
        this._unmounted = false;
    }

    componentDidUpdate(prevProps) {
        const { CalcuData } = this.props;

        if (CalcuData !== prevProps.CalcuData) {
            // const TRYear2 = CalcuData.total_gain.replace(/,/g,'');
            const TRYear2 = CalcuData.total_gain;

            const min = this.state.inputVal;
            const totalVal = (Number(TRYear2) * min / 100) + min;
            const TotalIncome = parseFloat(totalVal).toFixed(2);
            const ProfitVal = totalVal - min;

            this.setState({
                TotalIncome,
                ProfitVal,
                TRYear: CalcuData.total_gain
            })
        }
    }

    getCloseCoinsAPI = async (page) => {
        try {
            if (page === 1) {
                if (this._unmounted) {

                    this.setState({
                        isLoader2: true
                    })
                }
            }

            const response = await apiHelper(`signal/close/${page}`, "GET", null, null);
            const res = response.data;
            if (res.status) {
                const decode = jwtDecode(res.data);
                if (page > 1) {
                    if (decode.close_signal_data && decode.close_signal_data.length > 0) {
                        if (this._unmounted) {
                            this.setState({
                                isLoader2: false,
                                cSData: [...this.state.cSData, ...decode.close_signal_data],
                                isCSLoader: true
                            });
                        }
                    }
                    else {
                        if (this._unmounted) {
                            this.setState({
                                isLoader2: false,
                                cSData: [...this.state.cSData, ...decode.close_signal_data],
                                isCSLoader: false
                            });
                        }
                    }
                } else {
                    if (this._unmounted) {
                        this.setState({
                            isLoader2: false,
                            cSData: [...this.state.cSData, ...decode.close_signal_data],
                            isCSLoader: true,
                            cTotal: decode.total_count
                        });
                    }
                }


            } else {
                if (this._unmounted) {
                    this.setState({
                        cSData: [],
                        isLoader2: false,
                        isCSLoader: false,
                        cTotal: 0
                    })
                }
            }

        } catch (err) {
            console.log(err.message);
            if (this._unmounted) {
                this.setState({
                    cSData: [],
                    isLoader2: false,
                    isCSLoader: false,
                    cTotal: 0
                })
            }
        }
    }

    openTabs = (id) => {
        switch (id) {
            case 'open':
                this.setState({
                    isOSignal: true,
                    isCSignal: false,
                    isPResult: false,
                });
                break;
            case 'closed':
                this.setState({
                    isOSignal: false,
                    isCSignal: true,
                    isPResult: false,
                });
                break;
            case 'past':
                this.setState({
                    isOSignal: false,
                    isCSignal: false,
                    isPResult: true,
                });
                break;
            default:
                this.setState({
                    isOSignal: true,
                    isCSignal: false,
                    isPResult: false,
                });
                break;
        }
    }

    openChekout = () => {
        // if (sessionStorage.getItem('token') && sessionStorage.getItem('token') !== '') {
        //     this.props.history.push('/checkout');
        // } else {
        //     localStorage.setItem("isPurchase", "true");
        //     localStorage.setItem("returnPurchaseURL", "/top-signal");
        //     this.props.loginOpen()
        // }
        this.props.history.push('/checkout');
    }

    OSRender = (data, i) => {

        const token = sessionStorage.getItem('token')
        const decode = jwtDecode(token)
        const { img_url, coin_name, status_buy_or_not, timestamp } = data;
        const { toggleAPPModal } = this.props;
        // const d = new Date();
        // const dApi = new Date(timestamp);
        // const sendTime = moment('')
        // const currentTime = moment(d)
        // const days =  currentTime.diff(sendTime, 'days')
        const days = moment(timestamp, 'YYYYMMDD').fromNow();
        return (
            <li key={i}>
                <div className="main-listB cursor-pointer">
                    <div className="num-block"><p>{i + 1}</p></div>
                    <div className="img-blocs" onClick={() => toggleAPPModal()}>
                        <img src={`http://s3.us-east-2.amazonaws.com/cdn.redlinecoin.com/img_coins/${img_url}`} onError={this.imgErr} alt={coin_name} />
                    </div>
                    <div className="text-blocks" onClick={() => toggleAPPModal()}>
                        <h6>{coin_name}</h6>
                        {/* <p>{(time.month !== 0)? time.month+"M ":''}{(time.day !== 0)?time.day+"Day ":''}{(time.hour > 0)?time.hour+"Hours ":""}{time.minute+"Min"} ago</p> */}
                        <p>{days}</p>
                    </div>
                    <div className="btns-blxck btns-blxck2">
                        {/* <span className="active-icon"></span> */}
                        {/* (i < 2)?
                                (()=>{
                                    switch (status_buy_or_not){
                                        case 0: return <button type="button" className="btn-status m_hidden" name="str-sell" onClick={()=>toggleAPPModal()} >Strong Don't Buy</button>;
                                        case 1: return <button type="button" className="btn-status m_hidden" name="sell" onClick={()=>toggleAPPModal()} >Don't Buy</button>;
                                        case 2: return <button type="button" className="btn-status m_hidden" name="hold" onClick={()=>toggleAPPModal()} >Hold</button>;
                                         case 3: return <button type="button" className="btn-status m_hidden" name="buy" onClick={()=>toggleAPPModal()} >Buy</button>;
                                         case 4: return <button type="button" className="btn-status m_hidden" name="str-buy" onClick={()=>toggleAPPModal()} >Strong Buy</button>;
                                         default : return null;
                                     }
                                 })()
                        : */}
                        {decode ?
                            decode.subscription === '0' ?
                                <button type="button" className="btn-status m_hidden" name="paid" onClick={this.openChekout} >Premium Only</button>
                                :
                                (() => {
                                    switch (status_buy_or_not) {
                                        case 1: return <button type="button" className="btn-status m_hidden" name="str-sell" onClick={() => toggleAPPModal()} >Strong Don't Buy</button>;
                                        case 2: return <button type="button" className="btn-status m_hidden" name="sell" onClick={() => toggleAPPModal()} >Don't Buy</button>;
                                        case 3: return <button type="button" className="btn-status m_hidden" name="hold" onClick={() => toggleAPPModal()} >Hold</button>;
                                        case 4: return <button type="button" className="btn-status m_hidden" name="buy" onClick={() => toggleAPPModal()} >Buy</button>;
                                        case 5: return <button type="button" className="btn-status m_hidden" name="str-buy" onClick={() => toggleAPPModal()} >Strong Buy</button>;
                                        default: return null;
                                    }
                                })()
                            :
                            <button type="button" className="btn-status m_hidden" name="paid" onClick={this.openChekout} >Premium Only</button>
                        }
                        <button type="button" className="btn-r_arrow" onClick={() => toggleAPPModal()}><img src={ArrowRight} alt="Arrow" /></button>
                    </div>
                </div>
            </li>
        )
    }
    CSRender = (data, i) => {
        //const time = data.timer;
        const { toggleAPPModal } = this.props;
        return (
            <li key={i}>
                <div className="main-listB cursor-pointer">
                    <div className="num-block"><p>{i + 1}</p></div>
                    <div className="img-blocs" onClick={() => toggleAPPModal()}><img src={"http://s3.us-east-2.amazonaws.com/cdn.redlinecoin.com/img_coins/" + data.img_url} alt={data.coin_name} onError={this.imgErr} /></div>
                    <div className="text-blocks" onClick={() => toggleAPPModal()}>
                        <h6>{data.coin_name}</h6>
                        {/* <p>{(time.month !== 0)? time.month+"M ":''}{(time.day !== 0)?time.day+"day ":''}{time.hour+"Hours "+ time.minute+"Min"} ago</p> */}
                    </div>
                    <div className="btns-blxck">
                        <button type="button" className="btn-status m_hidden" name="paid" onClick={() => toggleAPPModal()} >{'Profit: ' + data.last_gain + '%'}</button>
                        <button type="button" className="btn-r_arrow" onClick={() => toggleAPPModal()}><img src={ArrowRight} alt="Arrow" /></button>
                    </div>
                </div>
            </li>
        )
    }

    imgErr = (e) => {
        e.target.src = DefaultCoinIcon
    }

    minsValue = () => {
        const val = 50;
        const mainVal = this.state.inputVal;
        const min = mainVal - val;
        const TRYear = this.state.TRYear;
        const totalVal = (Number(TRYear) * min / 100) + min;
        const val2 = parseFloat(totalVal).toFixed(2);
        const profit = totalVal - min;
        this.setState({
            inputVal: min,
            TotalIncome: val2,
            ProfitVal: profit
        })

    }
    pulsValue = () => {
        const val = 50;
        const mainVal = this.state.inputVal;
        const min = mainVal + val;
        const TRYear = this.state.TRYear;
        const totalVal = (Number(TRYear) * min / 100) + min;
        const val2 = parseFloat(totalVal).toFixed(2);
        const profit = totalVal - min;
        this.setState({
            inputVal: min,
            TotalIncome: val2,
            ProfitVal: profit,
        })
    }

    getPRVal = (data) => {
        this.setState({
            ValPR: data.label,
            valMon: data.num,
        })
        const val = data.label;
        const { loadCalcultor } = this.props;
        loadCalcultor(val.toLowerCase());
        // this.valueCalcu(val.toLowerCase());
        // this.valueCalcuYear(val.toLowerCase());
    }

    nextCoins = () => {
        // const { loadCloseSignal } = this.props;
        const inc = this.state.cpage + 1;
        this.setState({
            cpage: inc,
        })
        this.getCloseCoinsAPI(inc);
        // loadCloseSignal(inc);
    }

    render() {

        const PRChecklist = [
            { label: '1W', num: '7 days' }, { label: '1M', num: '1 Month' }, { label: '3M', num: '3 Months' }, { label: '6M', num: '6 Months' }, { label: '1Y', num: '12 Months' },
        ]
        const PRCList = PRChecklist.map((data, i) => {
            const ValPR = this.state.valPR;
            return <li key={i}>
                <input type="radio" name="PRMenu" id={data.label} onChange={() => this.getPRVal(data)} defaultChecked={(data.label === ValPR)} />
                <label htmlFor={data.label} className="radio_label">{data.label}</label>
            </li>
        });

        const { OSignalArray, isLoader, CalcuData } = this.props;

        const { title, description, keywords, g_title, g_description, g_content, f_title, f_description, f_content, t_title, t_description, t_content } = this.props.meta;

        var modalData = {
            payModal: this.state.payModal,
            payResponse: this.state.payResponse,
            url: '/top-signal'
        }

        // const { isOSignal, isCSignal, isPResult } = this.state;

        // let clasSlide = "bg_signalMenu";
        // if(isCSignal){
        //     clasSlide += ' active';
        // }else if(isPResult){
        //     clasSlide +=' active_2';
        // }

        const { TotalIncome, inputVal, valMon, cSData, cTotal, isPayLoader, isLoader2 } = this.state;
        return (
            <Fragment>
                <Helmet>
                    <title>{title}</title>
                    <meta name="description" id='web_dis' content={description} />
                    <meta name="keywords" id='web_key' content={keywords} />

                    <meta name="apple-itunes-app" content="https://itunes.apple.com/us/app/redline-coin-crypto-signals/id1326274166" />
                    <meta name="google-play-app" content="https://play.google.com/store/apps/details?id=com.redline.coin" />

                    {/* Google / Search Engine Tags */}
                    <meta itemprop="name" id='google_title' content={g_title} />
                    <meta itemprop="description" id='google_dis' content={g_description} />
                    <meta itemprop="image" id='google_img' content={g_content} />

                    {/* Facebook Meta Tags */}
                    <meta property="og:url" content="https://redlinecoin.com" />
                    <meta property="og:type" content="website" />
                    <meta property="og:title" id='facebook_title' content={f_title} />
                    <meta property="og:description" id='facebook_dis' content={f_description} />
                    <meta property="og:image" id='facebook_img' content={f_content} />

                    {/* Twitter Meta Tags */}
                    <meta name="twitter:card" content="summary_large_image" />
                    <meta name="twitter:title" id='twitter_title' content={t_title} />
                    <meta name="twitter:description" id='twitter_dis' content={t_description} />
                    <meta name="twitter:image" id='twitter_img' content={t_content} />
                </Helmet>
                <div className="container">
                    <div className="signal_body">
                        <h1>Live Signals</h1>
                        <div className="signal_blo">

                            <Tab.Container id="left-tabs-example" defaultActiveKey="openSignal">
                                <Nav variant="pills" className="tabs-ul">
                                    <Nav.Item>
                                        <Nav.Link eventKey="openSignal">Open <span>Signals</span> ({OSignalArray.length})</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="closedSignal">Closed <span>Signals</span> ({cTotal})</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="pastResult">Past Result</Nav.Link>
                                    </Nav.Item>
                                </Nav>
                                <Tab.Content>
                                    <Tab.Pane eventKey="openSignal">
                                        <div className="main-blcks signal-block signal-block2">
                                            <ul className="bon_block">
                                                {(isLoader || isPayLoader) ?
                                                    <LoaderComponent />
                                                    :
                                                    (OSignalArray.length > 0) ?
                                                        OSignalArray.map((data, i) => this.OSRender(data, i))
                                                        :
                                                        <RecordNotFound />
                                                }
                                            </ul>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="closedSignal">
                                        <div className="main-blcks signal-block">
                                            <ul className="bon_block" id="scrollableDivProfile">
                                                {(isLoader2) ?
                                                    <LoaderComponent />
                                                    :
                                                    (cSData.length > 0) ?
                                                        <InfiniteScroll className="width-forscrool" dataLength={cSData.length} next={this.nextCoins} hasMore={this.state.isCSLoader} loader={<ScrollLoader />} scrollableTarget="scrollableDivProfile">
                                                            {cSData.map((data, i) => this.CSRender(data, i))}
                                                        </InfiniteScroll>

                                                        :
                                                        <RecordNotFound />
                                                }
                                            </ul>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="pastResult">
                                        <div className="main-blcks PResult-block">
                                            <ul className="sTabs-ul">
                                                {PRCList}
                                            </ul>
                                            <div className="dat-block">
                                                <p>No of Trades <span className="text-block">{CalcuData !== null ? CalcuData.total_trades : null}</span></p>
                                                <p>Total Return <span className="text-block green-text">{CalcuData !== null ? CalcuData.total_gain.toFixed(2) + '%' : null}</span></p>
                                                <p>Avg Trade Return <span className="text-block green-text">{CalcuData !== null ? CalcuData.avg_return_trades.toFixed(2) + '%' : null}</span></p>
                                            </div>
                                            <div className="p-texts">
                                                <p>RedLineCoin dry run shows that an investment of
                                                    <span className="calc-block">
                                                        <button type="button" className="btn-mins" onClick={this.minsValue} disabled={!inputVal > 0}></button>
                                                        <input type="text" name="invest" className="input-invest" value={"$" + inputVal} onChange={this.handel} readOnly disabled />
                                                        <button type="button" className="btn-pluss" onClick={this.pulsValue}></button>
                                                    </span>
                                                    following our expert's daily trading signals in last {valMon} will become <span className="p-bold ptext-red">{'$' + numberWithCommas(TotalIncome)}</span>
                                                </p>
                                            </div>
                                        </div>
                                        <div className="ada_text">
                                            <h6>Past results should not be any indication of any future results.</h6>
                                            <p><span className="p-bold">RedLine Coin </span>does not make any guarantee or other promises as to any results that may be obtained from using our content. No one, should make any investment decision without first consulting his or her own financial advisor and conducting his or her own research , and due diligence, To the maximum extent permitted by law, RedLine Coin disclaims any and all liability in the event any information, Commentary, analysis, opinions, advice and/or, recommendations prove to be inaccurate, incomplete or unreliable or result in any investment or other losses .</p>
                                        </div>
                                    </Tab.Pane>
                                </Tab.Content>
                            </Tab.Container>

                        </div>
                    </div>
                </div>

                <AppDM />
                {this.state.payModal ?
                    <Modal modalData={modalData} /> :
                    null}
            </Fragment>
        )
    }
}


const mapStateToProps = (state) => ({
    isLoader: state.signal.isLoader,
    isLoader2: state.signal.isLoader2,
    OSignalArray: state.signal.openSignalArray,
    CSignalArray: state.signal.closeSignalArray,
    CalcuData: state.signal.calcData,
    subscriptions: state.signal.subscriptions,
    meta: state.meta.metaData
});

const mapDispatchToProps = (dispatch) => ({
    loginOpen: () => dispatch({ type: OPEN_LOGIN }),
    toggleAPPModal: () => dispatch({ type: TOGGLE_APPDM }),
    loadCalcultor: (type) => dispatch(loadCalcultor(type)),
    loadOPenSignal: () => dispatch(loadOPenSignal()),
    loadCloseSignal: (page) => dispatch(loadCloseSignal(page)),
    loadMeta: (data) => dispatch(loadMeta(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(TopSignal);

function numberWithCommas(x) {
    const value = parseFloat(x).toFixed(2)
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
