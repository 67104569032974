import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';
import Header from '../components/Header';
import Home from '../components/Home';
import Footer from '../components/Footer';
import BuyOrSell from '../components/Buyornot';
import BuyCrypto from '../components/BuyCrypto';
import TopNews from '../components/TopNews';
import TopYoutube from '../components/TopYoutube';
import TopSignal from '../components/TopSignal';
import HowItWorks from '../components/HowItWorks';
import ContactUs from '../components/ContactUs';
import TermsUse from '../components/TermsUse';
import Policy from '../components/Policy';
import Disclaimer from '../components/Disclaimer';
import Description from '../components/Description';
import AboutUs from '../components/AboutUs';
import FAQs from '../components/FAQs';
import Checkout from '../components/Checkout';
import NotFound404 from '../components/NotFound404';

export default class Routes extends Component {
    render() {
        return (
            <div>
                <Header />
                <Switch>
                    <Route exact path="/" component={Home} />
                    <Route path="/buy-or-sell" component={BuyOrSell} />
                    <Route path="/buy-crypto" component={BuyCrypto} />
                    <Route path="/news" component={TopNews} />
                    <Route path="/top-youtube" component={TopYoutube} />
                    <Route path="/top-signal" component={TopSignal} />
                    <Route path="/how-it-works" component={HowItWorks} />
                    <Route path="/contact-us" component={ContactUs} />
                    <Route path="/terms-of-use" component={TermsUse} />
                    <Route path="/policy" component={Policy} />
                    <Route path="/disclaimer" component={Disclaimer} />
                    <Route path="/description" component={Description} />
                    <Route path="/about-us" component={AboutUs} />
                    <Route path="/faqs" component={FAQs} />
                    <Route path="/checkout" component={Checkout} />                    
                    <Route path="**" component={NotFound404} />
                </Switch>
                <Footer />
            </div>
        )
    }
}
