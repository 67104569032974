import * as ACTION_TYPES from './types';

export const returnContactError = (msg, status, id)=>{
    return{
        type:ACTION_TYPES.CONTACT_GET_ERROR,
        payload:{ msg, status, id }
    }
}

export const clearContactError = () =>{
    return{
        type:ACTION_TYPES.CONTACT_CLEAR_ERROR
    }
}