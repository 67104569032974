import * as ACTION_TYPES from '../actions/types';
const initialState = {
    isLoader:false,
    allCoins:[],
    modalAllCoins: [],
    activeCoins:[],
    subscriptions:null,
    selected:[],
    crypModal:false,
    cryptoDS:[],
    commDS:'',
    isSmallLoader:false
}

const buyornot = (state = initialState, action) =>{
    switch(action.type){
        case ACTION_TYPES.LOADING_ALLCOINS:
            return{
                ...state,
                isLoader:true
            }
        case ACTION_TYPES.LOADED_ALLCOINS:
            return{
                ...state,
                allCoins:action.payload,
                isLoader:false,
            }
        case ACTION_TYPES.LOADED_MODALALLCOINS:        
            return {
                ...state,
                modalAllCoins: action.payload
            };
        case ACTION_TYPES.LOADED_ACTIVECOINS:
            const selectedCoins = action.payload.filter(type=>{
                return type.selected === '1';
            });
            const comm = selectedCoins.map(res=>{
                return res.symbol;
            });
            // console.log(action.payload);
            return {
                ...state,
                activeCoins:action.payload,
                cryptoDS:selectedCoins,
                commDS:comm.join(','),
                
            }
        case ACTION_TYPES.TOGGLE_CRYPMODAL:
            return{
                ...state,
                crypModal:!state.crypModal,
                // cryptoSelected:cryptSele
            }
        case ACTION_TYPES.CRYPTO_LOADING:
            return{
                ...state,
                isSmallLoader:true
            }
        case ACTION_TYPES.CRYPTOCOINS_SUCCESS:
            return{
                ...state,
                isSmallLoader:false,
                crypModal:false,
            }
        case ACTION_TYPES.CRYPTOCOINS_FAIL:
            return{
                ...state,
                isSmallLoader:false   
            }
        default:
            return state;
    }
} 

export default buyornot;