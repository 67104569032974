//import axios from 'axios';
import * as ACTION_TYPES from './types';
import { returnContactError } from './contactErrors';
import { apiHelper } from '../../helper/api_helper'

export const submitData=(data)=> dispatch =>{

    dispatch({type:ACTION_TYPES.CONTACT_SUBMIT});

    //const api_url = 'https://redlinecoin.com/api/index.php/mails/sendMail/contactUs';
    const { email } = data;

    let formData = new FormData();
    formData.append('email', email)

    return apiHelper('contactUs', 'POST', formData, null)
    //axios.post(api_url, formData, config)
        .then(res=>{
            if(res.data.status){
                dispatch({
                    type:ACTION_TYPES.CONTACT_SUCCESS,
                    payload:res.data.msg
                });
            }else{
                const err = res.data;
                dispatch(returnContactError(err.msg, 401, 'CONTACT_FAIL'));
                dispatch({type:ACTION_TYPES.CONTACT_FAIL});
            }
            
        })
        .catch(err=>{
            dispatch(returnContactError(err.msg, 401, 'CONTACT_FAIL'));
            dispatch({type:ACTION_TYPES.CONTACT_FAIL});
        })

}