import React, { Component, Fragment } from 'react';
import NoFoundImage from '../../assets/image/404.svg';
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";
import './NotFound404.css';

export default class NotFound404 extends Component {
    render() {
        return (
            <Fragment>
                <Helmet>
                    <title>RedLine Coin | Page not found</title>
                </Helmet>
                <div className="container">
                    <div className="block_404">
                        <div className="img_bloc">
                            <img src={NoFoundImage} alt="Page Not Found" />
                        </div>
                        <div className="had-block">
                            <h3>404</h3>
                            <h6>LOOKS LIKE YOU’RE LOST</h6>
                            <p>The page you are looking for is not available!</p>
                            <Link to="/" className="btn-backto2"> GO TO HOME</Link>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}
