import * as ACTION_TYPES from '../actions/types';

const initialState = {
    youtubeMSG:null,
    langMSG:null,
    status:null,
    id:null,
    cryptoMSG:null,
}

const topYoutubeError = (state = initialState, action) =>{
    switch(action.type){
        case ACTION_TYPES.YOUTUBE_GET_ERROR:
            return{
                ...state,
                youtubeMSG:action.payload.msg,
                status:action.payload.status,
                id:action.payload.id
            }
        case ACTION_TYPES.YOUTUBE_CLEAR_ERROR:
            return{
                ...state,
                youtubeMSG:null,
                status:null,
                id:null
            }
        case ACTION_TYPES.LANG_GET_ERROR:
            return{
                ...state,
                langMSG:action.payload.msg,
                status:action.payload.status,
                id:action.payload.id
            }
        case ACTION_TYPES.LANG_CLEAR_ERROR:
            return{
                ...state,
                langMSG:null,
                status:null,
                id:null
            }
        default:
            return state;
    }
}

export default topYoutubeError;