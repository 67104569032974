import React, { Component, Fragment } from 'react';
import FrontLoader from '../../assets/image/front_loader.gif';
import './index.css';

export default class LoaderComponent extends Component {
    render() {
        return (
            <Fragment>
                <div className="loader_block" id="loader">
                    <img src={ FrontLoader } alt="Loader" />
                </div>
            </Fragment>
        )
    }
}
