import React, { Component, Fragment } from 'react';
import NotFoundImg from '../../assets/image/404.svg';
import './index.css';

export default class RecordNotFound extends Component {
    render() {
        return (
            <Fragment>
                <div className="notFound">
                    <img src={NotFoundImg} alt="website not found" />
                    <p>Record not Found</p>
                </div>
            </Fragment>
        )
    }
}
