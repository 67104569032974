import * as ACTION_TYPES from './types';

// return errors

export const returnUserErrors = (msg, status, id)=>{
    return{
        type: ACTION_TYPES.USER_GET_ERROR,
        payload: { msg, status, id }
    }
}

export const returnLoginErrors = (msg, status, id)=>{
    return{
        type: ACTION_TYPES.LOGIN_GET_ERROR,
        payload: { msg, status, id }
    }
}

export const returnSignupErrors = (msg, status, id)=>{
    return{
        type: ACTION_TYPES.SIGNUP_GET_ERROR,
        payload: { msg, status, id }
    }
}

export const returnForgotErrors = (msg, status, id)=>{
    // console.log(msg);
    return{
        type: ACTION_TYPES.FORGOT_GET_ERROR,
        payload: { msg, status, id }
    }
}

export const returnResendErrors = (msg, status, id)=>{
    // console.log(msg);
    return{
        type: ACTION_TYPES.RESEND_GET_ERROR,
        payload: { msg, status, id }
    }
}

export const returnSecurityErrors = (msg, status, id)=>{
    // console.log(msg);
    return{
        type: ACTION_TYPES.SECURITY_GET_ERROR,
        payload: { msg, status, id }
    }
}

export const returnReplayErrors = (msg, status, id)=>{
    // console.log(msg);
    return{
        type: ACTION_TYPES.REPLAY_GET_ERROR,
        payload: { msg, status, id }
    }
}
export const returnInterErrors = (msg, status, id)=>{
    return{
        type: ACTION_TYPES.INTERCOM_GET_ERROR,
        payload: { msg, status, id }
    }
}

export const returnHmacErrors = (msg, status, id)=>{
    return{
        type: ACTION_TYPES.HMAC_GET_ERROR,
        payload: { msg, status, id }
    }
}

// clear errors
export const clearUserError = ()=>{
    return{
        type: ACTION_TYPES.USER_CLEAR_ERROR
    }
}

export const clearLoginError = ()=>{
    return{
        type: ACTION_TYPES.LOGIN_CLEAR_ERROR
    }
}

export const clearSignupError = ()=>{
    return{
        type: ACTION_TYPES.SIGNUP_CLEAR_ERROR
    }
}

export const clearForgotError = ()=>{
    return{
        type: ACTION_TYPES.FORGOT_CLEAR_ERROR
    }
}

export const clearResendError = ()=>{
    return{
        type: ACTION_TYPES.RESEND_CLEAR_ERROR
    }
}

export const clearSecurityError = ()=>{
    return{
        type: ACTION_TYPES.SECURITY_CLEAR_ERROR
    }
}

export const clearReplayError = ()=>{
    return{
        type: ACTION_TYPES.REPLAY_CLEAR_ERROR
    }
}
export const clearInterError = ()=>{
    return{
        type: ACTION_TYPES.INTERCOM_CLEAR_ERROR
    }
}

export const clearHmacError = ()=>{
    return{
        type: ACTION_TYPES.HMAC_CLEAR_ERROR
    }
}