import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import './Description.css';
import { loadMeta } from '../../redux/actions/metaActions';
import { Helmet } from "react-helmet";

class Description extends Component {

    componentDidMount(){
        const { loadMeta } = this.props;
        loadMeta('description');
    }

    render() {

        const listDescription = [
            {
                text:"RedLine Coin is a service provider of critical tips and leads to traders who desire to trade in cryptocurrencies but lack time to conduct extensive research on their own."
            },
            {
                text:"RedLine Coin avails efficient service in research; and through it, our qualified and professional traders will guide you with the help of verified and proprietary algorithm which gives information on the exact moment to purchase and sell the most trending cryptocurrencies."
            },
            {
                text:"Similar to the stock market, cryptocurrencies’ values fluctuate either upwards or downwards; hence if you do not know what to purchase, when to purchase it, and what to put on sale; you’re just guessing."
            },
            {
                text:"The Redline Coin will serve to notify you on the best moment to either buy or sell a cryptocoin for the maximum profit. You will be saved the trouble of researching, the flaw of guessing and missing out on the cryptocurrency."
            },
            {
                text:"DISCLAIMER: While trading in Cryptocurrency is potentially rewarding, there are alternative risks associated with it. Hence, one should be aware of the possible dangers and ready to accept them in their quest to invest in cryptocurrencies. It is thus not advisable to trade with money you cannot afford to lose. This is not a prompt to trade in cryptocurrencies, and it is not guaranteed that any given account will attain loses or profits. The important thing is that past failure or success is not necessarily an indication of upcoming results. Additionally, the trade should not be perceived as legal or financial counsel. One is supposed to consult with an attorney, or any particular professional to conclude on what best suits their personal needs.",
                text2:"RedLine Coin does not either promise or guarantee any given results that can be accessed from our content. One is strongly advised to make an investment decision after consulting with their attorney or any financial advisor and engaging in research and collection of intelligence. In compliance with legal grounds, RedLine Coin, the various officers involved, contractors and directors disclaim any form of liability in an instance whereby the data, analysis, commentary, opinions, and advice and/or recommendations appear to be imprecise, unreliable, unexpected or leads to any given form of investment losses."
            },
            {
                text:"REDLINE COIN IS NOT DESIGNED TO BE AN ULTIMATE SOURCE OF TRADING OR INVESTING ADVICE; USERS ARE STRONGLY COUNSELLED TO CONDUCT PERSONAL RESEARCH USING A VARIETY OF SOURCES OF DATA AND/OR SOLICITING FOR COUNSEL OF LICENSED PROFESSIONALS BEFORE ENGAGING IN TRADING OR INVESTING IN CRYPTOCURRENCIES."
            },
        ];

        const DesArray = listDescription.map((data, i)=>
            <li key={i}>
                <p>{data.text}</p>
                {(data.text2)? <p>{data.text2}</p>:null}
            </li>
        )

        const { title, description, keywords, g_title, g_description, g_content, f_title, f_description, f_content, t_title, t_description, t_content } = this.props.meta;

        return (
            <Fragment>
                <Helmet>
                    <title>{title}</title>
                    <meta name="description" id='web_dis' content={description} />
                    <meta name="keywords" id='web_key' content={keywords} />

                    <meta name="apple-itunes-app" content="https://itunes.apple.com/us/app/redline-coin-crypto-signals/id1326274166" />
                    <meta name="google-play-app" content="https://play.google.com/store/apps/details?id=com.redline.coin" />

                    {/* Google / Search Engine Tags */}
                    <meta itemprop="name" id='google_title' content={g_title} />
                    <meta itemprop="description" id='google_dis' content={g_description} />
                    <meta itemprop="image" id='google_img' content={g_content} />

                    {/* Facebook Meta Tags */}
                    <meta property="og:url" content="https://redlinecoin.com" />
                    <meta property="og:type" content="website" />
                    <meta property="og:title" id='facebook_title' content={f_title} />
                    <meta property="og:description" id='facebook_dis' content={f_description} />
                    <meta property="og:image" id='facebook_img' content={f_content} />

                    {/* Twitter Meta Tags */}
                    <meta name="twitter:card" content="summary_large_image" />
                    <meta name="twitter:title" id='twitter_title' content={t_title} />
                    <meta name="twitter:description" id='twitter_dis' content={t_description} />
                    <meta name="twitter:image" id='twitter_img' content={t_content} />
                </Helmet>
                <div className="container">
                    <div className="discr_body">
                        <h1>Description</h1>

                        <ul className="discr_ul">
                            { DesArray }
                        </ul>
                    </div>
                </div>
            </Fragment>
        )
    }
}

const mapStateToProps = state =>({
    meta:state.meta.metaData
});

const mapDispatchToProps = dispatch =>({
    loadMeta:(data)=>dispatch(loadMeta(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(Description);
