import React, { Component, Fragment } from 'react';
import { jwtDecode } from '../../helper/jwt_helper';
import { apiHelper } from '../../helper/api_helper';
import LoaderComponent from '../LoaderComponent';
import RecordNotFound from '../RecordNotFound';
import { connect } from 'react-redux';
import parse from 'html-react-parser';
import './AboutUs.css';
import { loadMeta } from '../../redux/actions/metaActions';
import { Helmet } from "react-helmet";

class AboutUs extends Component {

    constructor(props) {
        super(props);
        this.state = {
            aboutData: [],
            isLoader: true
        }
    }

    componentDidMount() {

        const { loadMeta } = this.props;
        loadMeta('about-us');

        //axios.get('https://redlinecoin.com/api/index.php/Abouts/get_about')
        apiHelper('aboutUS', 'GET', null, null)
            .then(res => {
                if (res.data.status) {
                    
                    const data = jwtDecode(res.data.data)
                    this.setState({
                        aboutData: data,
                        isLoader: false
                    });
                }
            })
            .catch(err => {
                console.log(err);
            })
    }

    render() {

        const { isLoader, aboutData } = this.state;
        const { title, description, keywords, g_title, g_description, g_content, f_title, f_description, f_content, t_title, t_description, t_content } = this.props.meta;

        return (
            <Fragment>
                <Helmet>
                    <title>{title}</title>
                    <meta name="description" id='web_dis' content={description} />
                    <meta name="keywords" id='web_key' content={keywords} />

                    <meta name="apple-itunes-app" content="https://itunes.apple.com/us/app/redline-coin-crypto-signals/id1326274166" />
                    <meta name="google-play-app" content="https://play.google.com/store/apps/details?id=com.redline.coin" />

                    {/* Google / Search Engine Tags */}
                    <meta itemprop="name" id='google_title' content={g_title} />
                    <meta itemprop="description" id='google_dis' content={g_description} />
                    <meta itemprop="image" id='google_img' content={g_content} />

                    {/* Facebook Meta Tags */}
                    <meta property="og:url" content="https://redlinecoin.com" />
                    <meta property="og:type" content="website" />
                    <meta property="og:title" id='facebook_title' content={f_title} />
                    <meta property="og:description" id='facebook_dis' content={f_description} />
                    <meta property="og:image" id='facebook_img' content={f_content} />

                    {/* Twitter Meta Tags */}
                    <meta name="twitter:card" content="summary_large_image" />
                    <meta name="twitter:title" id='twitter_title' content={t_title} />
                    <meta name="twitter:description" id='twitter_dis' content={t_description} />
                    <meta name="twitter:image" id='twitter_img' content={t_content} />
                </Helmet>
                <div className="container">
                    <div className="about_body">
                        <h1>About Us</h1>
                        {(isLoader) ?
                            <LoaderComponent />
                            :
                            (aboutData.length > 0) ?
                                <div className="about_blo">
                                    {aboutData.map((data) => data.content !== null? parse(data.content.replace(/<o:p>\s*<\/o:p>/g, "")): '')}
                                </div>
                                :
                                <RecordNotFound />
                        }

                    </div>
                </div>
            </Fragment>
        )
    }
}

const mapStateToProps = state => ({
    meta: state.meta.metaData
});

const mapDispatchToProps = dispatch => ({
    loadMeta: (data) => dispatch(loadMeta(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(AboutUs);
