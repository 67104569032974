import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux';
import StrBuyIcon from '../../assets/image/stbuy_new_icon.svg';
import BuyIcon from '../../assets/image/buy_new_icon.svg';
import HoldIcon from '../../assets/image/hold_new_icon.svg';
import SellIcon from '../../assets/image/sell_new_icon.svg';
import StrSellIcon from '../../assets/image/stsell_new_icon.svg';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import './HowItWorks.css';
import { loadMeta } from '../../redux/actions/metaActions';
import { Helmet } from "react-helmet";

class SignalStatusCom extends Component{

    items = (data, i) =>(
        <div className="item" key={i}>
            <div className="hwi_blocks_n">
                <img src={data.img} alt={data.title} />
                <div className="hw-content">
                    <h6>{data.title}</h6>
                    <p>{data.text}</p>
                </div>
            </div>
        </div>
    )
    
    render(){
        const { className2, optionsSilder, arraySignalS } = this.props;
        
        return (
            <Fragment>
                <OwlCarousel className={"owl-theme " + className2} loop={false} margin={10} nav={false} responsive={ optionsSilder } >
                    {arraySignalS.length > 0?
                        arraySignalS.map((data,i)=>this.items(data, i))
                        :
                        null
                    }
                </OwlCarousel>
            </Fragment>
        )
    }
}

class Steps extends Component {

    hwi_items = (data, i) =>(
        <div className="item" key={i}>
            <div className="hwi_blocks_m">
                <span className={"mobile_step"}>{i+1}</span>
                <div className="hw_m_content" id="order1">
                    <h6>{data.title}</h6>
                    <p>{data.text}</p>
                </div>
                <div className={data.uiClass} id="order2"></div>
            </div>
        </div>
    )

    dHwi_block = (data, i) =>(
        <div className="step_block" key={i}>
            <div className="step_ui">
                <div className="step_text">
                    <h6>{data.title}</h6>
                    <p>{data.text}</p>
                </div>
                <div className={data.uiClass}></div>
            </div>
        </div>
    )

    render(){
        const optionsSilder2 ={
            0:{
                items:1,
                nav:false,
                loop:true,
                autoplay:true,
                autoplayHoverPause:true
            },
            600:{
                items:2,
                nav:false,
                loop:true,
                autoplay:true,
                autoplayHoverPause:true
            },
            1000:{
                items:1,
                nav:true,
                loop:true,
                autoplay:true,
                autoplayHoverPause:true
            }
        }

        const hwi_array = [
            { uiClass:'mobile-uiAlert', title:"Activate Alert", text:"Go to settings tab to enable toggle alerts for trading signals notification and chosen cryptocurrency to set alerts" },
            { uiClass:"iphonex-exchange", title:"Follow trading signals", text:"Get alert and notification by following our daily trading signals for maximum profits on your preferred or at our recommended exchange." },
            { uiClass:"mobile-ui2", title:"Sit back and get notified", text:"Set up your trade and get real-time updates of market actions from the app." }
        ]

        return (
            <Fragment>
                <div className="hwi_steps">
                    {/* <div className="hwi_desktop">
                        
                        
                        {hwi_array.length > 0?
                            hwi_array.map((data, i)=>this.dHwi_block(data, i))
                            :
                            null
                        }

                        </div> 
                        <div className="step_element">
                            <span className="step_round step1">1</span>
                            <span className="step_round step2">2</span>
                            <span className="step_round step3">3</span>
                        </div>
                    </div> */}
                    <div className="hwi_mobile">
                        <OwlCarousel className={"owl-theme owl_hwi"} loop={false} margin={10} nav={false} responsive={ optionsSilder2 } >
                            {hwi_array.length > 0?
                                hwi_array.map((data,i)=>this.hwi_items(data, i))
                                :
                                null
                            }
                        </OwlCarousel>
                    </div>
                </div>
            </Fragment>
        )
    }
}

class HowItWorks extends Component {

    componentDidMount(){
        const { loadMeta } = this.props;
        loadMeta('how-it-works');
    }

    render() {

        const { title, description, keywords, g_title, g_description, g_content, f_title, f_description, f_content, t_title, t_description, t_content } = this.props.meta;

        const signalStatus_array = [
            {'img':StrBuyIcon,'title':"Strong Buy",'text':"It's a very good time to buy."},
            {'img':BuyIcon,'title':"Buy",'text':"It's a good time to buy."},
            {'img':HoldIcon,'title':"Hold",'text':"We can’t expect anything."},
            {'img':SellIcon,'title':"Don't Buy",'text':"It's not a good time to buy but it's a good time to sell if you are working as speculative capital."},
            {'img':StrSellIcon,'title':"Strong Don't Buy",'text':"It's not a good time to buy but it's a good time to sell if you are working as speculative capital."}
        ];

        const optionsSilder ={
            0:{
                items:1,
                nav:false,
                loop:true,
                autoplay:true,
                autoplayHoverPause:true
            },
            600:{
                items:1,
                nav:false,
                loop:true,
                autoplay:true,
                autoplayHoverPause:true
            },
            1000:{
                items:1,
                nav:false,
                loop:true,
                autoplay:true,
                autoplayHoverPause:true
            }
        }

        return (
            <Fragment>
                <Helmet>
                    <title>{title}</title>
                    <meta name="description" id='web_dis' content={description} />
                    <meta name="keywords" id='web_key' content={keywords} />

                    <meta name="apple-itunes-app" content="https://itunes.apple.com/us/app/redline-coin-crypto-signals/id1326274166" />
                    <meta name="google-play-app" content="https://play.google.com/store/apps/details?id=com.redline.coin" />

                    {/* Google / Search Engine Tags */}
                    <meta itemprop="name" id='google_title' content={g_title} />
                    <meta itemprop="description" id='google_dis' content={g_description} />
                    <meta itemprop="image" id='google_img' content={g_content} />

                    {/* Facebook Meta Tags */}
                    <meta property="og:url" content="https://redlinecoin.com" />
                    <meta property="og:type" content="website" />
                    <meta property="og:title" id='facebook_title' content={f_title} />
                    <meta property="og:description" id='facebook_dis' content={f_description} />
                    <meta property="og:image" id='facebook_img' content={f_content} />

                    {/* Twitter Meta Tags */}
                    <meta name="twitter:card" content="summary_large_image" />
                    <meta name="twitter:title" id='twitter_title' content={t_title} />
                    <meta name="twitter:description" id='twitter_dis' content={t_description} />
                    <meta name="twitter:image" id='twitter_img' content={t_content} />
                </Helmet>
                <div className="container">
                    <div className="hiw_body">
                        <h1>How it works?</h1>
                        <div className="hwi_blo">
                            <Steps />
                            <SignalStatusCom className2="owl_signals" optionsSilder={optionsSilder} arraySignalS={signalStatus_array} />
                            
                            {/* <table className="hiw_table">
                                <tbody>
                                    <tr>
                                        <td>
                                            <div className="hwi_block">
                                                <img src={StrBuyIcon} alt="Strong Buy" />
                                                <h6>Strong Buy</h6>
                                                <p>It's a very good time to buy.</p>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="hwi_block">
                                                <img src={BuyIcon} alt="Buy" />
                                                <h6>Buy</h6>
                                                <p>It's a good time to buy.</p>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan="2">
                                            <div className="hwi_block">
                                                <img src={HoldIcon} alt="Hold" />
                                                <h6>Hold</h6>
                                                <p>We can’t expect anything.</p>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className="hwi_block">
                                                <img src={SellIcon} alt="Don't Buy" />
                                                <h6>Don't Buy</h6>
                                                <p>It's not a good time to buy but it's a good time to sell if you are working as speculative capital.</p>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="hwi_block">
                                                <img src={StrSellIcon} alt="Strong Don't Buy" />
                                                <h6>Strong Don't Buy</h6>
                                                <p>It's not a good time to buy but it's a good time to sell if you are working as speculative capital.</p>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table> */}
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

const mapStateToProps = state =>({
    meta:state.meta.metaData
});

const mapDispatchToProps = dispatch =>({
    loadMeta:(data)=>dispatch(loadMeta(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(HowItWorks);
