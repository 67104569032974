import * as ACTION_TYPES from '../actions/types';

const initialState = {
    allCoinMSG:null,
    ActiveCoinMSG:null,
    status:null,
    id:null,
    cryptoMSG:null,
}

const buyornotError = (state = initialState, action) =>{
    switch(action.type){
        case ACTION_TYPES.ALLCOINS_GET_ERROR:
            return{
                ...state,
                allCoinMSG:action.payload.msg,
                status:action.payload.status,
                id:action.payload.id
            }
        case ACTION_TYPES.ALLCOINS_CLEAR_ERROR:
            return{
                ...state,
                allCoinMSG:null,
                status:null,
                id:null
            }
        case ACTION_TYPES.ACTIVECOINS_GET_ERROR:
            return{
                ...state,
                ActiveCoinMSG:action.payload.msg,
                status:action.payload.status,
                id:action.payload.id,
            }
        case ACTION_TYPES.ACTIVECOINS_CLEAR_ERROR:
            return{
                ...state,
                ActiveCoinMSG:null,
                status:null,
                id:null
            }
        case ACTION_TYPES.CRYPTO_GET_ERROR:
            return{
                ...state,
                cryptoMSG:action.payload.msg,
                status:action.payload.status,
                id:action.payload.id
            }
        case ACTION_TYPES.CRYPTO_CLEAR_ERROR:
            return{
                ...state,
                cryptoMSG:null,
                status:null,
                id:null
            }
        default:
            return state;
    }
}

export default buyornotError;