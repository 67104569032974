import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { apiHelper } from '../../helper/api_helper'
import { jwtDecode } from '../../helper/jwt_helper';
import { Accordion, AccordionItem } from 'react-light-accordion';
import 'react-light-accordion/demo/css/index.css';
import RecordNotFound from '../RecordNotFound';
import parse from 'html-react-parser';
import LoaderComponent from '../LoaderComponent';
import './FAQs.css';
import { loadMeta } from '../../redux/actions/metaActions';
import { Helmet } from "react-helmet";

class FAQs extends Component {

    constructor(props){
        super(props);
        this.state={
            isLoader:true,
            FaqData:[],
        }
    }

    componentDidMount(){

        const { loadMeta } = this.props;
        loadMeta('faqs');

        apiHelper('faqs', 'GET', null, null)
        //axios.get('https://redlinecoin.com/api/index.php/Faqs/get_faq')
        .then(res=>{
            console.log(res)
            if(res.data.status){
                
                const data = jwtDecode(res.data.data);
                
                this.setState({
                    FaqData:data,
                    isLoader:false,
                })
            }
        })
        .catch(error=>console.log(error))
    }

    handleEvent = (e) =>{
        let button = document.getElementsByClassName('faq_Q');
        for(let i = 0; i<button.length; i++){
            document.getElementsByClassName('faq_Q')[i].classList.remove('active');
        }
        e.target.classList.toggle('active');
        
        //e.target.classList.toggle('active');
    }

    renderFaqs = (data, i) =>{
        return   <AccordionItem title={data.question.replace(/(<([^>]+)>)/ig,"")} key={i}>
                     <div className="collaps_block">
                     {parse(data.answare)}
                     </div>
                 </AccordionItem>
     }

    render() {

        const { title, description, keywords, g_title, g_description, g_content, f_title, f_description, f_content, t_title, t_description, t_content } = this.props.meta;

        return (
            <Fragment>
                <Helmet>
                    <title>{title}</title>
                    <meta name="description" id='web_dis' content={description} />
                    <meta name="keywords" id='web_key' content={keywords} />

                    <meta name="apple-itunes-app" content="https://itunes.apple.com/us/app/redline-coin-crypto-signals/id1326274166" />
                    <meta name="google-play-app" content="https://play.google.com/store/apps/details?id=com.redline.coin" />

                    {/* Google / Search Engine Tags */}
                    <meta itemprop="name" id='google_title' content={g_title} />
                    <meta itemprop="description" id='google_dis' content={g_description} />
                    <meta itemprop="image" id='google_img' content={g_content} />

                    {/* Facebook Meta Tags */}
                    <meta property="og:url" content="https://redlinecoin.com" />
                    <meta property="og:type" content="website" />
                    <meta property="og:title" id='facebook_title' content={f_title} />
                    <meta property="og:description" id='facebook_dis' content={f_description} />
                    <meta property="og:image" id='facebook_img' content={f_content} />

                    {/* Twitter Meta Tags */}
                    <meta name="twitter:card" content="summary_large_image" />
                    <meta name="twitter:title" id='twitter_title' content={t_title} />
                    <meta name="twitter:description" id='twitter_dis' content={t_description} />
                    <meta name="twitter:image" id='twitter_img' content={t_content} />
                </Helmet>
                <div className="container">
                    <div className="faq_body">
                        <h1>Questions and Answers</h1>
                        <p className="etx">Please view our frequently asked questions to find answers to the most common questions about our services.</p>
                        {(this.state.isLoader)? 
                            <LoaderComponent />
                            :
                            <Accordion className="faqs_ul" atomic={true}>
                                
                                {(this.state.FaqData.length > 0)?
                                    this.state.FaqData.map((data, i)=> this.renderFaqs(data, i))
                                    :
                                    <RecordNotFound />
                                }
                            </Accordion>
                        }
                    </div>
                </div>
            </Fragment>
        )
    }
}

const mapStateToProps = state =>({
    meta:state.meta.metaData
});

const mapDispatchToProps = dispatch =>({
    loadMeta:(data)=>dispatch(loadMeta(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(FAQs);
