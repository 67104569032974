import * as ACTION_TYPES from '../actions/types';

const initialState={
    msg:null,
    loginMSG:null,
    signupMSG:null,
    forgotMSG:null,
    securityMSG:null,
    replayMSG:null,
    status:null,
    id:null,
    interMSG:null,
    hmacMSG:null
}

const errorR = (state=initialState, action) =>{
    switch(action.type){
        case ACTION_TYPES.USER_GET_ERROR:
            return{
                ...state,
                msg:action.payload.msg,
                status: action.payload.status,
                id : action.payload.id
            }
        case ACTION_TYPES.USER_CLEAR_ERROR:
            return{
                ...state,
                msg:null,
                status:null,
                id:null,
            }
        case ACTION_TYPES.SIGNUP_GET_ERROR:
        // console.log(action.payload);
            return{
                ...state,
                signupMSG:action.payload.msg,
                status: action.payload.status,
                id : action.payload.id
            }
        case ACTION_TYPES.SIGNUP_CLEAR_ERROR:
            return{
                ...state,
                status:null,
                id:null,
                signupMSG:null
            }
        case ACTION_TYPES.LOGIN_GET_ERROR:
            return{
                ...state,
                loginMSG:action.payload.msg,
                status: action.payload.status,
                id : action.payload.id
            }
        case ACTION_TYPES.LOGIN_CLEAR_ERROR:
            return{
                ...state,
                status:null,
                id:null,
                loginMSG:null
            }

        case ACTION_TYPES.FORGOT_GET_ERROR:
            return{
                ...state,
                forgotMSG:action.payload.msg,
                status:action.payload.status,
                id:action.payload.id
            }
        case ACTION_TYPES.FORGOT_CLEAR_ERROR:
            return{
                ...state,
                forgotMSG:null,
                status:null,
                id:null
            }
        
        case ACTION_TYPES.SECURITY_GET_ERROR:
            return{
                ...state,
                securityMSG:action.payload.msg,
                status:action.payload.status,
                id:action.payload.id
            }

        case ACTION_TYPES.SECURITY_CLEAR_ERROR:
            return{
                ...state,
                securityMSG:null,
                status:null,
                id:null
            }
        case ACTION_TYPES.REPLAY_GET_ERROR:
            return{
                ...state,
                replayMSG:action.payload.msg,
                status:action.payload.status,
                id:action.payload.id
            }
        case ACTION_TYPES.REPLAY_CLEAR_ERROR:
            return{
                ...state,
                replayMSG:null,
                status:null,
                id:null
            }
        case ACTION_TYPES.INTERCOM_GET_ERROR:
            return{
                ...state,
                interMSG:action.payload.msg,
                status:action.payload.status,
                id:action.payload.id
            }
        case ACTION_TYPES.INTERCOM_CLEAR_ERROR:
            return{
                ...state,
                interMSG:null,
                status:null,
                id:null
            }
        case ACTION_TYPES.HMAC_GET_ERROR:
            return{
                ...state,
                hmacMSG:action.payload.msg,
                status:action.payload.status,
                id:action.payload.id
            }
        case ACTION_TYPES.HMAC_CLEAR_ERROR:
            return{
                ...state,
                hmacMSG:null,
                status:null,
                id:null
            }

        default:
            return state;
    }
}

export default errorR;