import React, { Component } from 'react'
import { connect } from 'react-redux';
// import Modal from 'react-bootstrap/Modal';
import { TOGGLE_FORGOTMODAL, TOGGEL_SECURITYMODAL, FORGOT_SEND, FORGOT_FAIL } from '../redux/actions/types';
import { forgotSubmit } from '../redux/actions/authActions';
import UserLoader from '../assets/image/user-loader.gif';
import { clearForgotError } from '../redux/actions/errorActions';

class ForgotModal extends Component {

    constructor(props){
        super(props);
        this.state={
            forgotEmail:'',
            forgotError:{forgotEmail:''},
            emailValid:false,
            errorMessage2:null
        }

        this.getEmailValue = this.getEmailValue.bind(this);
        this.valid = this.valid.bind(this);
        this.resetPassowrd = this.resetPassowrd.bind(this);
    }

    getEmailValue=(e)=>{
        e.preventDefault();
        const { name, value } = e.target;
        this.setState({
            [name]:value
        }, ()=>this.valid(name,value))
    }

    valid = (name, value)=>{
        let forgotError = this.state.forgotError;
        let emailValid = this.state.emailValid;

        switch(name) {
            case 'forgotEmail':
                emailValid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
                forgotError.forgotEmail = emailValid ? '' : 'Email Must Required.';
                break;
            default:
                break;
        }

        this.setState({
            forgotError:forgotError,
            emailValid:emailValid
        })
    }

    resetPassowrd = async (e)=>{
        e.preventDefault();
        const { forgotSubmit, dataSend, forgotFail } = this.props;

        dataSend();

        const { forgotEmail } = this.state;
        if(forgotEmail !== ''){
            // let formData = new FormData();
            // formData.append('email', forgotEmail)

            // const response = await apiHelper('auth/send-otp', 'POST', formData, null);
            // const res
            forgotSubmit(forgotEmail);
            const { error } = this.props;
            // console.log(error);
            // if(error.id === 'FORGOT_FAIL'){
                this.setState({errorMessage2:error.forgotMSG});

            // }else{
            //     this.setState({errorMessage2:null})
            // }

            // ToggleSecuritiM();
        }else{
            forgotFail();
            this.setState({
                forgotError:{
                    forgotEmail:'Field is Required'
                }
            });
        }
    }

    componentDidUpdate(prevProps){
        const { error, isAuth } = this.props;
        // console.log(error);
        if(error !== prevProps.error){
            // check for login error
            if(error.id === 'FORGOT_FAIL'){
                this.setState({errorMessage2:error.forgotMSG});

            }else{
                this.setState({errorMessage2:null})
            }
        }

        // if auth, close modal
        if(isAuth){
            this.props.ToggleForgotM();
        }
    }

    render() {

        const forgotEmailError = this.state.forgotError.forgotEmail;

        const forgotFormValid = !this.state.emailValid;

        const { errorMessage2 } = this.state;

        const { ToggleForgotM, forgotLoading } = this.props;
        // console.log(this.props);
        return (
                <form method="POST" onSubmit={this.resetPassowrd}>
                    {(forgotEmailError.length > 0)? <p className="errorContact">{(forgotEmailError.length > 0)? this.state.forgotError.forgotEmail : ''}</p> :null}
                    <div className="form-group">
                        <input type="email" className="form-controllog forgot_input" placeholder="Enter your email here.." name="forgotEmail" value={this.state.forgotEmail} onChange={this.getEmailValue} autoComplete="off" />
                    </div>
                    <div className="form-group">
                        {/* {(this.state.forgotErrorMessage !== '')?<p className="errorContact">{this.state.forgotErrorMessage}</p>:null} */}
                        {(errorMessage2 !== null)?<p className="errorContact">{errorMessage2}</p>:null}
                    </div>
                    <div className="mt-60">
                        <div className="logOut-app_btn forgot_btn" >
                            <button type="submit" className="btn-resetP" disabled={forgotFormValid || forgotLoading}>{(forgotLoading)?<img className="sendLoader" src={UserLoader} alt="loader" />:"Reset Password"}</button>
                            <button type="button" className="btn-cancel" onClick={()=>ToggleForgotM()}>Cancel</button>
                        </div>
                    </div>
                </form>
        )
    }
}

const mapStateToProps=(state)=>({
    isAuth:state.auth.isAuth,
    error:state.error,
    forgotModal:state.authModal.forgotModal,
    forgotLoading:state.auth.forgotLoading,
    forgotMSG:state.error.forgotMSG
})

const mapDispatchToProps=dispatch=>({
    dataSend:()=>dispatch({type:FORGOT_SEND}),
    ToggleForgotM:()=>dispatch({type:TOGGLE_FORGOTMODAL}),
    ToggleSecuritiM:()=>dispatch({type:TOGGEL_SECURITYMODAL}),
    forgotSubmit:(email)=>dispatch(forgotSubmit(email)),
    clearError:()=>dispatch(clearForgotError()),
    forgotFail:()=>dispatch({type:FORGOT_FAIL})
})

export default connect(mapStateToProps, mapDispatchToProps)(ForgotModal)