import * as ACTION_TYPES from './types';
// import axios from 'axios';
import { returnMetaError } from './metaErrors';
import { jwtDecode } from '../../helper/jwt_helper';
import { apiHelper } from '../../helper/api_helper'

export const loadMeta = (page)=>dispatch=>{

   
    return apiHelper(`metaTag?pageName=${page}`, 'GET', null, null)
        .then(res=>{
            // console.log(res)
            if(res.data.status){
                dispatch({
                    type:ACTION_TYPES.META_SUCCESS,
                    payload:jwtDecode(res.data.data)
                    
                });
            }else{
                const err = res.data;
                dispatch(returnMetaError(err.msg,401, 'META_FAIL'));
                dispatch({type:ACTION_TYPES.META_FAIL})
            }
        })
        .catch(err=>{
            dispatch(returnMetaError(err.msg,401, 'META_FAIL'));
            dispatch({type:ACTION_TYPES.META_FAIL});
        })    

    
}