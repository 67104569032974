import React, { Component, Fragment } from 'react';
import { withRouter, Link, NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import Logo from '../assets/image/logo.svg';
import Login from './Login';
import Register from './Register';
import Modal from 'react-bootstrap/Modal';
import ForgotModal from './ForgotModal';
import Intercom from 'react-intercom';
import { logout } from '../redux/actions/authActions';
// import UserLoader from '../assets/image/user-loader.gif';
import { OPEN_LOGIN, CLOSE_LOGINM, LOGIN_TABS, TOGGLE_LOGOUTMODAL, TOGGEL_SECURITYMODAL, TOGGLE_FORGOTMODAL } from '../redux/actions/types';
import SecurityModal from './SecurityModal';
import { IntercomData, hash_hmac } from '../redux/actions/authActions';
import { jwtDecode } from '../helper/jwt_helper';
// import axios from 'axios';
// import CryptoJs from 'crypto-js';
// import { loadUser } from "../redux/actions/authActions";
// import store from '../redux/store';

class Header extends Component {
    constructor(props){
        super(props);
        this.state={
            sidebarOpen:false,
            activeMore:'/how-it-works',
            // isLogin:true,
            loginComp:true,
            forgotError:{
                forgotEmail:""
            },
            securityCode:'',
            newPassword:'',
            securityError:{securityCode:'',newPassword:''},
            sticky:false,
            // intercom
            UserID:'',
            userEmail:'',
            userName:'',
            // hmac-key
            hmacKey:null
        }

        this.openSidebar = this.openSidebar.bind(this);
        this.closeOverlay = this.closeOverlay.bind(this);
        // this.handleScroll = this.handleScroll.bind(this);
        // this.tabClick = this.tabClick.bind(this);
    }

    static getDerivedStateFromProps(props, state) {
        const pagePath = props.location.pathname.split('/')[1];

        if(pagePath === '' && pagePath === 'buy-or-sell' && pagePath === 'buy-crypto' && pagePath === 'news' && pagePath === 'top-youtube' && pagePath === 'top-signal'){
            state.activeMore = '/how-it-works';
        }
        if(pagePath === 'how-it-works'){
            state.activeMore = '/how-it-works';
        }
        if(pagePath === 'contact-us'){
            state.activeMore = '/contact-us';
        }
        if(pagePath === 'terms-of-use'){
            state.activeMore = '/terms-of-use';
        }
        if(pagePath === 'policy'){
            state.activeMore = '/policy';
        }
        if(pagePath === 'disclaimer'){
            state.activeMore = '/disclaimer';
        }
        if(pagePath === 'description'){
            state.activeMore = '/description';
        }
        if(pagePath === 'about-us'){
            state.activeMore = '/about-us';
        }
        if(pagePath === 'faqs'){
            state.activeMore = '/faqs';
        }

        return null;
    }

    // componentDidMount(){
        // check user is login
        // store.dispatch(loadUser());
    // }

    shouldComponentUpdate(nextProps, nextState){
        if(nextProps.loginModal !== nextState.sidebarOpen){
            return true;
        }else{
            return true;
        }
        // if(nextProps.loginModal === nextState.sidebarOpen){
            
        // }
 
    }

    // componentDidMount(){
    //     window.addEventListener('scroll', this.handleScroll);
    // }
    // componentWillUnmount(){
    //     window.removeEventListener('scroll', this.handleScroll);
    // }
    // handleScroll=()=>{
    //     if(window.scrollY > 75 ){
    //         this.setState({
    //             sticky:true
    //         })
    //     }else{
    //         this.setState({
    //             sticky:false
    //         })
    //     }
    // }

    // open sidebar
    openSidebar = () =>{
        this.setState({
            sidebarOpen:true
        })
    }
    
    // close sidebar
    closeOverlay = (e) =>{
        this.setState({
            sidebarOpen:false
        })
    }

    componentDidMount=()=>{
        const { hash_hmac2 } = this.props;
        // IntercomData2();
        hash_hmac2();
    }

    componentDidUpdate(prevProps){
        const { isAuth,  hash_hmac2 } = this.props;
        if(isAuth !== prevProps.isAuth){
            // IntercomData2();
            hash_hmac2();
        }
    }

    // IntercomData=()=>{
    //     const userId = sessionStorage.getItem('userId');
    //     axios.get(`https://redlinecoin.com/api/index.php/Users/checkUserDetailV2/${userId}`)
    //         .then(res=>{
    //             const user = res.data;
    //             // console.log(user);
    //             if(user.flag === 1){
    //                 sessionStorage.setItem('userName',user.data.fname);
    //                 sessionStorage.setItem('userEmail',user.data.email);
    //                 this.setState({
    //                     UserID:user.data.user_id,
    //                     userEmail:user.data.email,
    //                     userName:user.data.fname,
    //                 })
    //             }
    //         })
        // InterComApi('get',,null).then(res=>{
        //     const user = res.data;
        //     // console.log(user);
        //     if(user.flag === 1){
        //         sessionStorage.setItem('userName',user.data.fname);
        //         sessionStorage.setItem('userEmail',user.data.email);
        //         this.setState({
        //             UserID:user.data.user_id,
        //             userEmail:user.data.email,
        //             userName:user.data.fname,
        //         })
        //     }
        // })
    // }

    // hash_hmac = () =>{
    //     const userId = sessionStorage.getItem('userId');
    //     if(userId !== null){
    //         const hash = CryptoJs.HmacSHA256(userId, 'i1OfCKk2V2X76zE2EGUcAnF-OUQq1lXLGnHhT07P');
    //         const hmacKey = CryptoJs.enc.Hex.stringify(hash);
    //         this.setState({
    //             hmacKey:hmacKey
    //         })
    //     }else{
    //         this.setState({
    //             hmacKey:null
    //         })
    //     }
    // }

    // tabClick=()=>{
    //     this.setState({
    //         isLogin:!this.state.isLogin
    //     })
    // }

    LogoutClick = () =>{
        // const { logOut } = this.props;
        // logOut()
        sessionStorage.removeItem('token');
        window.location.reload(false);
        
    }

    closeLogin = () => {
        localStorage.removeItem("isPurchase");
        this.props.closeLoginModal()
    }

    render() {
        // console.log(this.props);
        const { sidebarOpen, sticky } = this.state;

        let loginClass = 'log_body';
        let signupClass = 'sign_body';
        let loginBtn = 'btn-dumy';
        let signupBtn = 'btn-dumy';
        let bgBack = 'tabs-nav';

        if(this.props.isLogin){
            loginClass += ' active';
            loginBtn +=' active';
        }else{
            signupClass += ' active';
            signupBtn +=' active';
            bgBack += ' active';
        }

        // const forgotEmailError = this.state.forgotError.forgotEmail;


        // let signupClass = 'sign_body';
        // if(this.state.signUpComp){
        //     signupClass += ' active';
        //     signupBtn +=' active';
        // }

        const { loginModal,loginOpen, isLogin, tabClicks, showModal, clickLogModal, 
                securityCodeModal, ToggleSecuritiM, forgotModal, ToggleForgotM } = this.props;

        const { hmacKey } = this.props;
        // const userId = sessionStorage.getItem('userId');
        // const userName = sessionStorage.getItem('userName');
        const token = sessionStorage.getItem('token');
        const tokenDecode = token && jwtDecode(token);
        // console.log(token);

        const user = {
            user_id: tokenDecode && tokenDecode.user_id,
            email: tokenDecode && tokenDecode.email,
            name: tokenDecode && tokenDecode.username,
            subscription: tokenDecode && tokenDecode.subscription ? (tokenDecode.subscription === 1 ? 'YES' : 'NO') : null,
            plan: tokenDecode && tokenDecode.subscription === 1 ? tokenDecode.subscriptionData.plan : null,
            start_date: tokenDecode && tokenDecode.subscription === 1 ? tokenDecode.subscriptionData.start_date : null,
            end_date: tokenDecode && tokenDecode.subscription === 1 ? tokenDecode.subscriptionData.end_date : null,
            payment: tokenDecode && tokenDecode.subscription === 1 ? tokenDecode.subscriptionData.payment : null,
            transaction_ids: tokenDecode && tokenDecode.subscription === 1 ? tokenDecode.subscriptionData.transaction_ids : null,
            manual: tokenDecode && tokenDecode.subscription === 1 ? tokenDecode.subscriptionData.manual : null,
            price: tokenDecode && tokenDecode.subscription === 1 ? tokenDecode.subscriptionData.price : null,
            currency: tokenDecode && tokenDecode.subscription === 1 ? tokenDecode.subscriptionData.currency : null,
            created_date: tokenDecode && tokenDecode.subscription === 1 ? tokenDecode.subscriptionData.created_date : null,
            status: tokenDecode && tokenDecode.subscription === 1 ? tokenDecode.subscriptionData.status : null,
        }

        return (
            <Fragment>
                <div className={sidebarOpen?"background_overlay active":"background_overlay"} onClick={this.closeOverlay}></div>
                <div className={sticky?"header sticky":"header"} id="Header">
                    <div className="container">
                        <div className="text-container">
                            <div className="navbar-header">
                                <Link to="/" className="navbar_logo"><img src={Logo} alt="RedLine Coin" /> RedLine Coin</Link>
                                <button type="button" className="toggle-btn" onClick={this.openSidebar}>
                                    <span className="icon-bar"></span>
                                    <span className="icon-bar"></span>
                                    <span className="icon-bar"></span>
                                </button>
                                {/* <button type="button" className="btn_login">Log in</button> */}
                            </div>
                            <ul className={sidebarOpen?"menuLink active":"menuLink"}>
                                <li className="logo_body">
                                    <div className="logo_block">
                                        <img src={Logo} alt="RedLine Coin" />
                                        {/* {(sessionStorage.getItem('token') === '1')?
                                        <button type="button" className="btn_login" onClick={this.logout}>Logout</button>
                                        : */}
                                        <button type="button" className="btn_login" onClick={()=>loginOpen()}>Log in</button>
                                        {/* } */}
                                    </div>
                                </li>
                                <li onClick={this.closeOverlay}> <NavLink exact activeClassName="active" to="/">Home</NavLink> </li>
                                <li onClick={this.closeOverlay}> <NavLink exact activeClassName="active" to="/buy-or-sell">Buy or Sell</NavLink> </li>
                                <li onClick={this.closeOverlay}> <NavLink exact activeClassName="active" to="/buy-crypto" >Buy Crypto</NavLink> </li>
                                <li onClick={this.closeOverlay}> <NavLink exact activeClassName="active" to="/news">Top News</NavLink> </li>
                                <li onClick={this.closeOverlay}> <NavLink exact activeClassName="active" to="/top-youtube">Top Youtube</NavLink> </li>
                                <li onClick={this.closeOverlay}> <NavLink exact activeClassName="active" to="/top-signal">Signals</NavLink> </li>
                                <li className="drop" onClick={this.closeOverlay}> <NavLink exact activeClassName="active" to={this.state.activeMore}>More...</NavLink> 
                                    <ul className="drop_menu">
                                        <li onClick={this.closeOverlay}><NavLink exact activeClassName="active" to="/how-it-works">How it Works</NavLink></li>
                                        <li onClick={this.closeOverlay}><NavLink exact activeClassName="active" to="/contact-us">Contact Us</NavLink></li>
                                        <li onClick={this.closeOverlay}><NavLink exact activeClassName="active" to="/terms-of-use">Terms of Service</NavLink></li>
                                        <li onClick={this.closeOverlay}><NavLink exact activeClassName="active" to="/policy">Privacy Policy</NavLink></li>
                                        <li onClick={this.closeOverlay}><NavLink exact activeClassName="active" to="/disclaimer">Disclaimer</NavLink></li>
                                        <li onClick={this.closeOverlay}><NavLink exact activeClassName="active" to="/description">Description</NavLink></li>
                                        <li onClick={this.closeOverlay}><NavLink exact activeClassName="active" to="/about-us">About us</NavLink></li>
                                        <li onClick={this.closeOverlay}><NavLink exact activeClassName="active" to="/faqs">FAQs</NavLink></li>
                                    </ul>
                                </li>
                            </ul>
                            <ul className="btn_blocks2">
                                {token?
                                    <li><button type="button" className="btn_login" onClick={()=>clickLogModal()}>Log Out</button></li>
                                    :
                                    <li><button type="button" className="btn_login" onClick={()=>loginOpen()}>Log In</button></li>
                                }
                                {/* <li><button type="button" className="btn_login" onClick={()=>loginOpen()}>Log In</button></li> */}
                                
                            </ul>
                        </div>
                    </div>
                </div>

                {/* login modal */}
                <Modal show={loginModal} onHide={this.closeLogin} className="login_body" aria-labelledby="contained-modal-title-vcenter" centered>

                    <Modal.Body>
                        <div className="login_blocks">
                            <ul className={bgBack}>
                                <li className={loginBtn} onClick={()=>tabClicks()}>Login</li>
                                <li className={signupBtn} onClick={()=>tabClicks()}>Sign up</li>
                                <span className='bg-nav'></span>
                            </ul>
                            <div className="content-tabs">
                                {(isLogin)?
                                    <div id="login" className={loginClass}>
                                        <Login />
                                    </div>
                                    :
                                    <div id="signup" className={signupClass} >
                                        <Register />
                                    </div>
                                }
                                
                            </div>
                        </div>
                    </Modal.Body>
                    

                </Modal>
                {/* over login modal */}

                {/* Logout modal */}
                <Modal show={showModal} onHide={()=>clickLogModal()} className="logout_modal" aria-labelledby="contained-modal-title-vcenter" centered>

                    <Modal.Body>
                        <div className="logout_block2">
                            <p>Are you sure you want to Logout?</p>
                            <button type="button" className="btn-yes" onClick={()=>this.LogoutClick()}>Yes</button>
                            <button type="button" className="btn-no" onClick={()=>clickLogModal()}>No</button>
                        </div>
                    </Modal.Body>
                    

                </Modal>
                {/* over Logout modal */}

                {/* Forgot password modal */}
                <Modal show={forgotModal} onHide={()=>ToggleForgotM()} className="fp_modal" aria-labelledby="contained-modal-title-vcenter" centered>

                    <Modal.Body className="reset-modal-body">
                        <h3 className="logOut_text_ti">Reset Password</h3>
                        <h5 className="ForgotPwd_text_ti">Type your email to reset your password</h5>
                        <ForgotModal />
                    </Modal.Body>
                </Modal>
                
                {/* over Forgot password modal */}

                {/* intercom  a8szy985*/}

                {/* security code modal */}
                <Modal show={securityCodeModal} onHide={()=>ToggleSecuritiM()} className="login_body" aria-labelledby="contained-modal-title-vcenter" centered>
    
                    <Modal.Body>
                        <div className="login_blocks">
                            <div className="hadding_suc">
                                <h5>Enter Security Code</h5>
                                <p>Enter the 6 digit code we sent you via email and your new password</p>
                            </div>
                            <SecurityModal />
                        </div>
                    </Modal.Body>
                    

                </Modal>
                {/* over security code modal */}
                                    {/* subscription_start={"1584028614"} subscription_end={"1615528614"} */}
                {/* <Intercom appID="a8szy985" user_hash={hmacKey} user_id={userId} name={userName} /> */}
                {token?
                    <Intercom appID="a8szy985" {...user} user_hash={hmacKey} />
                    :
                    <Intercom appID="a8szy985" />
                }
                
            </Fragment>
        )
    }
}

const mapStateToProps = state =>({
    loginModal:state.authModal.loginModal,
    isAuth:state.auth.isAuth,
    token:state.auth.token,
    isLogin:state.authModal.isLogin,
    showModal:state.authModal.logOutModal,
    securityCodeModal:state.authModal.securityCodeModal,
    forgotModal:state.authModal.forgotModal,
    hmacKey:state.auth.hmacKey
})

const mapDispatchToProps = dispatch =>({
    loginOpen:()=>dispatch({type:OPEN_LOGIN}),
    closeLoginModal:()=>dispatch({type:CLOSE_LOGINM}),
    logOut:()=>dispatch(logout()),
    tabClicks:()=>dispatch({type:LOGIN_TABS}),
    clickLogModal:()=>dispatch({type:TOGGLE_LOGOUTMODAL}),
    ToggleSecuritiM:()=>dispatch({type:TOGGEL_SECURITYMODAL}),
    ToggleForgotM:()=>dispatch({type:TOGGLE_FORGOTMODAL}),
    IntercomData2:()=>dispatch(IntercomData()),
    hash_hmac2:()=>dispatch(hash_hmac())
})


export default withRouter(connect(mapStateToProps,mapDispatchToProps)(Header));