// import axios from 'axios';
import { apiHelper } from '../../helper/api_helper'
import { jwtDecode } from '../../helper/jwt_helper';
import * as ACTION_TYPES from './types';
import { returnWebsiteError, returnWCoinsError } from './buycryptoError';

// let api_url = "https://redlinecoin.com/api/index.php/Websites/";


export const loadWebsite = (data) => (dispatch) => {

    const { sortWebValue, sortFeatureValue, methodPayment, coinNameArray, searchValue, countryGet, page, isFilter } = data;
    // console.log(page);
    if(page > 1){
        dispatch({ type: ACTION_TYPES.LOADING_SMALL_WEBSITES });
    }else{
        dispatch({ type: ACTION_TYPES.LOADING_WEBSITES });
    }

    const token = sessionStorage.getItem('token');
    const headers = { 'Authorization': token }


//   console.log(data)

    // let formData = new FormData();
    // formData.append('sorting', sortWebValue)
    // formData.append('feature', sortFeatureValue)
    // formData.append('payment', methodPayment)
    // formData.append('coin', coinNameArray)
    // formData.append('website_name', searchValue)
    // formData.append('location', countryGet)

    const url = `website?payment=${methodPayment}&coin=${coinNameArray}&location=${countryGet}&feature=${sortFeatureValue}&sorting=${sortWebValue}&page_no=${page}&search=${searchValue}`
    return apiHelper(url, 'GET', null, headers)
    
        .then(response => {
            const res = response.data;
            if (res.status) {
                const decode = jwtDecode(res.data);
                // console.log(decode);
                dispatch({
                    type: ACTION_TYPES.LOADED_WEBSITES_SUCCESS,
                    payload: {isFilter:isFilter, data:decode}
                });
            } else {
                const err = res.data;
                dispatch(returnWebsiteError(err.msg, 401, 'LOADED_WEBSITE_FAIL'));
                dispatch({
                    type: ACTION_TYPES.LOADED_WEBSITE_FAIL
                })
            }
        })
        .catch(err => {
            dispatch(returnWebsiteError(err.msg, 401, 'LOADED_WEBSITE_FAIL'));
            dispatch({
                type: ACTION_TYPES.LOADED_WEBSITE_FAIL
            })
        });
}

export const coinsList = () => dispatch => {

    const headers = { 'Authorization': sessionStorage.getItem('token') }

    apiHelper('coins-all', 'GET', null, headers)
    // axios.get('coin-all', config)
        .then(response => {
            const res = response.data;
            if (res.status) {
                const decode = jwtDecode(res.data);
                // console.log(decode);
                dispatch({
                    type: ACTION_TYPES.LOADED_COINS_SUCCESS,
                    payload: decode.map(info=>({id:info.id, label:info.symbol + ' · ' + info.name, value:info.symbol}))
                });
            } else {
                const err = res.data;
                dispatch(returnWCoinsError(err.msg, 401, 'LOADED_COINS_FAIL'));
                dispatch({ type: ACTION_TYPES.LOADED_COINS_FAIL });
            }
        })
        .catch(err => {
            dispatch(returnWCoinsError(err.msg, 401, 'LOADED_COINS_FAIL'));
            dispatch({ type: ACTION_TYPES.LOADED_COINS_FAIL });
        })
}