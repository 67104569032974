import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import thankUImg from '../../assets/image/successImg.svg';
import errorImg from '../../assets/image/errorImg.svg';

class PayMessageModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isSuccessModal: this.props.modalData.payModal && this.props.modalData.payResponse,
            isErrorModal: this.props.modalData.payModal && !this.props.modalData.payResponse
        }
    }

    openSuccess = () => {
        // window.location.href = this.props.modalData.url

    }

    handleClose = () => {
        this.setState({
            isSuccessModal: false,
            isErrorModal: false
        })
        window.location.href = this.props.modalData.url
    };

    render() {
        return (
            <div>
                <Modal show={this.state.isSuccessModal} onHide={this.handleClose} centered className="messages-modal credit-card">
                    <Modal.Body>
                        <img src={thankUImg} alt="Success" />
                        <h3>Thank you for subscribing!</h3>
                        <p className="">You have successfully subscribed to our list. We will let you know when we launch.</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <button type="submit" onClick={this.handleClose} className="btn btn-confirm">Ok</button>
                    </Modal.Footer>
                </Modal>

                <Modal show={this.state.isErrorModal} onHide={this.handleClose} centered className="messages-modal credit-card">
                    <Modal.Body>
                        <img src={errorImg} alt="Success" />
                        <h3>Oops! Something went wrong!</h3>
                        <p className="">We encountered an error while trying to add you to our list. Will you please try one more time? Pretty please?</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <button type="submit" onClick={this.handleClose} className="btn btn-confirm">Try again</button>
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }
}
export default PayMessageModal;