import * as ACTION_TYPES from '../actions/types';
const initialState = {
    websiteArray:[],
    coinsArray:[],
    isLoader:false,
    isSLoader:true,
    isFilter:false
}

const buycrypto = (state = initialState, action) =>{
    switch(action.type){
        case ACTION_TYPES.LOADING_WEBSITES:
            return{
                ...state,
                isLoader:true
            }
        case ACTION_TYPES.LOADING_SMALL_WEBSITES:
            return{
                ...state,
                isSLoader:true
            }
        case ACTION_TYPES.LOADED_WEBSITES_SUCCESS:
            // console.log(action.payload.isFilter);
            return{
                ...state,
                isLoader:false,
                isSLoader:action.payload.data.length > 0?true:false,
                websiteArray:action.payload.isFilter?action.payload.data:[...state.websiteArray, ...action.payload.data],
                isFilter:action.payload.isFilter
            }
        case ACTION_TYPES.LOADED_WEBSITE_FAIL:
            return{
                ...state,
                isSLoader:false,
                isLoader:false
            }
        case ACTION_TYPES.LOADED_COINS_SUCCESS:
            return{
                ...state,
                coinsArray:action.payload
            }
        default:
            return state;
    }
}
export default buycrypto;