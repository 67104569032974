import React, { Component } from 'react';
import { connect } from 'react-redux';
import { TOGGEL_SECURITYMODAL, SECURITY_FAIL, RESEND_SEND, SECURITY_SEND } from '../redux/actions/types';
import { ResendCode, submitSecurityCode } from '../redux/actions/authActions';
import UserLoader from '../assets/image/user-loader.gif';

class SecurityModal extends Component {
    constructor(props){
        super(props);
        this.state={
            securityCode:'',
            newPassword:'',
            securityError:{
                securityCode:'',
                newPassword:''
            },
            securityCodeValid:false,
            newPasswordValid:false,
            securityErrorMessage:null
        }

        this.getSecurityCode = this.getSecurityCode.bind(this);
        this.resendCode = this.resendCode.bind(this);
        this.validation = this.validation.bind(this);
        this.sendSecurityCode = this.sendSecurityCode.bind(this);
    }

    resendCode=()=>{
        const { email, ResendCodeAPi, dataSend } = this.props;
        // console.log(email);
        dataSend();
        ResendCodeAPi(email);
    }

    getSecurityCode=(e)=>{
        const { name, value } = e.target;

        this.setState({ 
            [name]:value 
        },()=>this.validation(name,value));
    }

    validation=(name, value)=>{
        let error = this.state.securityError;
        let securityCodeValid = this.state.securityCodeValid;
        let newPasswordValid = this.state.newPasswordValid;
        
        switch(name) {
            case 'securityCode':
                securityCodeValid = value.length > 0;
                error.securityCode = securityCodeValid ? '' : 'Security Code Must Required.';
                break;

            case 'newPassword':
                newPasswordValid = value.length >= 6;
                error.newPassword = newPasswordValid ? '': 'Password must be 6 characters long!';
                break;

            default:
                break;
        }
        this.setState({
                securityError: error,
                securityCodeValid: securityCodeValid,
                newPasswordValid: newPasswordValid,
            
        });
    }

    componentDidUpdate(prevProps){
        const { error, isAuth } = this.props;
        // console.log(error);
        if(error !== prevProps.error){
            // check for login error
            if(error.id === 'SECURITY_FAIL'){
                this.setState({securityErrorMessage:error.securityMSG});

            }else{
                this.setState({securityErrorMessage:null})
            }
        }

        // if auth, close modal
        if(isAuth){
            this.props.ToggleSecuritiM();
        }
    }

    sendSecurityCode = (e) =>{  
        e.preventDefault();
        const { email, submitPassword,securityFail, dataSend2 } = this.props;
        const { securityCode, newPassword } = this.state;
        dataSend2();
        if( securityCode !== '' || newPassword !== ''){
            submitPassword(email, securityCode, newPassword);
        }else{
            securityFail();
            this.setState({
                securityErrorMessage:'All Field is Required'
            });
        }
        
        
    }

    render() {

        const { securityErrorMessage, securityCode, newPassword, securityError, newPasswordValid, securityCodeValid } = this.state;

        const disabled = !securityCodeValid || !newPasswordValid;

        const { ToggleSecuritiM, resendLoading, securityLoading } = this.props;

        return (
            <form method="POST" onSubmit={this.sendSecurityCode}>
                {/* {(this.state.isSuccess)?(this.state.resndCodeMessage !== '')? <p className="text-green2">{this.state.resndCodeMessage}</p>:null:null} */}
                {(securityError.securityCode !== '')? <p className="errorContact">{securityError.securityCode}</p>:null}
                <div className="form-group mb-0">
                    <input type="text" className="form-controllog border-radt" placeholder="Security Code" name="securityCode" onChange={this.getSecurityCode} value={securityCode} autoComplete="off" />
                </div>
                <div className="form-group mb-0">
                    <input type="password" className="form-controllog border-radb" placeholder="New Password" name="newPassword" onChange={this.getSecurityCode} value={newPassword} autoComplete="off" />
                </div>
                {(securityError.newPassword !== '')? <p className="errorContact">{securityError.newPassword}</p>:null}
                {(securityErrorMessage !== null)? <p className="errorContact">{securityErrorMessage}</p>:null}
                                
                <p className="text-green2">Check your email and enter OTP</p>
                <p className="resend-code">Haven't received a code?<button type="button" onClick={this.resendCode}>Resend code</button></p>
                {(resendLoading)? 
                    <div className="user_loader user_loader3">
                        <img src={UserLoader} alt="loader" />
                    </div>
                    :
                    null
                }
                <div className="btn-block3">
                    <button type="submit" className="btn-restpas" disabled={disabled || securityLoading}>{securityLoading?<img className="sendLoader" src={UserLoader} alt="loader" />:"Reset Password"}</button>
                    <button type="button" className="btn-cancel3" onClick={()=>ToggleSecuritiM()}>Cancel</button>
                </div>
            </form>
        )
    }
}

const mapStateToProps = state =>{
    return{
        isAuth:state.auth.isAuth,
        email:state.auth.forgetEmail,
        error:state.error,
        securityLoading:state.auth.securityLoading,
        resendLoading:state.auth.resendLoading
    }
}

const mapDispatchToProps = dispatch =>({
    dataSend:()=>dispatch({type:RESEND_SEND}),
    dataSend2:()=>dispatch({type:SECURITY_SEND}),
    ToggleSecuritiM:()=>dispatch({type:TOGGEL_SECURITYMODAL}),
    ResendCodeAPi:(email)=>dispatch(ResendCode(email)),
    securityFail:()=>dispatch({type:SECURITY_FAIL}),
    submitPassword:(email, otp, password)=>dispatch(submitSecurityCode(email, otp, password))
})

export default connect(mapStateToProps, mapDispatchToProps)(SecurityModal);
