import { jwtDecode } from '../../helper/jwt_helper';
import { apiHelper } from '../../helper/api_helper'
import * as ACTION_TYPES from './types';
import { returnOSignalError, returnCSignalError, returnCalcuError } from './signalError';

export const loadOPenSignal = () => dispatch => {

    dispatch({ type: ACTION_TYPES.LOADING_OPEN_SIGNAL });

    //const api_url = `${url}getAllOpenSignals`;
    return apiHelper(`signal/open`, 'GET', null, null)
        // axios.get(api_url, config)
        .then(response => {
            const res = response.data;
            if (res.status) {
                const decode = jwtDecode(res.data)
                dispatch({
                    type: ACTION_TYPES.OPEN_SIGNAL_SUCCESS,
                    // payload: { data: res.data, subs: res.subscriptions }
                    payload: decode
                });

            } else {
                const err = res;
                dispatch(returnOSignalError(err.msg, 401, 'OPEN_SIGNAL_FAIL'));
                dispatch({ type: ACTION_TYPES.OPEN_SIGNAL_FAIL })
            }
        })
        .catch(err => {
            dispatch(returnOSignalError(err.msg, 401, 'OPEN_SIGNAL_FAIL'));
            dispatch({ type: ACTION_TYPES.OPEN_SIGNAL_FAIL });
        })
}

export const loadCloseSignal = (page) => dispatch => {

    dispatch({ type: ACTION_TYPES.LOADING_CLOSE_SIGNAL });

    // const api_url = `${url}getAllCloseignals`;

    // axios.get(api_url, config)
    return apiHelper(`signal/close/${page}`, 'GET', null, null)
        .then(response => {
            const res = response.data
            if (res.status) {
                const decode = jwtDecode(res.data)
            
                    dispatch({
                        type: ACTION_TYPES.CLOSE_SIGNAL_SUCCESS,
                        payload: decode
                    });
            } else {
                const err = res;
                dispatch(returnCSignalError(err.msg, 401, 'CLOSE_SIGNAL_FAIL'));
                dispatch({ type: ACTION_TYPES.CLOSE_SIGNAL_FAIL })
            }
        })
        .catch(err => {
            dispatch(returnCSignalError(err.msg, 401, 'CLOSE_SIGNAL_FAIL'));
            dispatch({ type: ACTION_TYPES.CLOSE_SIGNAL_FAIL });
        })
}

export const loadCalcultor = (type) => dispatch => {
    // console.log(type);
    const headers = { 'Authorization': sessionStorage.getItem('token') }

    //const api_url = `${url}threadsCalculation/${type}`;
    let month = 0
    let week = 0
    switch (type) {
        case '1w':
            week = 1
            month = 0
            break;
        case '1m':
            week = 0
            month = 1
            break;
        case '3m':
            week = 0
            month = 3
            break;
        case '6m':
            week = 0
            month = 6
            break;
        case '1y':
            week = 0
            month = 12
            break;
        default:
            break;
    }

    return apiHelper(`signal/threads?month=${month}&week=${week}`, 'GET', null, headers)
        // axios.get(api_url, headers)
        .then(response => {
            const res = response.data;
            
            if (res.status) {
                const decode = jwtDecode(res.data)
                dispatch({
                    type: ACTION_TYPES.CALCULATION_SUCCESS,
                    payload: decode
                });
            } else {
                const err = res.message;
                dispatch(returnCalcuError(err.msg, 401, 'CALCULATION_FAIL'));
                dispatch({ type: ACTION_TYPES.CALCULATION_FAIL })
            }
        })
        .catch(err => {
            dispatch(returnCalcuError(err.msg, 401, 'CALCULATION_FAIL'));
            dispatch({ type: ACTION_TYPES.CALCULATION_FAIL });
        })

}