import * as ACTION_TYPES from '../actions/types';

const initialState = {
    websiteMSG:null,
    coinsMSG:null,
    status:null,
    id:null,
}

const buyCryptoError = (state = initialState, action) =>{
    switch(action.type){
        case ACTION_TYPES.WEBSITE_GET_ERROR:
            return{
                ...state,
                websiteMSG:action.payload.msg,
                status:action.payload.status,
                id:action.payload.id
            }
        case ACTION_TYPES.WEBSITE_CLEAR_ERROR:
            return{
                ...state,
                websiteMSG:null,
                status:null,
                id:null
            }
        case ACTION_TYPES.COINS_GET_ERROR:
            return{
                ...state,
                coinsMSG:action.payload.msg,
                status:action.payload.status,
                id:action.payload.id
            }
        case ACTION_TYPES.COINS_CLEAR_ERROR:
            return{
                ...state,
                coinsMSG:null,
                status:null,
                id:null
            }
        default:
            return state;
    }
}

export default buyCryptoError;