import React, { Component } from 'react';
import './AppDM.css';
import Modal  from 'react-bootstrap/Modal';
import BitStatus from "../../assets/image/bit_status.svg";
import BitStatus2 from '../../assets/image/bit_status2.svg';
import BitAlert from '../../assets/image/bit_alert.svg';
import { connect } from 'react-redux';
import { TOGGLE_APPDM } from '../../redux/actions/types';

class AppDM extends Component{
    constructor(props){
        super(props);

        this.openAppStore = this.openAppStore.bind(this);
        this.openPlayStore = this.openPlayStore.bind(this);
    }

    openAppStore=()=>{
        window.open('https://itunes.apple.com/us/app/redline-coin-crypto-signals/id1326274166', '_blank');
    }
    openPlayStore=()=>{
        window.open('https://play.google.com/store/apps/details?id=com.redline.coin', '_blank');
    }

    render(){
        
        const { showModal, toggleAPPModal } = this.props;
        
        return(
            <React.Fragment>
                {/* download App modal */}
                <Modal show={showModal} onHide={()=>toggleAPPModal()} className="appModal" size="lg" aria-labelledby="contained-modal-title-vcenter" centered>

                    <Modal.Body>
                        <div className="block-app">
                            <button className="btn-closed" onClick={()=>toggleAPPModal()}>×</button>
                            <h6>Download our app to view everything</h6>
                            <div className="btn_bloc">
                                <button onClick={this.openAppStore} className="app-storeBtn app-storeBtn3"> 
                                    <span className="apple-iconBig"></span> 
                                    <p className="font-12">Download on the<br /><span>App Store</span></p>
                                </button>
                                <button onClick={this.openPlayStore} className="play-storeBtn play-storeBtn3"> 
                                    <span className="play-iconBig"></span> <p className="font-12">GET IT ON<br /><span>Google Play</span></p>
                                </button>
                            </div>
                            <ul className="imgs_bloc">
                                <li><img src={BitStatus} alt="Real-Time Status" /></li>
                                <li><img src={BitStatus2} alt="Coin Status" /></li>
                                <li><img src={BitAlert} alt="Coin Alerts" /></li>
                            </ul>
                        </div>
                    </Modal.Body>

                </Modal>
                {/* over download App modal */}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state)=>({
    showModal:state.appDM.showModal
})

const mapDispatchToProps = (dispatch)=>({
    toggleAPPModal:()=>dispatch({type:TOGGLE_APPDM})
})

export default connect(mapStateToProps, mapDispatchToProps)(AppDM);