import * as ACTION_TYPES from '../actions/types';

const initialState = {
    opSignalMSG:null,
    coSignalMSG:null,
    calMSG:null,
    status:null,
    id:null,
}

const topSignalError = (state = initialState, action) =>{
    switch(action.type){
        case ACTION_TYPES.OSIGNAL_GET_ERROR:
            return{
                ...state,
                opSignalMSG:action.payload.msg,
                status:action.payload.status,
                id:action.payload.id
            }
        case ACTION_TYPES.OSIGNAL_CLEAR_ERROR:
            return{
                ...state,
                opSignalMSG:null,
                status:null,
                id:null
            }
        case ACTION_TYPES.CSIGNAL_GET_ERROR:
            return{
                ...state,
                coSignalMSG:action.payload.msg,
                status:action.payload.status,
                id:action.payload.id
            }
        case ACTION_TYPES.CSIGNAL_CLEAR_ERROR:
            return{
                ...state,
                coSignalMSG:null,
                status:null,
                id:null
            }
        case ACTION_TYPES.CALCULATION_GET_ERROR:
            return{
                ...state,
                calMSG:action.payload.msg,
                status:action.payload.status,
                id:action.payload.id
            }
        case ACTION_TYPES.CALCULATION_CLEAR_ERROR:
            return{
                ...state,
                calMSG:null,
                status:null,
                id:null
            }
        default:
            return state;
    }
}

export default topSignalError;