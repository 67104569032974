import * as ACTION_TYPES from './types';

// get errors
export const returnAllCoinsError=(msg, status, id)=>{
    return{
        type:ACTION_TYPES.ALLCOINS_GET_ERROR,
        payload: { msg, status, id }
    }
}

export const returnActiveCoinsError = (msg, status, id)=>{
    return{
        type:ACTION_TYPES.ACTIVECOINS_GET_ERROR,
        payload:{ msg, status, id }
    }
}

export const returnCryptoError = (msg, status, id )=>{
    return {
        type:ACTION_TYPES.CRYPTO_GET_ERROR,
        payload:{ msg, status, id }
    }
}

// clear error
export const clearAllCoinsError = () =>{
    return{
        type:ACTION_TYPES.ALLCOINS_CLEAR_ERROR
    }
}

export const clearActiveCoinsError = () =>{
    return{
        type:ACTION_TYPES.ACTIVECOINS_CLEAR_ERROR
    }
}

export const clearCryptoError = () =>{
    return {
        type:ACTION_TYPES.CRYPTO_CLEAR_ERROR
    }
}