import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import './BuyCrypto.css';
import { loadWebsite, coinsList } from '../../redux/actions/buycryptoActions';
import { jwtDecode } from '../../helper/jwt_helper';
import { apiHelper } from '../../helper/api_helper'
import LoaderComponent from '../LoaderComponent';
import RecordNotFound from '../RecordNotFound';
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';
import ScrollLoader from '../ScrollLoader/index';
import InfiniteScroll from 'react-infinite-scroll-component';
import Select from 'react-select';
import Rating_0 from '../../assets/image/rating-0.svg';
import Rating_1 from '../../assets/image/rating-1.svg';
import Rating_2 from '../../assets/image/rating-2.svg';
import Rating_3 from '../../assets/image/rating-3.svg';
import Rating_4 from '../../assets/image/rating-4.svg';
import Rating_5 from '../../assets/image/rating-5.svg';
import { loadMeta } from '../../redux/actions/metaActions';
import { Helmet } from "react-helmet";

class BuyCrypto extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoader: false,
            pageNo:1,
            AllWebsite: [],
            coinList: [],
            countryListArr: [],
            paymentMethodArr: [],
            messageApi: '',
            pageTitle: "",
            searchValue: '',
            sortwebsite: "",
            sortWebValue: "",
            sortFeature: "",
            sortFeatureValue: "",
            methodPayment: [],
            coinNameArray: [],
            coinid: [],
            coinSearchArray: [],
            countryGet: [],
            countryID:[],
            isFilterDrop: false,
            isOverlay: false,
            isFeatureDrop: false,

        }
        this.openFilterDrop = this.openFilterDrop.bind(this);
        this.closeDrop = this.closeDrop.bind(this);
        this.websiteSort = this.websiteSort.bind(this);
        this.filterSearch = this.filterSearch.bind(this);
        this.getSortFeature = this.getSortFeature.bind(this);
        this.sortDrop = this.sortDrop.bind(this);
        this.getSortcoins = this.getSortcoins.bind(this);
        this.getCoinName = this.getCoinName.bind(this);
        this.getCountry = this.getCountry.bind(this);
        this.getSortcountry = this.getSortcountry.bind(this);
        this.getFeature = this.getFeature.bind(this);
        this.getPaymentM = this.getPaymentM.bind(this);
        this.getSearchOption = this.getSearchOption.bind(this);
    }

    UNSAFE_componentWillMount() {
        const windowWidth = window.innerWidth;
        if (windowWidth >= 1023) {
            this.setState({
                pageTitle: "Where to buy crypto?"
            })
        } else {
            this.setState({
                pageTitle: "Buy Crypto?"
            })
        }

        this.setState({
            sortwebsite: "Popularity",
            sortWebValue: "popular",
            sortFeature: 'Feature',
            sortCoin: 'Coin',
            sortCountry: 'Country',
        });
    }

    componentDidMount() {
        const { sortWebValue, sortFeatureValue, methodPayment, coinid, searchValue, countryID, pageNo } = this.state;

        const { loadWebsite, loadCoins, loadMeta } = this.props;

        const data = {
            sortWebValue,
            sortFeatureValue,
            methodPayment,
            coinNameArray:coinid,
            searchValue,
            countryGet:countryID,
            page:pageNo,
            isFilter:false
        }
        this.loadCountry();
        this.loadPaymentMethod();
        loadMeta('buy-crypto');
        loadWebsite(data);
        loadCoins();
    }

    loadCountry = async () => {
        try {
            this.setState({
                isLoader: true
            });

            const response = await apiHelper('website/location', 'GET', null, null);
            const res = response.data;

            if (res.status) {
                const decode = jwtDecode(res.data);
                // console.log(decode)
                this.setState({
                    isLoader: false,
                    countryListArr: decode.map(info=>({id:info.id, value:info.location, label:info.location})),
                    messageApi: ''
                });
            } else {
                this.setState({
                    isLoader: false,
                    countryListArr: [],
                    messageApi: res.message
                });
            }
            // console.log(this.state.countryListArr)

        } catch (err) {
            console.log(err);
            this.setState({
                messageApi: err.message,
                countryListArr:[]
            })
        }

        // const countryList = [
        //     { value: "AUS", label: "AUS" }, { value: "BRA", label: "BRA" }, { value: "BRB", label: "BRB" }, { value: "CA", label: "CA" }, { value: "GER", label: "GER" }, { value: "HKG", label: "HKG" },
        //     { value: "IND", label: "IND" }, { value: "ITA", label: "ITA" }, { value: "JPN", label: "JPN" }, { value: "KSA", label: "KSA" }, { value: "KOR", label: "KOR" }, { value: "MRT", label: "MRT" },
        //     { value: "MLT", label: "MLT" }, { value: "RUS", label: "RUS" }, { value: "SWE", label: "SWE" }, { value: "TUR", label: "TUR" }, { value: "UK", label: "UK" }, { value: "USA", label: "USA" },
        //     { value: "VIE", label: "VIE" }, { value: "YEM", label: "YEM" },
        // ]

        // const sort_country = countryList.map((data, i) =>
        //     <li key={i} onClick={() => this.getSortcountry(data)} className={(this.state.sortCountry === data.label) ? 'active' : ''}>{data.label}</li>
        // );
    }

    loadPaymentMethod = async () => {

        try {
            this.setState({
                isLoader: true
            });

            const response = await apiHelper('website/payment-method', 'GET', null, null);
            const res = response.data;

            if (res.status) {
                const decode = jwtDecode(res.data);
                // console.log(decode)
                this.setState({
                    isLoader: false,
                    paymentMethodArr: decode,
                    messageApi: ''
                });
            } else {
                this.setState({
                    isLoader: false,
                    paymentMethodArr: [],
                    messageApi: res.message
                });
            }
            // console.log(this.state.countryListArr)

        } catch (err) {
            console.log(err);
            this.setState({
                messageApi: err.message
            })
        }
    }

    nextWebList123 = () => {
        console.log('working...')

        const { loadWebsite } = this.props;

        const { sortWebValue, sortFeatureValue, methodPayment, coinid, searchValue, countryID, pageNo } = this.state;
        // console.log(countryID);
        if (this.props.isSLoader) {
            const inc = pageNo + 1;
            this.setState({
                pageNo: inc,                
            });

            
            const data = {
                sortWebValue,
                sortFeatureValue,
                methodPayment,
                coinNameArray: coinid,
                searchValue,
                countryGet:countryID,
                page:inc,
                isFilter:false
            }
            
            loadWebsite(data)        
        } 
        // else {
        //     this.setState({
        //         isSLoader: true
        //     })
        // }
    }


    filterSearch = (e) => {
        const searchValue = e.target.value;
        this.setState({
            searchValue: searchValue,
            pageNo:1
        });

        const { sortWebValue, sortFeatureValue, methodPayment, coinid, countryID } = this.state;

        const data = {
            sortWebValue,
            sortFeatureValue,
            methodPayment,
            coinNameArray: coinid,
            searchValue,
            countryGet:countryID,
            page:1,
            isFilter:true
        }

        this.props.loadWebsite(data);
    }

    openFilterDrop = () => {
        this.setState({
            isFilterDrop: !this.state.isFilterDrop,
            isOverlay: !this.state.isOverlay
        })
        // document.getElementById('filterBy').classList.toggle('active');
        // document.getElementById('OverlayId2').classList.toggle('active');
    }

    sortDrop = (e) => {
        e.target.classList.toggle('active');
        this.setState({
            isOverlay: !this.state.isOverlay
        })
    }

    closeDrop = () => {
        this.setState({
            isFilterDrop: false,
            isOverlay: false,
        })
        // document.getElementById('filterBy').classList.remove('active');
        // document.getElementById('OverlayId2').classList.remove('active');
        document.getElementById('sortFeature').classList.remove('active');
        document.getElementById('sortCountry').classList.remove('active');
        document.getElementById('sortCoins').classList.remove('active');
    }

    websiteSort = (e, val) => {
        const sortWebValue = val.value;
        this.setState({
            sortwebsite: val.name,
            sortWebValue: sortWebValue,
            pageNo:1
            // isLoader:true,
        });



        const { sortFeatureValue, methodPayment, coinid, searchValue, countryID } = this.state;

        const data = {
            sortWebValue,
            sortFeatureValue,
            methodPayment,
            coinNameArray: coinid,
            searchValue,
            countryGet:countryID,
            page:1,
            isFilter:true
        }

        this.props.loadWebsite(data);

        const drop_menuLi = document.getElementsByClassName('list');
        for (let n = 0; n < drop_menuLi.length; n++) {
            document.getElementsByClassName('list')[n].classList.remove('active');
        }
        e.target.classList.add('active');
    }

    getSortFeature = (value) => {
        document.getElementById('sortFeature').classList.remove('active');
        this.setState({
            isOverlay: false,
        })

        if (value !== 'Feature') {
            const sortFeatureValue = value.value;
            this.setState({
                sortFeature: value.label,
                sortFeatureValue: sortFeatureValue,
                pageNo:1,
            });

            const { sortWebValue, methodPayment, coinid, searchValue, countryID } = this.state;

            const data = { sortWebValue, sortFeatureValue, methodPayment, coinNameArray: coinid, searchValue, countryGet:countryID, page:1, isFilter:true }

            this.props.loadWebsite(data);

        } else {
            const sortFeatureValue = '';
            this.setState({
                sortFeature: 'Feature',
                sortFeatureValue: '',
                pageNo:1
            });
            const { sortWebValue, methodPayment, coinid, searchValue, countryID } = this.state;

            const data = { sortWebValue, sortFeatureValue, methodPayment, coinNameArray: coinid, searchValue, countryGet:countryID, page:1, isFilter:true }

            this.props.loadWebsite(data);
        }

    }

    getSortcoins = (e, value) => {
        document.getElementById('sortCoins').classList.remove('active');
        document.getElementById('OverlayId2').classList.remove('active');

        if (value !== 'Coin') {
            const coinNameArray = value.id;
            this.setState({
                sortCoin: value.value,
                coinNameArray: coinNameArray,
                pageNo:1
            });
            const { sortWebValue, sortFeatureValue, methodPayment, searchValue, countryID } = this.state;

            const data = { sortWebValue, sortFeatureValue, methodPayment, coinNameArray, searchValue, countryGet:countryID, page:1, isFilter:true }

            this.props.loadWebsite(data);
        } else {
            const coinNameArray = [];
            this.setState({
                sortCoin: 'Coin',
                coinNameArray: [],
                pageNo:1
            });

            const { sortWebValue, sortFeatureValue, methodPayment, searchValue, countryID } = this.state;

            const data = { sortWebValue, sortFeatureValue, methodPayment, coinNameArray, searchValue, countryGet:countryID, page:1, isFilter:true }

            this.props.loadWebsite(data);
        }
    }

    getCoinName = (data) => {
        const nameArr = this.state.coinNameArray;
        let newCoinId = this.state.coinid
        nameArr.push(data.id);

        for (let h = 0; h < nameArr.length; h++) {
            let curItem = nameArr[h];
            let foundCount = 0;
            for (let i = 0; i < nameArr.length; i++) {
                if (nameArr[i] === nameArr[h]) { foundCount++ };
            }
            if (foundCount > 1) {
                for (let j = 0; j < nameArr.length; j++) {
                    if (nameArr[j] === curItem) {
                        nameArr.splice(j, 1); j--;
                        newCoinId.splice(j, 1); j--;                        
                    }
                }
            }
        }

        if (nameArr.length > 0) {
            // api
            this.setState({
                pageNo:1,
                coinNameArray: nameArr,
                coinid: newCoinId

            })
            // const coinNAr = nameArr;
            // const coinSAr = this.state.coinSearchArray;
            // const coinName = [...coinNAr, ...coinSAr];
            // const coinNameArray = Array.from(new Set(coinName));

            const coinNID = nameArr;
            const coinSID = this.state.coinid;
            const coindd = [...coinNID, ...coinSID];
            const coinIDArray = Array.from(new Set(coindd));

            const { sortWebValue, sortFeatureValue, methodPayment, searchValue, countryID } = this.state;

            const data1 = { sortWebValue, sortFeatureValue, methodPayment, coinNameArray: coinIDArray, searchValue, countryGet:countryID, page:1, isFilter:true }

            this.props.loadWebsite(data1);

            // api over

        } else {
            this.setState({
                coinNameArray: [],
                pageNo:1,
            })
            // api
            // const coinNAr = [];
            // const coinSAr = this.state.coinSearchArray;
            // const coinName = [...coinNAr, ...coinSAr];
            // const coinNameArray = Array.from(new Set(coinName));

            const coinNID = nameArr;
            const coinSID = this.state.coinid;
            const coindd = [...coinNID, ...coinSID];
            const coinIDArray = Array.from(new Set(coindd));

            const { sortWebValue, sortFeatureValue, methodPayment, searchValue, countryID } = this.state;

            const data2 = { sortWebValue, sortFeatureValue, methodPayment, coinNameArray: coinIDArray, searchValue, countryGet:countryID, page:1, isFilter:true }

            this.props.loadWebsite(data2);

            // api over

        }
    }

    getCountry = (info) => {
        const countryGet = [];
        const countryIDs = []; 
        if (info.length !== null) {
            for (let i = 0; i < info.length; i++) {                
                countryGet.push(info[i].value);
                countryIDs.push(info[i].id)

            }

            const { sortWebValue, sortFeatureValue, methodPayment, coinid, searchValue } = this.state;

            const data = { sortWebValue, sortFeatureValue, methodPayment, coinNameArray: coinid, searchValue, countryGet:countryIDs, page:1, isFilter:true }

            this.props.loadWebsite(data);

            this.setState({
                countryGet: countryGet,
                countryID:countryIDs,
                pageNo:1
            })

            // api over

        } else {
            // api
            // const coinNAr = this.state.coinNameArray;
            // const coinSAr = this.state.coinSearchArray;
            // const coinName = [...coinNAr,...coinSAr];
            // const coinNameArray = Array.from(new Set(coinName));

            this.setState({
                countryGet: [],
                countryID:[],
                pageNo:1
            })

            const { sortWebValue, sortFeatureValue, methodPayment, coinid, searchValue } = this.state;

            const data = { sortWebValue, sortFeatureValue, methodPayment, coinNameArray: coinid, searchValue, countryIDs, page:1, isFilter:true }

            this.props.loadWebsite(data);

            // api over
        }

    }

    getSortcountry = (info) => {

        document.getElementById('sortFeature').classList.remove('active');
        document.getElementById('sortCountry').classList.remove('active');
        document.getElementById('sortCoins').classList.remove('active');
        // document.getElementById('OverlayId2').classList.remove('active');
        this.setState({
            isOverlay: false,
        })
        if (info !== 'Country') {
            const countryGet = info.id;
            // console.log(info);

            this.setState({
                countryGet: countryGet,
                countryID:countryGet,
                sortCountry: info.value,
                pageNo:1
            });

            // api

            const { sortWebValue, sortFeatureValue, methodPayment, coinid, searchValue } = this.state;

            const data = { sortWebValue, sortFeatureValue, methodPayment, coinNameArray: coinid, searchValue, countryGet, page:1, isFilter:true }

            this.props.loadWebsite(data);

            // api over
        } else {

            this.setState({
                countryGet: [],
                sortCountry: 'Country',
                pageNo:1
            });
            const countryGet = [];
            // api
            const { sortWebValue, sortFeatureValue, methodPayment, coinid, searchValue } = this.state;

            const data = { sortWebValue, sortFeatureValue, methodPayment, coinNameArray: coinid, searchValue, countryGet, page:1, isFilter:true }

            this.props.loadWebsite(data);

            // api over
        }

    }

    getFeature = (e, feature) => {
        //console.log(feature);
        if (Number(feature) === 0) {
            document.getElementById('feature1').classList.remove('checked');
            document.getElementById('feature2').classList.remove('checked');
        }
        if (Number(feature) === 1) {
            document.getElementById('feature0').classList.remove('checked');
            document.getElementById('feature2').classList.remove('checked');
        }
        if (Number(feature) === 2) {
            document.getElementById('feature0').classList.remove('checked');
            document.getElementById('feature1').classList.remove('checked');
        }
        e.target.classList.toggle('checked');

        if (e.target.classList[1] === 'checked') {

            this.setState({
                sortFeatureValue: feature,
                pageNo:1
            });

            const sortFeatureValue = feature;
            // api

            const { sortWebValue, methodPayment, coinid, searchValue, countryID } = this.state;

            const data = { sortWebValue, sortFeatureValue, methodPayment, coinNameArray: coinid, searchValue, countryGet:countryID, page:1, isFilter:true }

            this.props.loadWebsite(data);

            // api over

        } else {
            const sortFeatureValue = '';

            this.setState({
                sortFeatureValue: '',
                pageNo:1
            });

            // api
            const { sortWebValue, methodPayment, coinid, searchValue, countryID } = this.state;

            const data = { sortWebValue, sortFeatureValue, methodPayment, coinNameArray: coinid, searchValue, countryGet:countryID, page:1, isFilter:true }

            this.props.loadWebsite(data);

            // api over

        }

    }

    getPaymentM = (val) => {
        const nameArr = this.state.methodPayment;
        nameArr.push(val.id);

        for (let h = 0; h < nameArr.length; h++) {
            let curItem = nameArr[h];
            let foundCount = 0;
            for (let i = 0; i < nameArr.length; i++) {
                if (nameArr[i] === nameArr[h]) { foundCount++ };
            }
            if (foundCount > 1) {
                for (let j = 0; j < nameArr.length; j++) {
                    if (nameArr[j] === curItem) {
                        nameArr.splice(j, 1); j--;
                    }
                }
            }
        }
        if (nameArr.length > 0) {
            this.setState({
                methodPayment: nameArr,
                pageNo:1
            })
            // api
            const methodPayment = nameArr;

            const { sortWebValue, sortFeatureValue, coinid, searchValue, countryID } = this.state;

            const data = { sortWebValue, sortFeatureValue, methodPayment, coinNameArray: coinid, searchValue, countryGet:countryID, page:1, isFilter:true }

            this.props.loadWebsite(data);

            // api over

        } else {
            this.setState({
                methodPayment: [],
                pageNo:1
            })
            // api
            const methodPayment = [];

            const { sortWebValue, sortFeatureValue, coinid, searchValue, countryID } = this.state;

            const data = { sortWebValue, sortFeatureValue, methodPayment, coinNameArray: coinid, searchValue, countryGet:countryID, page:1, isFilter:true }

            this.props.loadWebsite(data);
            // api over
        }
    }

    getSearchOption = (val) => {
        const CoinSA = [];
        let coinId = [];
        if (val !== null) {
            for (let i = 0; i < val.length; i++) {
                CoinSA.push(val[i].value);
                coinId.push(val[i].id);                
            }

            this.setState({
                coinSearchArray: CoinSA,
                pageNo:1
            })

            // api
            // const coinNAr = this.state.coinNameArray;
            // const coinSAr = CoinSA;
            // const coinName = [...coinNAr, ...coinSAr];
            // const coinNameArray = Array.from(new Set(coinName));

            const coinNID = coinId;
            const coinSID = this.state.coinid;
            const coindd = [...coinNID, ...coinSID];
            const coinIDArray = Array.from(new Set(coindd));

            const { sortWebValue, sortFeatureValue, methodPayment, searchValue, countryID } = this.state;

            const data = { sortWebValue, sortFeatureValue, methodPayment, coinNameArray: coinIDArray, searchValue, countryGet:countryID, page:1, isFilter:true }

            this.props.loadWebsite(data);

            // api over

        } else {

            this.setState({
                coinSearchArray: [],
                pageNo:1,
            })

            // api
            // const coinNAr = this.state.coinNameArray;
            // const coinSAr = [];
            // const coinName = [...coinNAr, ...coinSAr];
            // const coinNameArray = Array.from(new Set(coinName));

            const coinNID = coinId;
            const coinSID = this.state.coinid;
            const coindd = [...coinNID, ...coinSID];
            const coinIDArray = Array.from(new Set(coindd));

            const { sortWebValue, sortFeatureValue, methodPayment, searchValue, countryID } = this.state;

            const data = { sortWebValue, sortFeatureValue, methodPayment, coinNameArray: coinIDArray, searchValue, countryGet:countryID, page:1, isFilter:true }

            this.props.loadWebsite(data);
            // api over
        }

    }

    componentDidUpdate(prevProps) {
        const { allWebsite, coinsArray } = this.props;
        // console.log(prevProps.allWebsite);
        if (allWebsite.length !== prevProps.allWebsite.length) {
            // console.log('if');
            this.setState({
                AllWebsite: allWebsite
            })
        }

        if (coinsArray.length !== prevProps.coinsArray.length) {
            this.setState({
                coinList: coinsArray
            })
        }
    }

    renderWebsite = (data, i) => {

        const { img_url, name, url, fees, privacy, speed, limits, trusted, easy_use } = data;

        return <li key={i}>
            <div className="website_block">
                <div className="top-bloc">
                    <div className="num-blo">
                        <p>{i + 1}</p>
                    </div>
                    <div className="had-block">
                        <img src={"http://s3.us-east-2.amazonaws.com/cdn.redlinecoin.com/img/" + img_url} alt={name} />
                        <a href={url} target="_blank" rel="noopener noreferrer">{name}</a>
                    </div>
                    <div className="button-block">
                        <a href={url} target="_blank" rel="noopener noreferrer">Website</a>
                    </div>
                </div>
                <div className="bottom-bloc">
                    <ul className="rating-block">
                        <li>
                            <h6>Fees</h6>
                            {(() => {
                                switch (fees) {
                                    case 0: return <img src={Rating_0} alt="rating" />;
                                    case 1: return <img src={Rating_1} alt="rating" />;
                                    case 2: return <img src={Rating_2} alt="rating" />;
                                    case 3: return <img src={Rating_3} alt="rating" />;
                                    case 4: return <img src={Rating_4} alt="rating" />;
                                    case 5: return <img src={Rating_5} alt="rating" />;
                                    default: return <img src={Rating_0} alt="rating" />;
                                }
                            })()}

                        </li>
                        <li>
                            <h6>Privacy</h6>
                            {(() => {
                                switch (privacy) {
                                    case 0: return <img src={Rating_0} alt="rating" />;
                                    case 1: return <img src={Rating_1} alt="rating" />;
                                    case 2: return <img src={Rating_2} alt="rating" />;
                                    case 3: return <img src={Rating_3} alt="rating" />;
                                    case 4: return <img src={Rating_4} alt="rating" />;
                                    case 5: return <img src={Rating_5} alt="rating" />;
                                    default: return <img src={Rating_0} alt="rating" />;
                                }
                            })()}
                        </li>
                        <li>
                            <h6>Speed</h6>
                            {(() => {
                                switch (speed) {
                                    case 0: return <img src={Rating_0} alt="rating" />;
                                    case 1: return <img src={Rating_1} alt="rating" />;
                                    case 2: return <img src={Rating_2} alt="rating" />;
                                    case 3: return <img src={Rating_3} alt="rating" />;
                                    case 4: return <img src={Rating_4} alt="rating" />;
                                    case 5: return <img src={Rating_5} alt="rating" />;
                                    default: return <img src={Rating_0} alt="rating" />;
                                }
                            })()}
                        </li>
                        <li>
                            <h6>Limits</h6>
                            {(() => {
                                switch (limits) {
                                    case 0: return <img src={Rating_0} alt="rating" />;
                                    case 1: return <img src={Rating_1} alt="rating" />;
                                    case 2: return <img src={Rating_2} alt="rating" />;
                                    case 3: return <img src={Rating_3} alt="rating" />;
                                    case 4: return <img src={Rating_4} alt="rating" />;
                                    case 5: return <img src={Rating_5} alt="rating" />;
                                    default: return <img src={Rating_0} alt="rating" />;
                                }
                            })()}
                        </li>
                        <li>
                            <h6>Trusted</h6>
                            {(() => {
                                switch (trusted) {
                                    case 0: return <img src={Rating_0} alt="rating" />;
                                    case 1: return <img src={Rating_1} alt="rating" />;
                                    case 2: return <img src={Rating_2} alt="rating" />;
                                    case 3: return <img src={Rating_3} alt="rating" />;
                                    case 4: return <img src={Rating_4} alt="rating" />;
                                    case 5: return <img src={Rating_5} alt="rating" />;
                                    default: return <img src={Rating_0} alt="rating" />;
                                }
                            })()}
                        </li>
                        <li>
                            <h6>Easy of Use</h6>
                            {(() => {
                                switch (easy_use) {
                                    case 0: return <img src={Rating_0} alt="rating" />;
                                    case 1: return <img src={Rating_1} alt="rating" />;
                                    case 2: return <img src={Rating_2} alt="rating" />;
                                    case 3: return <img src={Rating_3} alt="rating" />;
                                    case 4: return <img src={Rating_4} alt="rating" />;
                                    case 5: return <img src={Rating_5} alt="rating" />;
                                    default: return <img src={Rating_0} alt="rating" />;
                                }
                            })()}
                        </li>
                    </ul>
                </div>
            </div>
        </li>
    }

    dropDown = (e) => {
        e.target.classList.toggle('active');
    }

    render() {

        // const { AllWebsite } = this.state;
        // console.log(AllWebsite);

        const { pageTitle, sortwebsite, isFilterDrop, isOverlay, coinList, paymentMethodArr } = this.state;

        // const optionsLi = coinList.length > 0? coinList.map((data) =>({id:data.id}) : [];
        // if (coinList.length > 0) {
        //     coinList.map((data) => {
        //         return optionsLi.push({ label: data.symbol + ' · ' + data.name, value: data.symbol });
        //     })
        // }

        const sortwebsiteList = [
            { name: 'Popularity', value: 'popular' },
            { name: 'Low Fees', value: 'fees' },
            { name: 'High Privacy', value: 'privacy' },
            { name: 'Speed', value: 'speed' },
            { name: 'Limits', value: 'limits' },
            { name: 'High Trust', value: 'trusted' },
            { name: 'Easy of Use', value: 'easy_use' },
        ]

        const sortwebsArray = sortwebsiteList.map((data, i) =>
            <li key={i} onClick={(e) => this.websiteSort(e, data)} className={(i === 0) ? "list active" : "list"} id={"sortvalue" + i}>{data.name}</li>
        );

        const featureList = [
            { label: "Buy", value: 0 }, { label: "Exchanges", value: 1 }, { label: "Buy And Exchanges", value: 2 }
        ];

        const sort_feature = featureList.map((data, i) =>
            <li key={i} onClick={() => this.getSortFeature(data)} className={(this.state.sortFeature === data.label) ? 'active' : ''}>{data.label}</li>
        );

        const featureArray = featureList.map((da, k) =>
            <li key={k}>
                <div className="ckeck_block">
                    <input type="button" name="feature" id={"feature" + k} className="label_featrure" defaultValue={da.label} onClick={(event) => this.getFeature(event, da.value)} />
                    {/* onChange={()=>this.getFeature(da)}  */}
                    {/* <input type="checkbox" name="feature" id={"feature" + k} defaultValue={da.value} onChange={()=>this.getFeature(da)}/>
                    <label htmlFor={"feature" + k}>{da.label}</label> */}
                </div>
            </li>
        )
        // const coinArr = [];
        // if (coinList.length > 0) {
        //     coinList.map((da, n) => coinArr.push(
        //         <li key={n}>
        //             <div className="ckeck_block">
        //                 <input type="checkbox" name="coinname" id={"coinname" + n} defaultValue={da.web_id} onChange={() => this.getCoinName(da.web_id)} />
        //                 <label htmlFor={"coinname" + n}>{da.name}</label>
        //             </div>
        //         </li>
        //     ))
        // }


        const sort_coin = []
        if (coinList.length > 0) {
            coinList.map((data, i) => sort_coin.push(
                <li key={i} onClick={(event) => this.getSortcoins(event, data)} className={(this.state.sortCoin === data.value) ? 'active' : ''}>{data.label}</li>
            ));
        }

        // const paymentMethod = [
            // { label: paymentMethodArr.name, value: paymentMethodArr.name }
            // , { label: "Bank Account", value: "bank" }, { label: "Cash", value: "cash" }, { label: "PayPal", value: "paypal" }
        // ]

        const paymentMethodArray = paymentMethodArr.length > 0? paymentMethodArr.map((da, n) =>
            <li key={n}>
                <div className="ckeck_block">
                    {/* onChange={()=>this.getPaymentM(da)} */}
                    <input type="checkbox" name="paymentMethod" id={"paymentMethod" + n} defaultValue={da.id} onChange={() => this.getPaymentM(da)} />
                    {/* <label htmlFor={"paymentMethod" + n}>{da.label}</label> */}
                    <label htmlFor={"paymentMethod" + n}>{da.name}</label>
                </div>
            </li>
        ):null;

        const { isLoader, allWebsite } = this.props;

        const { sortFeature, sortCoin, countryListArr } = this.state;
        // console.log(coinList);

        const { title, description, keywords, g_title, g_description, g_content, f_title, f_description, f_content, t_title, t_description, t_content } = this.props.meta;
        
        return (
            <Fragment>
                <Helmet>
                    <title>{title}</title>
                    <meta name="description" id='web_dis' content={description} />
                    <meta name="keywords" id='web_key' content={keywords} />

                    <meta name="apple-itunes-app" content="https://itunes.apple.com/us/app/redline-coin-crypto-signals/id1326274166" />
                    <meta name="google-play-app" content="https://play.google.com/store/apps/details?id=com.redline.coin" />

                    {/* Google / Search Engine Tags */}
                    <meta itemprop="name" id='google_title' content={g_title} />
                    <meta itemprop="description" id='google_dis' content={g_description} />
                    <meta itemprop="image" id='google_img' content={g_content} />

                    {/* Facebook Meta Tags */}
                    <meta property="og:url" content="https://redlinecoin.com" />
                    <meta property="og:type" content="website" />
                    <meta property="og:title" id='facebook_title' content={f_title} />
                    <meta property="og:description" id='facebook_dis' content={f_description} />
                    <meta property="og:image" id='facebook_img' content={f_content} />

                    {/* Twitter Meta Tags */}
                    <meta name="twitter:card" content="summary_large_image" />
                    <meta name="twitter:title" id='twitter_title' content={t_title} />
                    <meta name="twitter:description" id='twitter_dis' content={t_description} />
                    <meta name="twitter:image" id='twitter_img' content={t_content} />
                </Helmet>
                <div className={!isOverlay ? "background_overlay" : "background_overlay active"} id="OverlayId2" onClick={this.closeDrop}></div>
                <section className="BuyCrypto_bg">
                    <div className="container">
                        <div className="BuyCrypto_body">
                            <h1>{pageTitle}</h1>

                            <div className="crypto-content-wrap">
                                <div className="search-crypto">
                                    <input type="text" className="search-cInput" name="search-crypto" placeholder="Search" onChange={this.filterSearch} autoComplete="off" />
                                    <div className="search-filter-crypto">
                                        <label>Sort by</label>
                                        <div className={!isFilterDrop ? "filter-by" : "filter-by active"} id="filterBy" onClick={this.openFilterDrop}>
                                            {/* <div className="filter-by" id="filterBy" onClick={this.openDrop}> */}
                                            {/* <input type="text" name="sortwebsite" defaultValue={this.state.sortwebsite} readOnly={true} /> */}
                                            <p>{sortwebsite}</p>
                                            <ul className="drop_menu2">
                                                {sortwebsArray}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="cypto-content">
                                    <ul className="sort-block mobile-v">
                                        <li>
                                            <div className="sort-body">
                                                {/* <input type="text" id="sortFeature" name="sortFeature" readOnly={true} className="sort-input" defaultValue={this.state.sortFeature} onClick={(event)=>this.sortDrop(event)}/> */}
                                                <label id="sortFeature" name="sortFeature" readOnly={true} className={"sort-input"} onClick={this.sortDrop}>{sortFeature}</label>
                                                <div className="drop-menu3">
                                                    <label className="valu">{sortFeature}</label>
                                                    <ul className="ul-drop">
                                                        <li onClick={(e) => this.getSortFeature('Feature')} className={(sortFeature === 'Feature') ? 'active' : ''}>{'None'}</li>
                                                        {sort_feature}
                                                    </ul>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="sort-body">
                                                {/* <input type="text" id="sortCoins" name="sortCoins" readOnly={true} className="sort-input" defaultValue={this.state.sortCoin} onClick={(event)=>this.sortDrop(event)}/> */}
                                                <label id="sortCoins" name="sortCoins" readOnly={true} className="sort-input" onClick={this.sortDrop}>{sortCoin}</label>
                                                <div className="drop-menu3">
                                                    <label className="valu">{sortCoin}</label>
                                                    <ul className="ul-drop" id="coinA">
                                                        <li onClick={(event) => this.getSortcoins(event, 'Coin')} className={(sortCoin === 'Coin') ? 'active' : ''}>{'None'}</li>
                                                        {sort_coin}
                                                    </ul>
                                                </div>
                                                {/* <ul className="drop-menu3">
                                                    <li className="valu">{this.state.sortCoin}</li>
                                                    { sort_coin }
                                                </ul> */}
                                            </div>
                                        </li>
                                        <li>
                                            <div className="sort-body">
                                                {/* <input type="text" id="sortCountry" name="sortCountry" readOnly={true} className="sort-input" defaultValue={this.state.sortCountry} onClick={(event)=>this.sortDrop(event)}/> */}
                                                <label id="sortCountry" name="sortCountry" readOnly={true} className="sort-input" defaultValue={this.state.sortCountry} onClick={(event) => this.sortDrop(event)}>{this.state.sortCountry}</label>
                                                <div className="drop-menu3">
                                                    <label className="valu">{this.state.sortCountry}</label>
                                                    <ul className="ul-drop">
                                                        <li onClick={() => this.getSortcountry('Country')} className={(this.state.sortCountry === 'Country') ? 'active' : ''}>{'None'}</li>
                                                        {this.state.countryListArr.length > 0?
                                                            this.state.countryListArr.map((data, i)=>
                                                                <li key={i} onClick={() => this.getSortcountry(data)} className={(this.state.sortCountry === data.id) ? 'active' : ''}>{data.label}</li>
                                                            )
                                                            :
                                                            null
                                                        }
                                                    </ul>
                                                </div>
                                                {/* <ul className="drop-menu3">
                                                    <li className="valu">{this.state.sortCountry}</li>
                                                    { sort_country }
                                                </ul> */}
                                            </div>
                                        </li>
                                    </ul>
                                    <div className="crypto-left">
                                        <div className="country_block" ref={this.setWrapperRef}>
                                            {/* <div className="country_block" ref={this.setWrapperRef}> */}
                                            {/* onChange={(event)=>this.getCountry(event)}  */}
                                            <ReactMultiSelectCheckboxes className="cuntryDro" isMulti options={countryListArr} placeholderButtonLabel="Country" onChange={this.getCountry} />
                                            {/* getDropdownButtonLabel={()=>this.getCountry("name", "AUS")} dropdownButton={<button>Name</button>} */}
                                            <span className="gps_icon"></span>
                                            {/* <input type="text" name="countrylist" id="countrylist" defaultValue={this.state.countryList} onClick={this.openCountryDrop} className="country_input" readOnly placeholder="Country"/>
                                            <ul className="drop_menu3" id="dropMenu3">
                                                { countryListArray }
                                            </ul> */}
                                        </div>
                                        <div className="crypto-left-wrap">
                                            <label className="label-crypto" onClick={this.dropDown}>FEATURE</label>
                                            <ul className="drop_menu4" id="dropMenu4">
                                                {featureArray}
                                            </ul>
                                        </div>

                                        <div className="crypto-left-wrap payment-method">
                                            <label className="label-crypto" onClick={this.dropDown}>PAYMENT METHOD</label>
                                            <ul className="drop_menu4 drop_payment" id="dropMenu4">
                                                {paymentMethodArray}
                                            </ul>
                                        </div>
                                        <div className="crypto-left-wrap">
                                            <label className="label-crypto" onClick={this.dropDown}>COIN NAME</label>
                                            <div className="block_seacrh" id="dropMenu4">
                                                <div className="seacrh-body">
                                                    <Select options={coinList} className="search_input" isMulti onChange={this.getSearchOption} />
                                                </div>
                                                <ul className="drop_menu4 drop_coins" >
                                                    {coinList.length > 0 ? 
                                                        coinList.map((info, i)=>
                                                        <li key={i}>
                                                            <div className="ckeck_block">
                                                                <input type="checkbox" name="coinname" id={"coinname" + i} defaultValue={info.value} onChange={() => this.getCoinName(info)} />
                                                                <label htmlFor={"coinname" + i}>{info.label}</label>
                                                            </div>
                                                        </li>)
                                                    : 
                                                        null
                                                    }
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="crypto-right">
                                        {isLoader ?
                                            <LoaderComponent /> :
                                            <ul className="website_body" id="scrollableWebDiv">
                                                {(allWebsite.length > 0) ?
                                                    <InfiniteScroll 
                                                        className="width-forscrool" 
                                                        dataLength={allWebsite.length} 
                                                        next={this.nextWebList123} 
                                                        hasMore={this.props.isSLoader} 
                                                        loader={<ScrollLoader />} 
                                                        scrollableTarget="scrollableWebDiv"
                                                    >
                                                        {allWebsite.map((data, i) => this.renderWebsite(data, i))}
                                                    </InfiniteScroll>
                                                    :
                                                    <RecordNotFound />
                                                } 
                                            </ul>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </Fragment>
        )
    }
}

const mapStateToProps = state => ({
    isLoader: state.buycrypto.isLoader,
    isSLoader:state.buycrypto.isSLoader,
    allWebsite: state.buycrypto.websiteArray,
    coinsArray: state.buycrypto.coinsArray,
    meta: state.meta.metaData
});

const mapDispatchToProps = dispatch => ({
    loadWebsite: (data) => dispatch(loadWebsite(data)),
    loadCoins: () => dispatch(coinsList()),
    loadMeta: (data) => dispatch(loadMeta(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(BuyCrypto);
