import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import './ContactUs.css';
import { submitData } from '../../redux/actions/contactActions';
import { CONTACT_SUBMIT_FALSE } from '../../redux/actions/types';
import UserLoader from '../../assets/image/user-loader.gif';
import { loadMeta } from '../../redux/actions/metaActions';
import { Helmet } from "react-helmet";

class ContactUs extends Component {

    constructor(props){
        super(props);
        this.state={
            fullName:"",
            email:"",
            message:"",
            formErrors:{fullName:"",email:"",message:""},
            nameValid:false,
            emailValid: false,
            messageValid: false,
            msgError:null
        };

        this.SubmitContactFrom = this.SubmitContactFrom.bind(this);
        this.getvalue = this.getvalue.bind(this);
    }

    componentDidMount(){
        const { loadMeta } = this.props;
        loadMeta('contact-us');
    }

    getvalue=(e)=>{
        const { name, value } = e.target;
        if(value.length > 0){
            e.target.classList.add('active');
        }else{
            e.target.classList.remove('active');
        }
        this.setState({
            [name]:value
        },()=>this.validateField(name, value));
    }

    validateField = (fieldName, value) => {
        let fieldValidationErrors = this.state.formErrors;
        let nameValid = this.state.nameValid;
        let emailValid = this.state.emailValid;
        let messageValid = this.state.messageValid;
        
        switch(fieldName) {
            case 'fullName' :
                nameValid = value !== ''; 
                fieldValidationErrors.fullName = nameValid ? '': 'Name Must Required.';
                break;
            case 'email':
                emailValid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
                // emailValid = emailValid.length >= 0;
                fieldValidationErrors.email = emailValid ? '' : 'Email Must Required.';
                break;
            case 'message':
                messageValid = value !== '';
                fieldValidationErrors.message = messageValid ? '': 'Message Must Required.';
                break;
            default:
                break;
        }
        this.setState({
            formErrors: fieldValidationErrors,
            nameValid:nameValid,
            emailValid: emailValid,
            messageValid: messageValid
        });
    }

    SubmitContactFrom=(e)=>{
        e.preventDefault();

        const { fullName, email, message } = this.state;
        const data = {name:fullName, email, message};
        if(fullName !== "" && email !== "" && message !== ""){
            this.setState({
                msgError:null
            })
            this.props.contactData(data);
        }else{
            this.setState({
                msgError:"All field is required"
            });
        }
        
    }

    componentDidUpdate(prevProps){
        const {isSubmitted} = this.props;
        if(isSubmitted !== prevProps.isSubmitted){
            this.setState({
                fullName:"",
                email:"",
                message:"",
                formErrors:{fullName:"",email:"",message:""},
                nameValid:false,
                emailValid: false,
                messageValid: false,
                msgError:null
            });
            const inputClass = document.getElementsByClassName('input_contact');
            for(let i = 0; i < inputClass.length; i++){
                inputClass[i].classList.remove('active');
            }
            document.getElementsByClassName('textarea_contact')[0].classList.remove('active');
        }
    }

    render() {
        const { fullName, email, message, nameValid, emailValid, messageValid, formErrors, msgError } = this.state;
        const nameError= this.state.formErrors.fullName;
        const emailError = this.state.formErrors.email;
        const messageError = this.state.formErrors.message;
        const disabled = !nameValid || !emailValid || !messageValid;

        const { isLoader, isSubmitted, closedSuccess, error } = this.props;
        if(isSubmitted){setTimeout(closedSuccess, 3000)}

        const { title, description, keywords, g_title, g_description, g_content, f_title, f_description, f_content, t_title, t_description, t_content } = this.props.meta;

        return (
            <Fragment>
                <Helmet>
                    <title>{title}</title>
                    <meta name="description" id='web_dis' content={description} />
                    <meta name="keywords" id='web_key' content={keywords} />

                    <meta name="apple-itunes-app" content="https://itunes.apple.com/us/app/redline-coin-crypto-signals/id1326274166" />
                    <meta name="google-play-app" content="https://play.google.com/store/apps/details?id=com.redline.coin" />

                    {/* Google / Search Engine Tags */}
                    <meta itemprop="name" id='google_title' content={g_title} />
                    <meta itemprop="description" id='google_dis' content={g_description} />
                    <meta itemprop="image" id='google_img' content={g_content} />

                    {/* Facebook Meta Tags */}
                    <meta property="og:url" content="https://redlinecoin.com" />
                    <meta property="og:type" content="website" />
                    <meta property="og:title" id='facebook_title' content={f_title} />
                    <meta property="og:description" id='facebook_dis' content={f_description} />
                    <meta property="og:image" id='facebook_img' content={f_content} />

                    {/* Twitter Meta Tags */}
                    <meta name="twitter:card" content="summary_large_image" />
                    <meta name="twitter:title" id='twitter_title' content={t_title} />
                    <meta name="twitter:description" id='twitter_dis' content={t_description} />
                    <meta name="twitter:image" id='twitter_img' content={t_content} />
                </Helmet>
                <div className="container">
                    <div className="contact_body">
                        <h1>Get in touch</h1>

                        <div className="contact_bloc">
                            <h6>Send us a message</h6>
                            {(error !== null)?<div class="alert alert-danger" role="alert"><strong>{error}</strong></div>:null}
                            {(msgError !== null)?<div class="alert alert-danger" role="alert"><strong>{msgError}</strong></div>:null}
                            {isSubmitted?
                                <div class="alert alert-success" role="alert"><strong>Your request has been sent.</strong></div>
                              :
                                null
                            }
                            <form method="post" onSubmit={this.SubmitContactFrom}>
                                {/* <FormErrors formErrors={this.state.formErrors} /> */}
                                <div className="form_group">
                                    <div className="form_group2">
                                        <input type="text" name="fullName" className="input_contact" value={fullName} onChange={this.getvalue}/>
                                        <label>Your Name</label>
                                        <span className="bar"></span>
                                    </div>
                                    {(nameError.length > 0)? <p className="errorContact">{(nameError.length > 0)? formErrors.fullName : ''}</p>:null}
                                    
                                </div>
                                <div className="form_group">
                                    <div className="form_group2">
                                        <input type="email" name="email" className="input_contact" value={email} onChange={this.getvalue}/>
                                        <label>Email</label>
                                        <span className="bar"></span>
                                    </div>
                                    {(emailError.length > 0)? <p className="errorContact">{(emailError.length > 0)? formErrors.email : ''}</p> :null}
                                </div>
                                <div className="form_group">
                                    <div className="form_group2">
                                        <textarea className="textarea_contact" name="message" onChange={this.getvalue} value={message} rows="5"/>
                                        <label>Message</label>
                                        <span className="bar"></span>
                                    </div>
                                    {(messageError.length > 0)?<p className="errorContact">{(messageError.length > 0)? formErrors.message : ''}</p>:null}
                                </div>
                                <div className="form_group">
                                    <button type="submit" disabled={disabled || isLoader } className="btn_contact">{(isLoader)?<img className="loader" src={UserLoader} alt="Loader" />:"Submit"}</button>
                                    {/* {(isSubmitted)?
                                        <p className="green-block">Your request has been sent.</p>
                                        :
                                        null
                                    } */}
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

const mapStateToProps = state =>({
    isLoader:state.contact.isLoader,
    isSubmitted:state.contact.isSubmitted,
    error:state.contactError.contactMSG,
    meta:state.meta.metaData
});

const mapDispatchToProps = dispatch =>({
    contactData:(data)=>dispatch(submitData(data)),
    closedSuccess:()=>dispatch({type:CONTACT_SUBMIT_FALSE}),
    loadMeta:(data)=>dispatch(loadMeta(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(ContactUs);
