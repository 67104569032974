import * as ACTION_TYPES from '../actions/types';

const initialState = {
    metaData:[]
}

const MetaDataR = (state = initialState, action)=>{
    switch(action.type){
        case ACTION_TYPES.META_SUCCESS:
            // console.log(action.payload);
            return{
                ...state,
                metaData:action.payload
            }
        default:
            return state;
    }
}

export default MetaDataR;