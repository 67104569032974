// auth types
export const USER_LOADING = 'USER_LOADING';
export const USER_LOADED = 'USER_LOADED';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_SEND = 'LOGIN_SEND';
export const SIGNUP_SEND = 'SIGNUP_SEND';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
export const SIGNUP_FAIL = 'SIGNUP_FAIL';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const AUTH_ERROR = 'AUTH_ERROR';
export const USER_GET_ERROR = 'USER_GET_ERROR';
export const USER_CLEAR_ERROR = 'USER_CLEAR_ERROR';
export const SIGNUP_CLEAR_ERROR = 'SIGNUP_CLEAR_ERROR';
export const SIGNUP_GET_ERROR = 'SIGNUP_GET_ERROR';
export const LOGIN_CLEAR_ERROR = 'LOGIN_CLEAR_ERROR';
export const LOGIN_GET_ERROR = 'LOGIN_GET_ERROR';
export const FORGOT_SEND = 'FORGOT_SEND';
export const FORGOT_SUCCESS = 'FORGOT_SUCCESS';
export const FORGOT_FAIL = 'FORGOT_FAIL';
export const FORGOT_GET_ERROR = 'FORGOT_GET_ERROR';
export const FORGOT_CLEAR_ERROR = 'FORGOT_CLEAR_ERROR';
export const RESEND_SEND = 'RESEND_SEND';
export const RESEND_SUCCESS = 'RESEND_SUCCESS';
export const RESEND_FAIL = 'RESEND_FAIL';
export const RESEND_GET_ERROR = 'RESEND_GET_ERROR';
export const RESEND_CLEAR_ERROR = 'RESEND_CLEAR_ERROR';
export const SECURITY_SEND = 'SECURITY_SEND';
export const SECURITY_SUCCESS = 'SECURITY_SUCCESS';
export const SECURITY_FAIL = 'SECURITY_FAIL';
export const SECURITY_GET_ERROR = 'SECURITY_GET_ERROR';
export const SECURITY_CLEAR_ERROR = 'SECURITY_CLEAR_ERROR';

// login modal
export const OPEN_LOGIN = 'OPEN_LOGIN';
export const CLOSE_LOGINM = 'CLOSE_LOGINM';
export const TOGGLE_LOGOUTMODAL = 'TOGGLE_LOGOUTMODAL';
export const TOGGLE_FORGOTMODAL = 'TOGGLE_FORGOTMODAL';
export const TOGGEL_SECURITYMODAL = 'TOGGEL_SECURITYMODAL';
export const LOGIN_TABS = 'LOGIN_TABS';

// footer contact me
export const REPLAY_SEND = 'REPLAY_SEND';
export const REPLAY_SUCCESS = 'REPLAY_SUCCESS';
export const REPLAY_FAIL = 'REPLAY_FAIL';
export const REPLAY_GET_ERROR = 'REPLAY_GET_ERROR';
export const REPLAY_CLEAR_ERROR = 'REPLAY_CLEAR_ERROR';

// buyornot types
export const LOADING_ALLCOINS = 'LOADING_ALLCOINS';
export const LOADED_ALLCOINS = 'LOADED_ALLCOINS';
export const LOADED_MODALALLCOINS = 'LOADED_MODALALLCOINS';
export const ALLCOINS_FAIL = 'ALLCOINS_FAIL';
export const ALLCOINS_GET_ERROR = 'ALLCOINS_GET_ERROR';
export const ALLCOINS_CLEAR_ERROR = 'ALLCOINS_CLEAR_ERROR';
export const LOADING_ACTIVECOINS = 'LOADING_ACTIVECOINS';
export const LOADED_ACTIVECOINS = 'LOADED_ACTIVECOINS';
export const ACTIVECOINS_FAIL = 'ACTIVECOINS_FAIL';
export const ACTIVECOINS_GET_ERROR = 'ACTIVECOINS_GET_ERROR';
export const ACTIVECOINS_CLEAR_ERROR = 'ACTIVECOINS_CLEAR_ERROR';
// export const ADD_CRYPTOCOINS = 'ADD_CRYPTOCOINS';
// export const REMOVE_CRYPTOCOINS = 'REMOVE_CRYPTOCOINS';
export const CRYPTO_LOADING = 'CRYPTO_LOADING';
export const CRYPTOCOINS_SUCCESS = 'CRYPTOCOINS_SUCCESS';
export const CRYPTOCOINS_FAIL = 'CRYPTOCOINS_FAIL';
export const CRYPTO_GET_ERROR = 'CRYPTO_GET_ERROR';
export const CRYPTO_CLEAR_ERROR = 'CRYPTO_CLEAR_ERROR';

// appD Modal
export const TOGGLE_APPDM = 'TOGGLE_APPDM';
// crypto modal
export const TOGGLE_CRYPMODAL = 'TOGGLE_CRYPMODAL';
// buyornot page end

// buy crypto page
export const LOADING_WEBSITES = 'LOADING_WEBSITES';
export const LOADED_WEBSITES_SUCCESS = 'LOADED_WEBSITES_SUCCESS';
export const LOADED_WEBSITE_FAIL = 'LOADED_WEBSITE_FAIL';
export const WEBSITE_GET_ERROR = 'WEBSITE_GET_ERROR';
export const WEBSITE_CLEAR_ERROR = 'WEBSITE_CLEAR_ERROR';
export const LOADING_SMALL_WEBSITES = 'LOADING_SMALL_WEBSITE';
// export const LOADING_COINS = 'LOADING_COINS';
export const LOADED_COINS_SUCCESS = 'LOADED_COINS_SUCCESS';
export const LOADED_COINS_FAIL = 'LOADED_COINS_FAIL';
export const COINS_GET_ERROR = 'COINS_GET_ERROR';
export const COINS_CLEAR_ERROR = 'COINS_CLEAR_ERROR'; 
// buy crypto page end

// top news page
export const LOADING_NEWS = 'LOADING_NEWS';
export const LOADED_NEWS_SUCCESS = 'LOADED_NEWS_SUCCESS';
export const LOADED_NEWS_FAIL = 'LOADED_NEWS_FAIL';
export const NEWS_GET_ERROR = 'NEWS_GET_ERROR';
export const NEWS_CLEAR_ERROR = 'NEWS_CLEAR_ERROR';
// top news page end

// top youtube page
export const LOADING_YOUTUBE = 'LOADING_YOUTUBE';
export const LOADED_YOUTUBE_SUCCESS = 'LOADED_YOUTUBE_SUCCESS';
export const LOADED_YOUTUBE_FAIL = 'LOADED_YOUTUBE_FAIL';
export const LOADED_LANG_SUCCESS = 'LOADED_LANG_SUCCESS';
export const LOADED_LANG_FAIL = 'LOADED_LANG_FAIL';
export const YOUTUBE_GET_ERROR = 'YOUTUBE_GET_ERROR';
export const YOUTUBE_CLEAR_ERROR = 'YOUTUBE_CLEAR_ERROR';
export const LANG_GET_ERROR = 'LANG_GET_ERROR';
export const LANG_CLEAR_ERROR = 'LANG_CLEAR_ERROR';
// top youtube page end

// top signal page 
export const LOADING_OPEN_SIGNAL = 'LOADING_OPEN_SIGNAL';
export const OPEN_SIGNAL_SUCCESS = 'OPEN_SIGNAL_SUCCESS';
export const OPEN_SIGNAL_FAIL = 'OPEN_SIGNAL_FAIL';
export const OSIGNAL_GET_ERROR = 'OSIGNAL_GET_ERROR';
export const OSIGNAL_CLEAR_ERROR = 'OSIGNAL_CLEAR_ERROR';
export const LOADING_CLOSE_SIGNAL = 'LOADING_CLOSE_SIGNAL';
export const CLOSE_SIGNAL_SUCCESS = 'CLOSE_SIGNAL_SUCCESS';
export const CLOSE_SIGNAL_FAIL = 'CLOSE_SIGNAL_FAIL';
export const CSIGNAL_GET_ERROR = 'CSIGNAL_GET_ERROR';
export const CSIGNAL_CLEAR_ERROR = 'CSIGNAL_CLEAR_ERROR';
export const CALCULATION_SUCCESS = 'CALCULATION_SUCCESS';
export const CALCULATION_FAIL = 'CALCULATION_FAIL';
export const CALCULATION_GET_ERROR = 'CALCULATION_GET_ERROR';
export const CALCULATION_CLEAR_ERROR = 'CALCULATION_CLEAR_ERROR';
// top signal page end

// contact us page
export const CONTACT_SUBMIT = 'CONTACT_SUBMIT';
export const CONTACT_SUCCESS = 'CONTACT_SUCCESS';
export const CONTACT_FAIL = 'CONTACT_FAIL';
export const CONTACT_SUBMIT_FALSE = 'CONTACT_SUBMIT_FALSE';
export const CONTACT_GET_ERROR = 'CONTACT_GET_ERROR';
export const CONTACT_CLEAR_ERROR = 'CONTACT_CLEAR_ERROR';
// contact us page end

// meta data
export const META_SUCCESS = 'META_SUCCESS';
export const META_FAIL = 'META_FAIL';
export const META_GET_ERROR = 'META_GET_ERROR';
export const META_CLEAR_ERROR = 'META_CLEAR_ERROR';
// meta data end

// intercom
export const INTERCOM_SUCCESS = 'INTERCOM_SUCCESS';
export const INTERCOM_FAIL = 'INTERCOM_FAIL';
export const HMAC_SUCCESS = 'HMAC_SUCCESS';
export const HMAC_FAIL = 'HMAC_FAIL';
export const INTERCOM_GET_ERROR = 'INTERCOM_GET_ERROR';
export const INTERCOM_CLEAR_ERROR = 'INTERCOM_CLEAR_ERROR';
export const HMAC_GET_ERROR = 'HMAC_GET_ERROR';
export const HMAC_CLEAR_ERROR = 'HMAC_CLEAR_ERROR';
// intercom end

