import * as ACTION_TYPES from '../actions/types';

const initialState = {
    newsArray: [],
    isLoader: false,
    isSLoader: false
}

const newsReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTION_TYPES.LOADING_NEWS:
            return {
                ...state,
                isLoader: true
            }
        case ACTION_TYPES.LOADED_NEWS_SUCCESS:
            return {
                ...state,
                newsArray: [...state.newsArray, ...action.payload],
                isLoader: false,
                isSLoader: action.payload.length > 0 ? true : false
            }
        default:
            return state;
    }
}

export default newsReducer;