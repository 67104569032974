import * as ACTION_TYPES from './types';

export const returnWebsiteError = (msg, status, id)=>{
    return{
        type:ACTION_TYPES.WEBSITE_GET_ERROR,
        payload:{ msg, status, id }
    }
}

export const returnWCoinsError = (msg, status, id)=>{
    return{
        type:ACTION_TYPES.COINS_GET_ERROR,
        payload:{ msg, status, id }
    }
}

export const clearWebsiteError = () =>{
    return{
        type:ACTION_TYPES.WEBSITE_CLEAR_ERROR
    }
}

export const clearWCoinsError = () =>{
    return{
        type:ACTION_TYPES.COINS_CLEAR_ERROR
    }
}