import React, { Component } from 'react';
import { connect } from 'react-redux';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'animate.css/animate.min.css';
import 'jquery';
import './assets/css/Style.css';
// import LoaderComponent from './components/LoaderComponent';
import Routes from './routes';
// const Routes = lazy(()=>import ('./routes'))

class App extends Component {
  render(){

    return (
      // <Suspense fallback={<LoaderComponent />}>
        <Routes />
      // </Suspense>
    );

  }
}

export default connect()(App);
