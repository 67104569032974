import * as ACTION_TYPES from '../actions/types';

const initialState = {
    newsMSG:null,
    status:null,
    id:null,
    cryptoMSG:null,
}

const newsError = (state = initialState, action) =>{
    switch(action.type){
        case ACTION_TYPES.NEWS_GET_ERROR:
            return{
                ...state,
                newsMSG:action.payload.msg,
                status:action.payload.status,
                id:action.payload.id
            }
        case ACTION_TYPES.NEWS_CLEAR_ERROR:
            return{
                ...state,
                newsMSG:null,
                status:null,
                id:null
            }
        default:
            return state;
    }
}

export default newsError;