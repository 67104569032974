import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { REPLAY_SEND, REPLAY_FAIL } from '../redux/actions/types';
import UserLoader from '../assets/image/user-loader.gif';
import { sendContactMe } from '../redux/actions/footerActions';

class Footer extends Component {
    constructor(props){
        super(props);
        this.state={
            email:'',
            formErrors:{email:''},
            emailValid:false,
            errorMSG:null,
            isSuccess:false
        }

        this.pageTop = this.pageTop.bind(this);
        this.SubmitContactFrom = this.SubmitContactFrom.bind(this);
        this.getvalue = this.getvalue.bind(this);
        this.validateField = this.validateField.bind(this);
    }

    SubmitContactFrom = (e) =>{
        e.preventDefault();
        const { email } = this.state;
        const { sendData, submitEmail, replayFail } = this.props;
        sendData();

        if(email !== ''){
            this.setState({
                errorMSG:null,
                email: ''
            })
            submitEmail(email);
        }else{
            replayFail();
            this.setState({
                errorMSG:"Field is Required",
                email: ''
            })
        }
    }

    getvalue = (e) =>{
        const { name, value } = e.target;
        this.setState({
            email:value
        }, ()=>this.validateField(name, value))
    }

    validateField = (fieldName, value) => {
        let fieldValidationErrors = this.state.formErrors;
        let emailValid = this.state.emailValid;
        
        switch(fieldName) {
            case 'email':
                emailValid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
                // emailValid = emailValid.length >= 0;
                fieldValidationErrors.email = emailValid ? '' : 'Email Must Required.';
                break;
            default:
                break;
        }
        this.setState({
            formErrors: fieldValidationErrors,
            emailValid: emailValid
        });
    }

    componentDidUpdate(prevProps){
        const { error } = this.props;
        if(error !== prevProps.error){
            // check for login error
            if(error.id === 'REPLAY_FAIL'){
                this.setState({errorMSG:error.replayMSG});

            }else{
                this.setState({errorMSG:null})
            }
        }

        // if auth, close modal
        // if(isAuth){
        //     this.props.closeModal();
        // }
    }

    pageTop=()=>{
        window.scrollTo(0, 0);
    }

    render() {

        const { email, formErrors, emailValid, errorMSG } = this.state;

        const { isLoading, isSuccess } = this.props;

        // console.log(this.props);
        var currYear = new Date().getFullYear();

        return (
            <footer className="footer">
                <div className="top-footer">
                    <div className="container">
                        <div className="row">
                            <div className="span-2 xs-span-3">
                                <ul className="footer-menu">
                                    <li><h4>GENERAL</h4></li>
                                    <li><Link to="/" onClick={this.pageTop}>Home</Link></li>
                                    <li className=""><Link to="/contact-us" onClick={this.pageTop}>Contact Us</Link></li>
                                    <li className="sm-hidden"><Link to="/buy-or-sell" className="footMenu" onClick={this.pageTop}>Buy or Sell</Link></li>
                                    <li><Link to="/about-us" onClick={this.pageTop}>About Us</Link></li>
                                </ul>
                            </div>
                            <div className="span-2 xs-span-3">
                                <ul className="footer-menu">
                                    <li><h4>POLICY</h4></li>
                                    <li><Link to="/policy" onClick={this.pageTop}>Privacy Policy</Link></li>
                                    <li><Link to="/terms-of-use" onClick={this.pageTop}>Terms of use</Link></li>
                                    <li><Link to="/disclaimer" onClick={this.pageTop}>Disclaimer</Link></li>
                                    <li><Link to="/news" onClick={this.pageTop}>Top News</Link></li>
                                </ul>
                            </div>
                            <div className="span-3 xs-span-6">
                                <ul className="footer-menu">
                                    <li><h4>CONTACT</h4></li>
                                    <li><p>Columbus OH 43229 USA</p></li>
                                    <li><a href="https://mail.google.com/mail/u/0/#inbox" rel="noopener noreferrer" target="_blank">support@redlinecoin.com</a></li>
                                </ul>
                                <ul className="social-icons sm-hidden">
                                    <li><a href="https://www.facebook.com/redlinecoin" target="_blank" rel="noopener noreferrer"><span className="fb"></span></a></li>
                                    <li><a href="https://twitter.com/redlinecoin2" target="_blank" rel="noopener noreferrer"><span className="twi"></span></a></li>
                                    <li><a href="https://www.instagram.com/redlinecoin" target="_blank" rel="noopener noreferrer"><span className="insta"></span></a></li>
                                </ul>
                            </div>
                            <div className="span-5 xs-hidden">
                                <div className="replay2">
                                    <p>Tell us your Email and we will reply you within 24 hours</p>
                                    
                                    <form method="post" onSubmit={this.SubmitContactFrom}>
                                        <div className="input-group input-group_footer">
                                            <input type="email" className="form-control2" name="email" placeholder="Enter your email" value={email} onChange={this.getvalue} autoComplete={'off'}/>
                                            <div className="input-group-btn">
                                                <button type="submit" disabled={!emailValid} className="btn-submit2" id="contact_button_phone" >{isLoading?<img className="small_loader" src={UserLoader} alt="loader" />:"Contact Me"}</button>
                                            </div>
                                        </div>
                                        {(formErrors.email.length > 0)? <p className="errorContact">{(formErrors.email.length > 0)? formErrors.email : ''}</p> :null}
                                        {errorMSG !== null?<p className="errorContact">{errorMSG}</p>:null}
                                        {(isSuccess)?
                                            <p className="green-block">Your request has been sent.</p>
                                            :
                                            null
                                        }
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bottom-footer">
                    <div className="container">
                        <div className="row">
                            <div className="span-8 xs-span-12 width-100">
                                <div className="copyright-block">
                                    <p>Copyright © {currYear}.  www.redlinecoin.com. All rights reserved.</p>
                                </div>
                            </div>
                            <div className="span-4 xs-hidden">
                                <ul className="social-icons">
                                    <li><a href="https://www.facebook.com/redlinecoin" target="_blank" rel="noopener noreferrer"><span className="fb"></span></a></li>
                                    <li><a href="https://twitter.com/redlinecoin2" target="_blank" rel="noopener noreferrer"><span className="twi"></span></a></li>
                                    <li><a href="https://www.instagram.com/redlinecoin" target="_blank" rel="noopener noreferrer"><span className="insta"></span></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        )
    }
}

const mapStateToProps = state =>({
    isAuth: state.auth.isAuth,
    isLoading:state.footer.isLoader,
    error:state.error,
    isSuccess:state.footer.successMSG
});

const mapDispatchToProps = dispatch =>({
    sendData:()=>dispatch({type:REPLAY_SEND}),
    submitEmail:(email)=>dispatch(sendContactMe(email)),
    replayFail:()=>dispatch({type:REPLAY_FAIL})
})

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
