import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { loadNews } from '../../redux/actions/newsActions';
import LoaderComponent from '../LoaderComponent';
import RecordNotFound from '../RecordNotFound';
import ScrollLoader from '../ScrollLoader';
import Redline_logo from '../../assets/image/logo.svg';
import InfiniteScroll from "react-infinite-scroll-component";
import './TopNews.css';
import { loadMeta } from '../../redux/actions/metaActions';
import { Helmet } from "react-helmet";
import moment from 'moment';

class TopNews extends Component {
    constructor(props) {
        super(props)
        this.state = {
            pageNo: 1,
            // isLoader:false,
            isSLoader:true,
        }
    }
    componentDidMount() {
        const { pageNo } = this.state
        const { loadNews, loadMeta } = this.props;
        loadMeta('news');
        loadNews(pageNo);

    }

    nextTopNews = () => {
        const { loadNews } = this.props;
        if (this.props.isSLoader) {
            const inc = this.state.pageNo + 1;
            this.setState({
                pageNo: inc,                
            })
            loadNews(inc)        
        } 
        else {
            this.setState({
                isSLoader: true
            })
        }
    }

    imgError = (e) => {
        // console.log('error');    
        e.target.src = Redline_logo;
    }

    renderNews = (data, i) => {
        const { url, title, site, d_pub, img_url } = data;
        const fromTo = moment(d_pub).fromNow();
        return (
            <li key={i}>
                <div className="news-block">
                    <div className="news-text">
                        <a href={url} target="_block" rel="noopener noreferrer">{title}</a>
                        <p>{site + " : " + fromTo}</p>
                    </div>
                    <div className="newsImg_block">
                        <img src={img_url} alt="news" onError={(e) => this.imgError(e)} />
                    </div>
                </div>
            </li>
        )
    }

    render() {

        const { isLoader, newsArray } = this.props;
        const { title, description, keywords, g_title, g_description, g_content, f_title, f_description, f_content, t_title, t_description, t_content } = this.props.meta;
        // console.log(this.state.pageNo)
        return (
            <Fragment>
                <Helmet>
                    <title>{title}</title>
                    <meta name="description" id='web_dis' content={description} />
                    <meta name="keywords" id='web_key' content={keywords} />

                    <meta name="apple-itunes-app" content="https://itunes.apple.com/us/app/redline-coin-crypto-signals/id1326274166" />
                    <meta name="google-play-app" content="https://play.google.com/store/apps/details?id=com.redline.coin" />

                    {/* Google / Search Engine Tags */}
                    <meta itemprop="name" id='google_title' content={g_title} />
                    <meta itemprop="description" id='google_dis' content={g_description} />
                    <meta itemprop="image" id='google_img' content={g_content} />

                    {/* Facebook Meta Tags */}
                    <meta property="og:url" content="https://redlinecoin.com" />
                    <meta property="og:type" content="website" />
                    <meta property="og:title" id='facebook_title' content={f_title} />
                    <meta property="og:description" id='facebook_dis' content={f_description} />
                    <meta property="og:image" id='facebook_img' content={f_content} />

                    {/* Twitter Meta Tags */}
                    <meta name="twitter:card" content="summary_large_image" />
                    <meta name="twitter:title" id='twitter_title' content={t_title} />
                    <meta name="twitter:description" id='twitter_dis' content={t_description} />
                    <meta name="twitter:image" id='twitter_img' content={t_content} />
                </Helmet>
                <section className="news_bg">
                    <div className="container">
                        <div className="news_body">
                            <h1>Top News</h1>
                            <div className="news_main">
                                <ul className="news_ul" id="scrollableDivProfile">
                                    {(isLoader) ? <LoaderComponent />
                                        : (newsArray.length > 0) ?
                                        <InfiniteScroll className="width-forscrool" dataLength={newsArray.length} next={this.nextTopNews} hasMore={this.props.isSLoader} loader={<ScrollLoader />} scrollableTarget="scrollableDivProfile">
                                            {newsArray.map((data, i) => this.renderNews(data, i))}
                                        </InfiniteScroll>
                                        : <RecordNotFound />
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>
            </Fragment>
        )
    }
}

const mapStateToProps = state => ({
    newsArray: state.news.newsArray,
    isLoader: state.news.isLoader,
    meta: state.meta.metaData,
    isSLoader:state.news.isSLoader
});

const mapDispatchToProps = dispatch => ({
    loadNews: (page) => dispatch(loadNews(page)),
    loadMeta: (data) => dispatch(loadMeta(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(TopNews);
