import React, { Component, Fragment } from 'react';
import UserLoader from '../assets/image/user-loader.gif';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { clearLoginError } from '../redux/actions/errorActions';
import { loginUser, IntercomData, hash_hmac } from '../redux/actions/authActions';
import { CLOSE_LOGINM, LOGIN_SEND, LOGIN_FAIL, TOGGLE_FORGOTMODAL } from '../redux/actions/types'
import { jwtDecode } from '../helper/jwt_helper';
import { withRouter } from 'react-router-dom';

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isSubmitted: false,
            loginEmail: '',
            loginPassword: '',
            ErrorLogin: {
                loginEmail: '',
                loginPassword: ''
            },
            errorMessage2: '',
        }

        this.loginSubmit = this.loginSubmit.bind(this);
        this.getLoginValue = this.getLoginValue.bind(this);
    }

    static propTypes = {
        isAuth: PropTypes.bool,
        error: PropTypes.object.isRequired,
        loginUser: PropTypes.func.isRequired,
        clearError: PropTypes.func.isRequired
    }

    componentDidUpdate(prevProps) {
        const { isAuth } = this.props;
        // if(error !== prevProps.error){
        //     // check for login error
        //     if(error.id === 'LOGIN_FAIL'){
        //         this.setState({errorMessage2:error.loginMSG});

        //     }else{
        //         this.setState({errorMessage2:null})
        //     }
        // }

        // if auth, close modal
        if (isAuth) {
            this.props.closeModal();
        }
    }

    getLoginValue = (e) => {
        const { name, value } = e.target;
        this.setState({
            [name]: value
        }, () => this.validation(name, value))
    }

    // form validation
    validation = (name, value) => {
        let ErrorLogin = this.state.ErrorLogin;
        let emailValid = this.state.loginEmailValid;
        let passwordValid = this.state.loginPasswordValid;
        if (name === 'loginEmail') {
            if (value.length > 0) {
                // console.log('if');
                if (!value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
                    emailValid = false;
                    ErrorLogin.loginEmail = 'Enter valid email';
                    // console.log('if if');
                } else {
                    // console.log('if else');
                    emailValid = true;
                    ErrorLogin.loginEmail = ''
                }
            } else {
                // console.log('else');
                emailValid = false;
                ErrorLogin.loginEmail = 'Email Must Required.';
            }
        }


        switch (name) {
            // case 'loginEmail':
            //     emailValid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
            //     ErrorLogin.loginEmail = emailValid ? '' : 'Email Must Required.';
            //     break;
            case 'loginPassword':
                // Password must be 6 characters long!
                passwordValid = value.length > 0;
                ErrorLogin.loginPassword = passwordValid ? '' : 'Required';
                break;
            default:
                break;
        }
        this.setState({
            ErrorLogin: ErrorLogin,
            loginEmailValid: emailValid,
            loginPasswordValid: passwordValid,
        });

    }

    loginSubmit = (e) => {
        e.preventDefault();
        this.props.dataSend();
        const { loginEmail, loginPassword } = this.state;
        const { loginUser, hash_hmac2, IntercomData2 } = this.props;
        if (loginEmail !== '' || loginPassword !== '') {
            loginUser(loginEmail, loginPassword).then((res) => {
                if (res.status) {

                    const decode = jwtDecode(res.data);

                    IntercomData2();
                    hash_hmac2();
                    if(localStorage.getItem("isPurchase") && localStorage.getItem("isPurchase") !== '' && decode.subscription === '0'){
                        localStorage.removeItem("isPurchase");
                        this.props.history.push('/checkout');
                    }else{
                        localStorage.removeItem("isPurchase");
                        window.location.reload(false);
                    }
                }
                else {
                    this.setState({
                        errorMessage2: res.message
                    })
                }
            });
        } else {
            this.props.loginFail()
            this.setState({
                errorMessage2: 'All Field is Required'
            });
        }

        // this.props.closeModal();
    }


    render() {
        // console.log(this.props);
        const { isSubmitted, loginEmail, loginPassword, ErrorLogin, errorMessage2, loginEmailValid, loginPasswordValid } = this.state;

        const disabled = !loginEmailValid || !loginPasswordValid;

        const { isLoading2, ToggleForgotM } = this.props;
        return (
            <Fragment>
                <div className="loginForm">
                    <form method="POST" onSubmit={this.loginSubmit} autoComplete="off">
                        {(isSubmitted) ? <p className="green-block">Your request has been sent.</p> : null}
                        {(ErrorLogin.loginEmail.length > 0) ? <p className="errorContact">{(ErrorLogin.loginEmail.length > 0) ? ErrorLogin.loginEmail : ''}</p> : null}
                        <div className="form-group mb-0">
                            <input type="email" className="form-controllog border-radt" placeholder="Email" name="loginEmail" onChange={this.getLoginValue} value={loginEmail} autoComplete="new-password" />
                        </div>
                        <div className="form-group mb-0">
                            <input type="password" className="form-controllog border-radb" placeholder="Password" name="loginPassword" onChange={this.getLoginValue} value={loginPassword} autoComplete="new-password" />
                        </div>
                        {(ErrorLogin.loginPassword.length > 0) ? <p className="errorContact">{(ErrorLogin.loginPassword.length > 0) ? ErrorLogin.loginPassword : ''}</p> : null}
                        {(errorMessage2 !== '') ? <p className="errorContact">{errorMessage2}</p> : null}
                        {/* {(error.loginMSG !== null)?<p className="errorContact">{error.loginMSG}</p>:null} */}
                        {/* {(this.state.isUserLoader)? 
                            <div className="user_loader">
                                <img src={UserLoader} alt="loader" />
                            </div>
                            : 
                            null
                        } */}
                        <div className="form-group mb-0 mt-100">
                            <button type="submit" className="btn_login2" disabled={disabled || isLoading2}>{isLoading2 ? <img src={UserLoader} className="sendLoader" alt="loader" /> : "Login"}</button>
                            <button type="button" className="forget-btn cursor_p" onClick={() => ToggleForgotM()}>Forgot password?</button>
                        </div>
                    </form>
                </div>
            </Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    // console.log(state);
    return {
        isAuth: state.auth.isAuth,
        error: state.error,
        loginModal: state.authModal.loginModal,
        isLoading2: state.auth.loginLoading,
    }
}

const mapDispatchToProps = dispatch => ({
    loginUser: (email, password) => dispatch(loginUser(email, password)),
    clearError: () => dispatch(clearLoginError()),
    closeModal: () => dispatch({ type: CLOSE_LOGINM }),
    dataSend: () => dispatch({ type: LOGIN_SEND }),
    loginFail: () => dispatch({ type: LOGIN_FAIL }),
    ToggleForgotM: () => dispatch({ type: TOGGLE_FORGOTMODAL }),
    IntercomData2: () => dispatch(IntercomData()),
    hash_hmac2: () => dispatch(hash_hmac())

})



export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login));
