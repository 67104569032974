import React, { Component } from 'react'
import { connect } from 'react-redux';
import { TOGGLE_CRYPMODAL, } from '../../redux/actions/types';
import SearchIcons2 from '../../assets/image/search_icon2.svg';
import Modal from 'react-bootstrap/Modal';
import { send_cryptoCurrency, modalLoadAllCoins, loadActiveCoins } from '../../redux/actions/buyornotActions';
import UserLoader from '../../assets/image/user-loader.gif';

class CryptoModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            MSearchVal: '',
            searchBlock: false,
            cryptoSelect2: [],
            coma2: [],
            allCoin: [],
            checked: false
        }

        this.getSearchVal = this.getSearchVal.bind(this);
        this.toggleSearch = this.toggleSearch.bind(this);
        this.saveCoins = this.saveCoins.bind(this);
    }

    getSearchVal = (e) => {
        this.setState({
            MSearchVal: e.target.value
        });
    }

    toggleSearch = () => {
        this.setState({
            searchBlock: !this.state.searchBlock
        })
    }

    viewAllCoin = (data, i) => {
        const { name, symbol, selected } = data;
        return (
            <li key={i}>
                <p>{symbol + ' - ' + name}
                    <span className="check-btn">
                        <input type="checkbox" name="cryptocurrency" value={symbol} checked={selected === 1} className={selected === 1 ? "active" : ""} id={symbol} onChange={() => this.addCryptoCoins2(data)} />
                        <label className="checkbox-d" htmlFor={symbol}></label>
                    </span>
                </p>
            </li>
        )
    }

    viewSelectList = (data, i) => {

        const { symbol, name } = data;

        return (
            <li key={i}>
                <p>{symbol + ' - ' + name}
                    <span className="check-btn">
                        <input type="checkbox" name="cryptocurrency" value={symbol} coinid={symbol} id={'coins' + i} onChange={() => this.removeCoins(data)} className="active" />
                        <label className="checkbox-d" htmlFor={'coins' + i}></label>
                    </span>
                </p>
            </li>
        )
    }

    removeCoins = (data) => {
        this.state.allCoin.map((val) => {
            if (data.id === val.id) {
                document.getElementById(val.symbol).removeAttribute('checked', false);
                document.getElementById(data.symbol).removeAttribute('class');
            }
            return this.setState({
                checked: false,
            })
        })
        let nameArr = this.state.cryptoSelect2;
        let coma = this.state.coma2;

        for (let i = 0; i < nameArr.length; i++) {
            if (nameArr[i].id === data.id) {
                nameArr.splice(i, 1);
                coma.splice(i, 1);
                i--;
            }
        }
        if (nameArr.length > 0) {
            this.setState({
                cryptoSelect2: nameArr,
                coma2: coma,
            })
        } else {
            this.setState({
                cryptoSelect2: [],
                coma2: this.state.coma2,
            })
        }
    }

    addCryptoCoins2 = data => {
        const { id, symbol } = data;

        document.getElementById(symbol).setAttribute('checked', true);
        document.getElementById(symbol).setAttribute('class', 'active');

        let newArray = this.state.cryptoSelect2;
        let coma = this.state.coma2;

        newArray.push(data);
        coma.push(id);

        for (let h = 0; h < newArray.length; h++) {
            let curItem = newArray[h];
            let foundCount = 0;
            for (let i = 0; i < newArray.length; i++) {

                if (newArray[i] === newArray[h]) {
                    foundCount++;
                };
            }
            if (foundCount > 1) {
                for (let j = 0; j < newArray.length; j++) {
                    if (newArray[j] === curItem) {
                        newArray.splice(j, 1);
                        coma.splice(j, 1);
                        document.getElementById(symbol).removeAttribute('checked');
                        document.getElementById(symbol).removeAttribute('class');
                        j--;
                    }
                }
            }
        }

        if (newArray.length > 0) {
            return this.setState({
                cryptoSelect2: newArray,
                coma2: coma
            })
        } else {
            return this.setState({
                cryptoSelect2: [],
                coma: this.state.coma2
            })
        }
    }

    componentDidMount() {
        const { modalAllCoins, loadAllCoinData } = this.props;

        loadAllCoinData();
        const selectedCoins = modalAllCoins.filter(type => {
            return type.selected === 1;
        });

        const comm = selectedCoins.map(res => {
            return res.id;
        });
        this.setState({
            allCoin: modalAllCoins,
            cryptoSelect2: selectedCoins,
            coma2: comm
        });
    }

    componentDidUpdate(prevProps, prevState) {
        const { crypModal, modalAllCoins, loadAllCoinData, loadACData, coma } = this.props;

        if (crypModal !== prevProps.crypModal) {
            // loadAllCoinData();
            // loadACData();
            if (coma !== prevState.coma2) {
                loadAllCoinData();
                loadACData();
            }
            const selectedCoins = modalAllCoins.filter(type => {
                return type.selected === 1;
            });
            const comm = selectedCoins.map(res => {
                return res.id;
            });
            this.setState({
                allCoin: modalAllCoins,
                cryptoSelect2: selectedCoins,
                coma2: comm
            });


        }

    }

    saveCoins = (e) => {
        e.preventDefault();
        const { sendCurrency } = this.props;
        const { coma2 } = this.state;
        sendCurrency(coma2);
    }


    render() {

        const { searchBlock, MSearchVal, cryptoSelect2 } = this.state;

        const { crypModal, toggleCryptoModal, modalAllCoins, isSmallLoader, cryptoError } = this.props;

        let ActiveCoins = modalAllCoins;
        if (MSearchVal.length > 0) {
            const searchData = modalAllCoins.filter((data) => {
                return data.symbol.toLowerCase().indexOf(MSearchVal.toLowerCase()) !== -1;
            });
            ActiveCoins = searchData;
        } else {
            ActiveCoins = modalAllCoins;
        }

        return (
            <Modal show={crypModal} onHide={() => toggleCryptoModal()} className="appModal" size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
                <form method="post" onSubmit={this.saveCoins}>
                    <Modal.Body>
                        <div className="block-cc">
                            <h6><span className="main-texts">All Cryptocurrency</span>
                                {(searchBlock) ?
                                    <span className="inputs_searc">
                                        <input type="text" name="search_coin" className="search-input" placeholder="Search..." value={MSearchVal} onChange={this.getSearchVal} />
                                        <button type="button" name="cancel" className="btn-cancel2" onClick={this.toggleSearch}>Cancel</button>
                                    </span>
                                    :
                                    <span className="search-bl">
                                        <button type="button" className="btn-searcs" onClick={this.toggleSearch}><img src={SearchIcons2} alt="search" /></button>
                                    </span>
                                }


                            </h6>
                            <div className="con_block">
                                <div className="divi_block">
                                    <div className="curr-block">
                                        <h5>{`My cryptocurrency (${cryptoSelect2.length})`}</h5>
                                        <ul className="cur_ul" >
                                            {(cryptoSelect2.length > 0) ?
                                                cryptoSelect2.map((data, i) => this.viewSelectList(data, i))
                                                :
                                                <li className="no-data"><p>No Data Available</p></li>
                                            }

                                        </ul>
                                    </div>
                                </div>
                                <div className="divi_block">
                                    <div className="curr-block marginB-0">
                                        <h5>{'Available cryptocurrencies (' + modalAllCoins.length + ')'}</h5>
                                        <ul className="cur_ul" >
                                            {(ActiveCoins.length > 0) ?
                                                ActiveCoins.map((data, i) => this.viewAllCoin(data, i))
                                                :
                                                <li className="no-data"><p>No Data Available</p></li>
                                            }

                                        </ul>
                                    </div>
                                </div>
                            </div>
                            {(cryptoError !== null) ?
                                <p className="errorContact">{cryptoError}</p>
                                :
                                null
                            }

                            <div className="btns-blc">
                                {/* <button type="submit" className="btn-saves" disabled={!this.state.selectCoins.length > 0 || this.state.isSmallLoader}>{(this.state.isSmallLoader)?<img className="loader" src={UserLoader} alt="Loader" />:"Save"}</button> */}
                                <button type="submit" className="btn-saves" disabled={!this.state.cryptoSelect2.length > 0 || isSmallLoader}>{(isSmallLoader) ? <img className="loader" src={UserLoader} alt="Loader" /> : "Save"}</button>
                                <button type="button" className="btn-cancels" onClick={() => toggleCryptoModal()}>Cancel</button>
                            </div>
                        </div>
                    </Modal.Body>
                </form>

            </Modal>
        )
    }
}

const mapStateToProps = state => ({
    userId: state.auth.userId,
    crypModal: state.buyornot.crypModal,
    activeCoins: state.buyornot.activeCoins,
    cryptoDS: state.buyornot.cryptoDS,
    coma: state.buyornot.commDS,
    isSmallLoader: state.buyornot.isSmallLoader,
    cryptoError: state.buyornotErrors.cryptoMSG,
    modalAllCoins:state.buyornot.modalAllCoins
});

const mapDispatchToProps = dispatch => ({
    toggleCryptoModal: () => dispatch({ type: TOGGLE_CRYPMODAL }),
    sendCurrency: (data) => dispatch(send_cryptoCurrency(data)),
    loadAllCoinData: () => dispatch(modalLoadAllCoins()),
    loadACData: () => dispatch(loadActiveCoins())
});

export default connect(mapStateToProps, mapDispatchToProps)(CryptoModal);
