import axios from 'axios';

// let url = "https://h5ffhnquaa.execute-api.us-east-2.amazonaws.com/dev/";
// let url = "http://localhost:3000/dev/";
let url = "https://webdev.redlinecoin.com/";

// api calling function
export const apiHelper = (api, method, data, headers) =>{

    const apiUrl = url + api;
    return new Promise((resolve, reject)=>{
        axios({
            method:method,
            url:apiUrl,
            data:data,
            headers:headers 
        })
        .then(res=>resolve(res))
        .catch(error=>reject(error));
    });
    
}