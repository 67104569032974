import * as ACTION_TYPES from '../actions/types';

const initialState = {
    isLoader:false,
    youtubeArray:[],
    langArray:[]
}

const topYoutube = (state = initialState, action) =>{
    switch(action.type){
        case ACTION_TYPES.LOADING_YOUTUBE:
            return{
                ...state,
                isLoader:true
            }
        case ACTION_TYPES.LOADED_YOUTUBE_SUCCESS:
            return{
                ...state,
                isLoader:false,
                youtubeArray:action.payload
            }
        case ACTION_TYPES.LOADED_YOUTUBE_FAIL:
            return{
                ...state,
                isLoader:false,
                youtubeArray:[]
            }
        case ACTION_TYPES.LOADED_LANG_SUCCESS:
            return {
                ...state,
                langArray:action.payload
            }
        default:
            return state;
    }
} 

export default topYoutube;