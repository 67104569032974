import * as ACTION_TYPES from '../actions/types';

const initialState = {
    successMSG:null,
    isLoader:false,
}

const FooterReducer = (state = initialState, action) =>{
    switch(action.type){
        case ACTION_TYPES.REPLAY_SEND:
            return{
                ...state,
                isLoader:true
            }
        case ACTION_TYPES.REPLAY_SUCCESS:
            // console.log(action.payload)
            return{
                ...state,
                isLoader:false,
                successMSG:action.payload
            }
        case ACTION_TYPES.REPLAY_FAIL:
            return{
                ...state,
                isLoader:false,
                successMSG:null
            }
        default:
            return state;
    }
}

export default FooterReducer;