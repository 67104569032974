import * as ACTION_TYPES from './types';
import { jwtDecode } from '../../helper/jwt_helper';
import { apiHelper } from '../../helper/api_helper'
import { returnAllCoinsError, returnActiveCoinsError, returnCryptoError } from './buyornotError';

export const loadAllCoins = () => (dispatch, getState) => {

    return apiHelper('coin', 'GET', null, null)
        .then(response => {
            const res = response.data
            if (res.status) {
                const decode = jwtDecode(res.data)
                dispatch({
                    type: ACTION_TYPES.LOADED_ALLCOINS,
                    // payload:{data:res.data.data,subscription:res.data.subscriptions,selected:res.data.selected}
                    payload: decode
                });
                return {status:true, data:decode};
            } else {
                const err = res.message;
                dispatch(returnAllCoinsError(err.msg, 401, 'ALLCOINS_FAIL'));
                dispatch({
                    type: ACTION_TYPES.ALLCOINS_FAIL
                })

                return {status:false, data:[]};
            }
        })
        .catch(err => {
            dispatch(returnAllCoinsError(err.msg, 401, 'ALLCOINS_FAIL'));
            dispatch({
                type: ACTION_TYPES.ALLCOINS_FAIL
            });

            return {status:false, data:[]};;
        })

}
export const modalLoadAllCoins = () => (dispatch, getState) => {

    const token = getState().auth.token;
    const headers = { 'Authorization': token }

    return apiHelper('coin-all', 'GET', null, headers)
        .then(response => {
            const res = response.data

            if (res.status) {
                const decode = jwtDecode(res.data)                
                dispatch({
                    type: ACTION_TYPES.LOADED_MODALALLCOINS,
                    // payload:{data:res.data.data,subscription:res.data.subscriptions,selected:res.data.selected}
                    payload: decode
                })
            } else {
                const err = res.data;
                dispatch(returnAllCoinsError(err.msg, 401, 'ALLCOINS_FAIL'));
                dispatch({
                    type: ACTION_TYPES.ALLCOINS_FAIL
                })
            }
        })
        .catch(err => {
            dispatch(returnAllCoinsError(err.msg, 401, 'ALLCOINS_FAIL'));
            dispatch({
                type: ACTION_TYPES.ALLCOINS_FAIL
            });
        })
}

// active coins load
export const loadActiveCoins = () => (dispatch, getState) => {

    const token = sessionStorage.getItem('token');
    const headers = { 'Authorization': token };

    return apiHelper('user/coin', 'GET', null, headers)
        .then(response => {
            const res = response.data
            if (res.status) {
                const decode = jwtDecode(res.data)
                dispatch({
                    type: ACTION_TYPES.LOADED_ACTIVECOINS,
                    payload: decode
                })

                return { status:true, data:decode }
            } else {
                const err = res.data;
                dispatch(returnActiveCoinsError(err.msg, 401, 'ACTIVECOINS_FAIL'));
                dispatch({
                    type: ACTION_TYPES.ACTIVECOINS_FAIL
                });

                return { status:false, data:[] }
            }
        })
        .catch(err => {
            dispatch(returnActiveCoinsError(err.msg, 401, 'ACTIVECOINS_FAIL'));
            dispatch({
                type: ACTION_TYPES.ACTIVECOINS_FAIL
            });
            return { status:false, data:[] }
        })
}

export const send_cryptoCurrency = (data) => (dispatch, getState) => {
    const token = sessionStorage.getItem('token')
    const headers = { 'Authorization': token }
    dispatch({ type: ACTION_TYPES.CRYPTO_LOADING })

    //formData.append('coinIDs', JSON.stringify(data))
    const formData = {'coinIDs': data }

    return apiHelper('user/coin', 'POST', formData, headers)
        .then(response => {
            const res = response.data;
            if (res.status) {
                dispatch({
                    type: ACTION_TYPES.CRYPTOCOINS_SUCCESS
                });
            } else {
                const err = res;
                dispatch(returnCryptoError(err.msg, 401, 'CRYPTOCOINS_FAIL'));
                dispatch({
                    type: ACTION_TYPES.CRYPTOCOINS_FAIL
                })
            }
        })
        .catch(err => {
            dispatch(returnCryptoError(err.msg, 401, 'CRYPTOCOINS_FAIL'));
            dispatch({
                type: ACTION_TYPES.CRYPTOCOINS_FAIL
            });
        })
}